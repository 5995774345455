import "./SwitchTabFive.css";
import { useState } from "react";
import DashboardEmbed from "./DashboardEmbed";
import { FaFolder, FaFolderOpen, FaTasks } from "react-icons/fa";

export default function SwitchTabFive() {
  const [selectedMainTab, setSelectedMainTab] = useState(null);
  const [selectedSubTab, setSelectedSubTab] = useState(null);
  const [selectedSubSubTab, setSelectedSubSubTab] = useState(null);
  const [selectedSubSubSubTab, setSelectedSubSubSubTab] = useState(null);

  const handleMainTabClick = (tab) => {
    setSelectedMainTab(tab === selectedMainTab ? null : tab);
    setSelectedSubTab(null);
    setSelectedSubSubTab(null);
    setSelectedSubSubSubTab(null);
  };

  const handleSubTabClick = (tab) => {
    setSelectedSubTab(tab === selectedSubTab ? null : tab);
    setSelectedSubSubTab(null);
    setSelectedSubSubSubTab(null);
  };

  const handleSubSubTabClick = (tab) => {
    setSelectedSubSubTab(tab === selectedSubSubTab ? null : tab);
    setSelectedSubSubSubTab(null);
  };

  const handleSubSubSubClick = (tab) => {
    setSelectedSubSubSubTab(tab === selectedSubSubSubTab ? null : tab);
  };

  return (
    <div className="main-container">
      <div className="tab-list">
        <div
          className="main-heading"
          onClick={() => handleMainTabClick("Profiling")}
        >
          {selectedMainTab === "Profiling" ? (
            <FaFolderOpen style={{ marginRight: "8px" }} />
          ) : (
            <FaFolder style={{ marginRight: "8px" }} />
          )}
          Profiling
        </div>

        {selectedMainTab === "Profiling" && (
          <>
            <div
              className="sub-heading"
              onClick={() => handleSubTabClick("Table Profiling Status")}
            >
              {selectedSubTab === "Table Profiling Status" ? (
                <FaFolderOpen style={{ marginRight: "8px" }} />
              ) : (
                <FaFolder style={{ marginRight: "8px" }} />
              )}
              Table Profiling Status
            </div>
            {selectedSubTab === "Table Profiling Status" && (
              <>
                <div
                  className={`sub-sub-heading ${
                    selectedSubSubSubTab === "Table Profiling Status Details"
                      ? "active"
                      : ""
                  }`}
                  onClick={() =>
                    handleSubSubSubClick("Table Profiling Status Details")
                  }
                >
                  <FaTasks size={12} style={{ marginRight: "5px" }} />
                  Table Profiling Status Details
                </div>
                <div
                  className={`sub-sub-heading ${
                    selectedSubSubSubTab === "Column Profiling Status"
                      ? "active"
                      : ""
                  }`}
                  onClick={() =>
                    handleSubSubSubClick("Column Profiling Status")
                  }
                >
                  <FaTasks size={12} style={{ marginRight: "5px" }} />
                  Column Profiling Status
                </div>
              </>
            )}
            <div
              className="sub-heading"
              onClick={() => handleSubTabClick("Data Profiling Issues Count")}
            >
              {selectedSubTab === "Data Profiling Issues Count" ? (
                <FaFolderOpen style={{ marginRight: "8px" }} />
              ) : (
                <FaFolder style={{ marginRight: "8px" }} />
              )}
              Data Profiling Issues Count
            </div>
            {selectedSubTab === "Data Profiling Issues Count" && (
              <>
                <div
                  className={`sub-sub-heading ${
                    selectedSubSubSubTab ===
                    "Data Quality Executed Checks per Table"
                      ? "active"
                      : ""
                  }`}
                  onClick={() =>
                    handleSubSubSubClick(
                      "Data Quality Executed Checks per Table"
                    )
                  }
                >
                  <FaTasks size={12} style={{ marginRight: "5px" }} />
                  Data Quality Executed Checks per Table
                </div>
                <div
                  className={`sub-sub-heading ${
                    selectedSubSubSubTab === "Profiling Issues Count per Check"
                      ? "active"
                      : ""
                  }`}
                  onClick={() =>
                    handleSubSubSubClick("Profiling Issues Count per Check")
                  }
                >
                  <FaTasks size={12} style={{ marginRight: "5px" }} />
                  Profiling Issues Count per Check
                </div>
              </>
            )}

            <div
              className="sub-heading"
              onClick={() => handleSubTabClick("Data Quality Dimensions")}
            >
              {selectedSubTab === "Data Quality Dimensions" ? (
                <FaFolderOpen style={{ marginRight: "5px" }} />
              ) : (
                <FaFolder style={{ marginRight: "5px" }} />
              )}
              Data Quality Dimensions
            </div>

            {selectedSubTab === "Data Quality Dimensions" && (
              <>
                <div
                  className="sub-sub-heading"
                  onClick={() => handleSubSubTabClick("Availability")}
                >
                  {selectedSubSubTab === "Availability" ? (
                    <FaFolderOpen size={12} style={{ marginRight: "5px" }} />
                  ) : (
                    <FaFolder size={12} style={{ marginRight: "5px" }} />
                  )}
                  Availability
                </div>
                {selectedSubSubTab === "Availability" && (
                  <div
                    className={`sub-sub-sub-heading ${
                      selectedSubSubSubTab === "Current Table Availability"
                        ? "active"
                        : ""
                    }`}
                    onClick={() =>
                      handleSubSubSubClick("Current Table Availability")
                    }
                  >
                    <FaTasks size={10} style={{ marginRight: "5px" }} />
                    Current Table Availability
                  </div>
                )}

                <div
                  className="sub-sub-heading"
                  onClick={() => handleSubSubTabClick("Completeness")}
                >
                  {selectedSubSubTab === "Completeness" ? (
                    <FaFolderOpen size={12} style={{ marginRight: "5px" }} />
                  ) : (
                    <FaFolder size={12} style={{ marginRight: "5px" }} />
                  )}
                  Completeness
                </div>
                {selectedSubSubTab === "Completeness" && (
                  <>
                    <div
                      className={`sub-sub-sub-heading ${
                        selectedSubSubSubTab === "Current Completeness Issues"
                          ? "active"
                          : ""
                      }`}
                      onClick={() =>
                        handleSubSubSubClick("Current Completeness Issues")
                      }
                    >
                      <FaTasks size={10} style={{ marginRight: "5px" }} />
                      Current Completeness Issues on Columns
                    </div>
                    <div
                      className={`sub-sub-sub-heading ${
                        selectedSubSubSubTab === "Incomplete Columns"
                          ? "active"
                          : ""
                      }`}
                      onClick={() => handleSubSubSubClick("Incomplete Columns")}
                    >
                      <FaTasks size={10} style={{ marginRight: "5px" }} />
                      Incomplete Columns with Null Values - Counts
                    </div>
                  </>
                )}

                <div
                  className="sub-sub-heading"
                  onClick={() => handleSubSubTabClick("Accuracy")}
                >
                  {selectedSubSubTab === "Accuracy" ? (
                    <FaFolderOpen size={12} style={{ marginRight: "5px" }} />
                  ) : (
                    <FaFolder size={12} style={{ marginRight: "5px" }} />
                  )}
                  Accuracy
                </div>
                {selectedSubSubTab === "Accuracy" && (
                  <div
                    className={`sub-sub-sub-heading ${
                      selectedSubSubSubTab === "Table Comparison"
                        ? "active"
                        : ""
                    }`}
                    onClick={() => handleSubSubSubClick("Table Comparison")}
                  >
                    <FaTasks size={10} style={{ marginRight: "5px" }} />
                    Table Comparison
                  </div>
                )}

                <div
                  className="sub-sub-heading"
                  onClick={() => handleSubSubTabClick("Timeliness")}
                >
                  {selectedSubSubTab === "Timeliness" ? (
                    <FaFolderOpen size={12} style={{ marginRight: "5px" }} />
                  ) : (
                    <FaFolder size={12} style={{ marginRight: "5px" }} />
                  )}
                  Timeliness
                </div>
                {selectedSubSubTab === "Timeliness" && (
                  <>
                    <div
                      className={`sub-sub-sub-heading ${
                        selectedSubSubSubTab === "Current Timeliness Issues"
                          ? "active"
                          : ""
                      }`}
                      onClick={() =>
                        handleSubSubSubClick("Current Timeliness Issues")
                      }
                    >
                      <FaTasks size={10} style={{ marginRight: "5px" }} />
                      Current Timeliness Issues
                    </div>
                    <div
                      className={`sub-sub-sub-heading ${
                        selectedSubSubSubTab ===
                        "Table Freshness - Current Data"
                          ? "active"
                          : ""
                      }`}
                      onClick={() =>
                        handleSubSubSubClick("Table Freshness - Current Data")
                      }
                    >
                      <FaTasks size={10} style={{ marginRight: "5px" }} />
                      Table Freshness - Tables with the Most Current Data
                    </div>
                    <div
                      className={`sub-sub-sub-heading ${
                        selectedSubSubSubTab === "Table Freshness - Oldest Data"
                          ? "active"
                          : ""
                      }`}
                      onClick={() =>
                        handleSubSubSubClick("Table Freshness - Oldest Data")
                      }
                    >
                      <FaTasks size={10} style={{ marginRight: "5px" }} />
                      Table Freshness - Tables with the Oldest Data
                    </div>
                  </>
                )}

                <div
                  className="sub-sub-heading"
                  onClick={() => handleSubSubTabClick("Validity")}
                >
                  {selectedSubSubTab === "Validity" ? (
                    <FaFolderOpen size={12} style={{ marginRight: "5px" }} />
                  ) : (
                    <FaFolder size={12} style={{ marginRight: "5px" }} />
                  )}
                  Validity
                </div>
                {selectedSubSubTab === "Validity" && (
                  <div
                    className={`sub-sub-sub-heading ${
                      selectedSubSubSubTab === "Current Validity Issues"
                        ? "active"
                        : ""
                    }`}
                    onClick={() =>
                      handleSubSubSubClick("Current Validity Issues")
                    }
                  >
                    <FaTasks size={10} style={{ marginRight: "5px" }} />
                    Current Validity Issues on Columns
                  </div>
                )}
              </>
            )}

            <div
              className="sub-heading"
              onClick={() => handleSubTabClick("Profiling Data Quality KPIs")}
            >
              {selectedSubTab === "Profiling Data Quality KPIs" ? (
                <FaFolderOpen style={{ marginRight: "5px" }} />
              ) : (
                <FaFolder style={{ marginRight: "5px" }} />
              )}
              Profiling Data Quality KPIs
            </div>

            {selectedSubTab === "Profiling Data Quality KPIs" && (
              <>
                <div
                  className={`sub-sub-heading ${
                    selectedSubSubSubTab === "Profiling KPIs Scorecard"
                      ? "active"
                      : ""
                  }`}
                  onClick={() =>
                    handleSubSubSubClick("Profiling KPIs Scorecard")
                  }
                >
                  <FaTasks size={12} style={{ marginRight: "5px" }} />
                  Profiling KPIs Scorecard - Summary
                </div>
                <div
                  className={`sub-sub-heading ${
                    selectedSubSubSubTab === "Profiling KPIs per Table"
                      ? "active"
                      : ""
                  }`}
                  onClick={() =>
                    handleSubSubSubClick("Profiling KPIs per Table")
                  }
                >
                  <FaTasks size={12} style={{ marginRight: "5px" }} />
                  Profiling KPIs per Table - Summary
                </div>
                <div
                  className={`sub-sub-heading ${
                    selectedSubSubSubTab === "KPIs Current vs Previous Month"
                      ? "active"
                      : ""
                  }`}
                  onClick={() =>
                    handleSubSubSubClick("KPIs Current vs Previous Month")
                  }
                >
                  <FaTasks size={12} style={{ marginRight: "5px" }} />
                  KPIs Current vs Previous Month
                </div>
              </>
            )}

            <div
              className="sub-heading"
              onClick={() => handleSubTabClick("Check Results")}
            >
              {selectedSubTab === "Check Results" ? (
                <FaFolderOpen style={{ marginRight: "5px" }} />
              ) : (
                <FaFolder style={{ marginRight: "5px" }} />
              )}
              Check Results
            </div>
            {selectedSubTab === "Check Results" && (
              <>
                <div
                  className={`sub-sub-heading ${
                    selectedSubSubSubTab ===
                    "Current Data Quality Check Results"
                      ? "active"
                      : ""
                  }`}
                  onClick={() =>
                    handleSubSubSubClick("Current Data Quality Check Results")
                  }
                >
                  <FaTasks size={12} style={{ marginRight: "5px" }} />
                  Current Data Quality Check Results
                </div>
                <div
                  className={`sub-sub-heading ${
                    selectedSubSubSubTab ===
                    "History of Data Quality Check Results"
                      ? "active"
                      : ""
                  }`}
                  onClick={() =>
                    handleSubSubSubClick(
                      "History of Data Quality Check Results"
                    )
                  }
                >
                  <FaTasks size={12} style={{ marginRight: "5px" }} />
                  History of Data Quality Check Results
                </div>
              </>
            )}

            <div
              className="sub-heading"
              onClick={() => handleSubTabClick("Volume")}
            >
              {selectedSubTab === "Volume" ? (
                <FaFolderOpen style={{ marginRight: "5px" }} />
              ) : (
                <FaFolder style={{ marginRight: "5px" }} />
              )}
              Volume
            </div>
            {selectedSubTab === "Volume" && (
              <>
                <div
                  className={`sub-sub-heading ${
                    selectedSubSubSubTab === "Largest Tables" ? "active" : ""
                  }`}
                  onClick={() => handleSubSubSubClick("Largest Tables")}
                >
                  <FaTasks size={12} style={{ marginRight: "5px" }} />
                  Largest Tables by Number of Rows
                </div>
                <div
                  className={`sub-sub-heading ${
                    selectedSubSubSubTab === "Empty or Small Tables"
                      ? "active"
                      : ""
                  }`}
                  onClick={() => handleSubSubSubClick("Empty or Small Tables")}
                >
                  <FaTasks size={12} style={{ marginRight: "5px" }} />
                  Empty or Too Small Tables
                </div>
              </>
            )}

            <div
              className="sub-heading"
              onClick={() => handleSubTabClick("PII")}
            >
              {selectedSubTab === "PII" ? (
                <FaFolderOpen style={{ marginRight: "5px" }} />
              ) : (
                <FaFolder style={{ marginRight: "5px" }} />
              )}
              PII
            </div>
            {selectedSubTab === "PII" && (
              <>
                <div
                  className={`sub-sub-heading ${
                    selectedSubSubSubTab === "PII Data Detected" ? "active" : ""
                  }`}
                  onClick={() => handleSubSubSubClick("PII Data Detected")}
                >
                  <FaTasks size={12} style={{ marginRight: "5px" }} />
                  PII Data Detected
                </div>
                <div
                  className={`sub-sub-heading ${
                    selectedSubSubSubTab === "History of PII Issues"
                      ? "active"
                      : ""
                  }`}
                  onClick={() => handleSubSubSubClick("History of PII Issues")}
                >
                  <FaTasks size={12} style={{ marginRight: "5px" }} />
                  History of PII Issues
                </div>
              </>
            )}
          </>
        )}
        {/* Monitorings Main Tab */}
        <div
          className="main-heading"
          onClick={() => handleMainTabClick("Monitorings")}
        >
          {selectedMainTab === "Monitorings" ? (
            <FaFolderOpen style={{ marginRight: "8px" }} />
          ) : (
            <FaFolder style={{ marginRight: "8px" }} />
          )}
          Monitoring
        </div>
        {selectedMainTab === "Monitorings" && (
          <>
            <div
              className="sub-heading"
              onClick={() => handleSubTabClick("Table Monitoring Status")}
            >
              {selectedSubTab === "Table Monitoring Status" ? (
                <FaFolderOpen style={{ marginRight: "5px" }} />
              ) : (
                <FaFolder style={{ marginRight: "5px" }} />
              )}
              Current Status
            </div>

            {selectedSubTab === "Table Monitoring Status" && (
              <>
                <div
                  className={`sub-sub-heading ${
                    selectedSubSubSubTab === "Table Monitoring Status Details"
                      ? "active"
                      : ""
                  }`}
                  onClick={() =>
                    handleSubSubSubClick("Table Monitoring Status Details")
                  }
                >
                  <FaTasks size={12} style={{ marginRight: "5px" }} />
                  Table Monitoring Status
                </div>
                <div
                  className={`sub-sub-heading ${
                    selectedSubSubSubTab === "Column Monitoring Status"
                      ? "active"
                      : ""
                  }`}
                  onClick={() =>
                    handleSubSubSubClick("Column Monitoring Status")
                  }
                >
                  <FaTasks size={12} style={{ marginRight: "5px" }} />
                  Column Monitoring Status
                </div>
              </>
            )}
            <div
              className="sub-heading"
              onClick={() => handleSubTabClick("Data Monitoring Issues Count")}
            >
              {selectedSubTab === "Data Monitoring Issues Count" ? (
                <FaFolderOpen style={{ marginRight: "5px" }} />
              ) : (
                <FaFolder style={{ marginRight: "5px" }} />
              )}
              Data Monitoring Issues Count
            </div>

            {selectedSubTab === "Data Monitoring Issues Count" && (
              <>
                <div
                  className={`sub-sub-heading ${
                    selectedSubSubSubTab === "Monitoring Issues Count per Check"
                      ? "active"
                      : ""
                  }`}
                  onClick={() =>
                    handleSubSubSubClick("Monitoring Issues Count per Check")
                  }
                >
                  <FaTasks size={12} style={{ marginRight: "5px" }} />
                  Monitoring Issues Count per Check
                </div>
                <div
                  className={`sub-sub-heading ${
                    selectedSubSubSubTab === "Executed Checks per Table"
                      ? "active"
                      : ""
                  }`}
                  onClick={() =>
                    handleSubSubSubClick("Executed Checks per Table")
                  }
                >
                  <FaTasks size={12} style={{ marginRight: "5px" }} />
                  Data Quality Executed Checks per Table
                </div>
              </>
            )}

            <div
              className="sub-heading"
              onClick={() =>
                handleSubTabClick("Monitoring Data Quality Dimensions")
              }
            >
              {selectedSubTab === "Monitoring Data Quality Dimensions" ? (
                <FaFolderOpen style={{ marginRight: "5px" }} />
              ) : (
                <FaFolder style={{ marginRight: "5px" }} />
              )}
              Monitoring Data Quality Dimensions
            </div>

            {selectedSubTab === "Monitoring Data Quality Dimensions" && (
              <>
                <div
                  className={`sub-sub-heading ${
                    selectedSubSubTab === "Availability" ? "active" : ""
                  }`}
                  onClick={() => handleSubSubTabClick("Availability")}
                >
                  {selectedSubSubTab === "Availability" ? (
                    <FaFolderOpen style={{ marginRight: "5px" }} />
                  ) : (
                    <FaFolder style={{ marginRight: "5px" }} />
                  )}
                  Availability
                </div>
                {selectedSubSubTab === "Availability" && (
                  <div
                    className={`sub-sub-sub-heading ${
                      selectedSubSubSubTab ===
                      "Current Table Availability Monitoring"
                        ? "active"
                        : ""
                    }`}
                    onClick={() =>
                      handleSubSubSubClick(
                        "Current Table Availability Monitoring"
                      )
                    }
                  >
                    <FaTasks size={12} style={{ marginRight: "5px" }} />
                    Current Table Availability Monitoring
                  </div>
                )}

                <div
                  className={`sub-sub-heading ${
                    selectedSubSubTab === "Completeness" ? "active" : ""
                  }`}
                  onClick={() => handleSubSubTabClick("Completeness")}
                >
                  {selectedSubSubTab === "Completeness" ? (
                    <FaFolderOpen style={{ marginRight: "5px" }} />
                  ) : (
                    <FaFolder style={{ marginRight: "5px" }} />
                  )}
                  Completeness
                </div>
                {selectedSubSubTab === "Completeness" && (
                  <>
                    <div
                      className={`sub-sub-sub-heading ${
                        selectedSubSubSubTab ===
                        "Current Monitoring Completeness Issues"
                          ? "active"
                          : ""
                      }`}
                      onClick={() =>
                        handleSubSubSubClick(
                          "Current Monitoring Completeness Issues"
                        )
                      }
                    >
                      <FaTasks size={12} style={{ marginRight: "5px" }} />
                      Current Monitoring Completeness Issues
                    </div>
                    <div
                      className={`sub-sub-sub-heading ${
                        selectedSubSubSubTab === "Incomplete Monitoring Columns"
                          ? "active"
                          : ""
                      }`}
                      onClick={() =>
                        handleSubSubSubClick("Incomplete Monitoring Columns")
                      }
                    >
                      <FaTasks size={12} style={{ marginRight: "5px" }} />
                      Incomplete Monitoring Columns
                    </div>
                    <div
                      className={`sub-sub-sub-heading ${
                        selectedSubSubSubTab === "Empty Monitoring Columns"
                          ? "active"
                          : ""
                      }`}
                      onClick={() =>
                        handleSubSubSubClick("Empty Monitoring Columns")
                      }
                    >
                      <FaTasks size={12} style={{ marginRight: "5px" }} />
                      Empty Monitoring Columns
                    </div>
                  </>
                )}

                <div
                  className={`sub-sub-heading ${
                    selectedSubSubTab === "Accuracy" ? "active" : ""
                  }`}
                  onClick={() => handleSubSubTabClick("Accuracy")}
                >
                  {selectedSubSubTab === "Accuracy" ? (
                    <FaFolderOpen style={{ marginRight: "5px" }} />
                  ) : (
                    <FaFolder style={{ marginRight: "5px" }} />
                  )}
                  Accuracy
                </div>
                {selectedSubSubTab === "Accuracy" && (
                  <div
                    className={`sub-sub-sub-heading ${
                      selectedSubSubSubTab === "Table Monitoring Comparison"
                        ? "active"
                        : ""
                    }`}
                    onClick={() =>
                      handleSubSubSubClick("Table Monitoring Comparison")
                    }
                  >
                    <FaTasks size={12} style={{ marginRight: "5px" }} />
                    Table Monitoring Comparison
                  </div>
                )}

                <div
                  className={`sub-sub-heading ${
                    selectedSubSubTab === "Timeliness" ? "active" : ""
                  }`}
                  onClick={() => handleSubSubTabClick("Timeliness")}
                >
                  {selectedSubSubTab === "Timeliness" ? (
                    <FaFolderOpen style={{ marginRight: "5px" }} />
                  ) : (
                    <FaFolder style={{ marginRight: "5px" }} />
                  )}
                  Timeliness
                </div>
                {selectedSubSubTab === "Timeliness" && (
                  <>
                    <div
                      className={`sub-sub-sub-heading ${
                        selectedSubSubSubTab === "Current Monitoring Issues"
                          ? "active"
                          : ""
                      }`}
                      onClick={() =>
                        handleSubSubSubClick("Current Monitoring Issues")
                      }
                    >
                      <FaTasks size={12} style={{ marginRight: "5px" }} />
                      Current Monitoring Issues
                    </div>
                    <div
                      className={`sub-sub-sub-heading ${
                        selectedSubSubSubTab ===
                        "Monitoring Freshness - Current Data"
                          ? "active"
                          : ""
                      }`}
                      onClick={() =>
                        handleSubSubSubClick(
                          "Monitoring Freshness - Current Data"
                        )
                      }
                    >
                      <FaTasks size={12} style={{ marginRight: "5px" }} />
                      Monitoring Freshness - Current Data
                    </div>
                    <div
                      className={`sub-sub-sub-heading ${
                        selectedSubSubSubTab ===
                        "Monitoring Freshness - Oldest Data"
                          ? "active"
                          : ""
                      }`}
                      onClick={() =>
                        handleSubSubSubClick(
                          "Monitoring Freshness - Oldest Data"
                        )
                      }
                    >
                      <FaTasks size={12} style={{ marginRight: "5px" }} />
                      Monitoring Freshness - Oldest Data
                    </div>
                  </>
                )}

                <div
                  className={`sub-sub-heading ${
                    selectedSubSubTab === "Validity" ? "active" : ""
                  }`}
                  onClick={() => handleSubSubTabClick("Validity")}
                >
                  {selectedSubSubTab === "Validity" ? (
                    <FaFolderOpen style={{ marginRight: "5px" }} />
                  ) : (
                    <FaFolder style={{ marginRight: "5px" }} />
                  )}
                  Validity
                </div>
                {selectedSubSubTab === "Validity" && (
                  <div
                    className={`sub-sub-sub-heading ${
                      selectedSubSubSubTab ===
                      "Current Monitoring Validity Issues"
                        ? "active"
                        : ""
                    }`}
                    onClick={() =>
                      handleSubSubSubClick("Current Monitoring Validity Issues")
                    }
                  >
                    <FaTasks size={12} style={{ marginRight: "5px" }} />
                    Current Monitoring Validity Issues
                  </div>
                )}
              </>
            )}

            <div
              className="sub-heading"
              onClick={() => handleSubTabClick("Monitoring Data Quality KPIs")}
            >
              {selectedSubTab === "Monitoring Data Quality KPIs" ? (
                <FaFolderOpen style={{ marginRight: "5px" }} />
              ) : (
                <FaFolder style={{ marginRight: "5px" }} />
              )}
              Monitoring Data Quality KPIs
            </div>
            {selectedSubTab === "Monitoring Data Quality KPIs" && (
              <>
                <div
                  className={`sub-sub-heading ${
                    selectedSubSubSubTab === "Monitoring KPIs Scorecard"
                      ? "active"
                      : ""
                  }`}
                  onClick={() =>
                    handleSubSubSubClick("Monitoring KPIs Scorecard")
                  }
                >
                  <FaTasks size={12} style={{ marginRight: "5px" }} />
                  Monitoring KPIs Scorecard - Summary
                </div>
                <div
                  className={`sub-sub-heading ${
                    selectedSubSubSubTab === "Monitoring KPIs per Table"
                      ? "active"
                      : ""
                  }`}
                  onClick={() =>
                    handleSubSubSubClick("Monitoring KPIs per Table")
                  }
                >
                  <FaTasks size={12} style={{ marginRight: "5px" }} />
                  Monitoring KPIs per Table - Summary
                </div>
                <div
                  className={`sub-sub-heading ${
                    selectedSubSubSubTab === "KPI Current vs Previous Month"
                      ? "active"
                      : ""
                  }`}
                  onClick={() =>
                    handleSubSubSubClick("KPI Current vs Previous Month")
                  }
                >
                  <FaTasks size={12} style={{ marginRight: "5px" }} />
                  KPIs Current vs Previous Month
                </div>
              </>
            )}

            <div
              className="sub-heading"
              onClick={() => handleSubTabClick("Monitoring Check Results")}
            >
              {selectedSubTab === "Monitoring Check Results" ? (
                <FaFolderOpen style={{ marginRight: "5px" }} />
              ) : (
                <FaFolder style={{ marginRight: "5px" }} />
              )}
              Monitoring Check Results
            </div>
            {selectedSubTab === "Monitoring Check Results" && (
              <>
                <div
                  className={`sub-sub-heading ${
                    selectedSubSubSubTab === "Current Monitoring Check Results"
                      ? "active"
                      : ""
                  }`}
                  onClick={() =>
                    handleSubSubSubClick("Current Monitoring Check Results")
                  }
                >
                  <FaTasks size={12} style={{ marginRight: "5px" }} />
                  Current Monitoring Check Results
                </div>
                <div
                  className={`sub-sub-heading ${
                    selectedSubSubSubTab ===
                    "History of Monitoring Check Results"
                      ? "active"
                      : ""
                  }`}
                  onClick={() =>
                    handleSubSubSubClick("History of Monitoring Check Results")
                  }
                >
                  <FaTasks size={12} style={{ marginRight: "5px" }} />
                  History of Monitoring Check Results
                </div>
              </>
            )}

            <div
              className="sub-heading"
              onClick={() => handleSubTabClick("Volume")}
            >
              {selectedSubTab === "Volume" ? (
                <FaFolderOpen style={{ marginRight: "5px" }} />
              ) : (
                <FaFolder style={{ marginRight: "5px" }} />
              )}
              Volume
            </div>
            {selectedSubTab === "Volume" && (
              <>
                <div
                  className={`sub-sub-heading ${
                    selectedSubSubSubTab === "Largest Monitored Tables"
                      ? "active"
                      : ""
                  }`}
                  onClick={() =>
                    handleSubSubSubClick("Largest Monitored Tables")
                  }
                >
                  <FaTasks size={12} style={{ marginRight: "5px" }} />
                  Largest Monitored Tables by Number of Rows
                </div>
                <div
                  className={`sub-sub-heading ${
                    selectedSubSubSubTab === "Empty or Small Monitored Tables"
                      ? "active"
                      : ""
                  }`}
                  onClick={() =>
                    handleSubSubSubClick("Empty or Small Monitored Tables")
                  }
                >
                  <FaTasks size={12} style={{ marginRight: "5px" }} />
                  Empty or Small Monitored Tables
                </div>
              </>
            )}

            <div
              className="sub-heading"
              onClick={() => handleSubTabClick("Monitoring PII")}
            >
              {selectedSubTab === "Monitoring PII" ? (
                <FaFolderOpen style={{ marginRight: "5px" }} />
              ) : (
                <FaFolder style={{ marginRight: "5px" }} />
              )}
              Monitoring PII
            </div>
            {selectedSubTab === "Monitoring PII" && (
              <>
                <div
                  className={`sub-sub-heading ${
                    selectedSubSubSubTab === "Monitored PII Data Detected"
                      ? "active"
                      : ""
                  }`}
                  onClick={() =>
                    handleSubSubSubClick("Monitored PII Data Detected")
                  }
                >
                  <FaTasks size={12} style={{ marginRight: "5px" }} />
                  Monitored PII Data Detected
                </div>
                <div
                  className={`sub-sub-heading ${
                    selectedSubSubSubTab === "History of Monitored PII Issues"
                      ? "active"
                      : ""
                  }`}
                  onClick={() =>
                    handleSubSubSubClick("History of Monitored PII Issues")
                  }
                >
                  <FaTasks size={12} style={{ marginRight: "5px" }} />
                  History of Monitored PII Issues
                </div>
              </>
            )}
          </>
        )}
      </div>
      <div className="content">
        {selectedSubSubSubTab === "Table Profiling Status Details" && (
          <DashboardEmbed
            dashboardId="a856d144-bd93-45f4-bffc-e0eec167cd76"
            filterId="Q2xxKQdUbp"
          />
        )}
        {selectedSubSubSubTab === "Column Profiling Status" && (
          <DashboardEmbed
            dashboardId="a626b486-b575-4c67-b2af-3e9be27c476a"
            filterId="u2dKIZ7QBA"
          />
        )}
        {selectedSubSubSubTab === "Profiling KPIs Scorecard" && (
          <DashboardEmbed
            dashboardId="79d42c69-3852-4668-a041-e63575216798"
            filterId="_7HRCRTkY"
          />
        )}
        {selectedSubSubSubTab === "Profiling KPIs per Table" && (
          <DashboardEmbed
            dashboardId="a08df29a-42c7-40bc-a6c4-93cd52dde3dd"
            filterId="sKedFs_zKU"
          />
        )}
        {selectedSubSubSubTab === "Current Timeliness Issues" && (
          <DashboardEmbed
            dashboardId="36463ebc-d194-4962-8489-01c4d0dcad94"
            filterId="6Ea9irRr4z"
          />
        )}
        {selectedSubSubSubTab === "Current Table Availability" && (
          <DashboardEmbed
            dashboardId="de830683-d64a-4c26-904e-b344af3bd9b5"
            filterId="hkLMidchG1"
          />
        )}
        {selectedSubSubSubTab === "Table Freshness - Current Data" && (
          <DashboardEmbed dashboardId="dashboard_id" filterId="" />
        )}
        {selectedSubSubSubTab === "Table Freshness - Oldest Data" && (
          <DashboardEmbed dashboardId="dashboard_id" filterId="" />
        )}
        {selectedSubSubSubTab === "Current Validity Issues" && (
          <DashboardEmbed
            dashboardId="849be01f-8d28-4375-9fa9-d6ce4b44eae0"
            filterId="wkEz5byATB"
          />
        )}
        {selectedSubSubSubTab === "Current Completeness Issues" && (
          <DashboardEmbed
            dashboardId="0eceea59-c914-4136-8f71-01d74fc408ca"
            filterId="_oWY8hYI25"
          />
        )}
        {selectedSubSubSubTab === "Incomplete Columns" && (
          <DashboardEmbed
            dashboardId="fb7cf7d9-9ed5-4c8d-8f1d-934df06e024e"
            filterId="k9b2CPCnpY"
          />
        )}
        {selectedSubSubSubTab === "Empty Columns" && (
          <DashboardEmbed dashboardId="id_empty_columns" filterId="" />
        )}
        {selectedSubSubSubTab === "Largest Tables" && (
          <DashboardEmbed
            dashboardId="e20134f2-4d0b-4721-a3c1-34443c8b0b73"
            filterId="HgHCEj2WZX"
          />
        )}
        {selectedSubSubSubTab === "Empty or Small Tables" && (
          <DashboardEmbed
            dashboardId="c0152525-d070-4a87-9078-6cc8f5240ff3"
            filterId="WzUh2j8ZhH"
          />
        )}
        {selectedSubSubSubTab === "PII Data Detected" && (
          <DashboardEmbed
            dashboardId="4b83c9ef-e7fd-44f8-8311-524f51a428b6"
            filterId="UOjGpl9lO0"
          />
        )}
        {selectedSubSubSubTab === "Data Quality Executed Checks per Table" && (
          <DashboardEmbed
            dashboardId="e5f5949e-8816-4bd7-b865-95597242f14b"
            filterId="4ei34f46nC"
          />
        )}
        {selectedSubSubSubTab === "Profiling Issues Count per Check" && (
          <DashboardEmbed
            dashboardId="637af1ca-cc75-4137-b87a-327207fb1324"
            filterId="AMTeou2HHn"
          />
        )}
        {selectedSubSubSubTab === "KPIs Current vs Previous Month" && (
          <DashboardEmbed
            dashboardId="f7507321-344a-4d94-a937-07d1db6a2b68"
            filterId="K6d4ER-8V"
          />
        )}
        {selectedSubSubSubTab === "Current Data Quality Check Results" && (
          <DashboardEmbed
            dashboardId="b9020ae6-1438-4016-ac32-49c12d0a6295"
            filterId="exM9K8_3fE"
          />
        )}
        {selectedSubSubSubTab === "History of Data Quality Check Results" && (
          <DashboardEmbed
            dashboardId="ab57db9b-e4ea-4ab2-b8d6-8bfa9e6d1e6b"
            filterId="exM9K8_3fE"
          />
        )}
        {selectedSubSubSubTab === "History of PII Issues" && (
          <DashboardEmbed
            dashboardId="2a6dd88e-312f-4e91-b363-7aeefcc5a42d"
            filterId="DmYos-F8RA"
          />
        )}
        {selectedSubSubSubTab === "Table Comparison" && (
          <DashboardEmbed
            dashboardId="691b4325-d175-48dc-bbdc-9417db0666e0"
            filterId="vuLPa5T6qB"
          />
        )}
        {/* Monitorings Dashboards */}
        {selectedSubSubSubTab === "Table Monitoring Status Details" && (
          <DashboardEmbed
            dashboardId="495d3e9f-759f-4170-9a1c-6ea27a614166"
            filterId="Q2xxKQdUbp"
          />
        )}
        {selectedSubSubSubTab === "Column Monitoring Status" && (
          <DashboardEmbed
            dashboardId="aa2384e1-b09a-468e-a7f4-6b8711474626"
            filterId="u2dKIZ7QBA"
          />
        )}
        {selectedSubSubSubTab === "Monitoring KPIs Scorecard" && (
          <DashboardEmbed
            dashboardId="a705d835-3ee1-43cd-bcff-1ce9401b64f4"
            filterId=""
          />
        )}
        {selectedSubSubSubTab === "Monitoring KPIs per Table" && (
          <DashboardEmbed
            dashboardId="2d784b3b-2645-4e4b-a780-9fe303f8e332"
            filterId=""
          />
        )}
        {selectedSubSubSubTab === "KPI Current vs Previous Month" && (
          <DashboardEmbed
            dashboardId="3407ca7a-d0cd-407d-8c01-9d35e45f8662"
            filterId=""
          />
        )}
        {selectedSubSubSubTab === "Current Monitoring Issues" && (
          <DashboardEmbed dashboardId="monitoring-current-issues" filterId="" />
        )}
        {selectedSubSubSubTab === "Current Table Availability Monitoring" && (
          <DashboardEmbed
            dashboardId="monitoring-current-table-availability"
            filterId=""
          />
        )}
        {selectedSubSubSubTab === "Monitoring Freshness - Current Data" && (
          <DashboardEmbed
            dashboardId="monitoring-freshness-current"
            filterId=""
          />
        )}
        {selectedSubSubSubTab === "Monitoring Freshness - Oldest Data" && (
          <DashboardEmbed
            dashboardId="monitoring-freshness-oldest"
            filterId=""
          />
        )}
        {selectedSubSubSubTab === "Current Monitoring Validity Issues" && (
          <DashboardEmbed
            dashboardId="monitoring-current-validity-issues"
            filterId=""
          />
        )}
        {selectedSubSubSubTab === "Current Monitoring Completeness Issues" && (
          <DashboardEmbed
            dashboardId="monitoring-current-completeness-issues"
            filterId=""
          />
        )}
        {selectedSubSubSubTab === "Executed Checks per Table" && (
          <DashboardEmbed
            dashboardId="3d119be0-c25e-4918-ade7-46ee428a57fc"
            filterId="4ei34f46nC"
          />
        )}
        {selectedSubSubSubTab === "Incomplete Monitoring Columns" && (
          <DashboardEmbed
            dashboardId="monitoring-incomplete-columns"
            filterId=""
          />
        )}
        {selectedSubSubSubTab === "Empty Monitoring Columns" && (
          <DashboardEmbed dashboardId="monitoring-empty-columns" filterId="" />
        )}
        {selectedSubSubSubTab === "Largest Monitored Tables" && (
          <DashboardEmbed dashboardId="monitoring-largest-tables" filterId="" />
        )}
        {selectedSubSubSubTab === "Empty or Small Monitored Tables" && (
          <DashboardEmbed
            dashboardId="monitoring-empty-small-tables"
            filterId=""
          />
        )}
        {selectedSubSubSubTab === "Monitored PII Data Detected" && (
          <DashboardEmbed
            dashboardId="monitoring-pii-data-detected"
            filterId=""
          />
        )}
        {/* {selectedSubSubSubTab ===
          "Monitoring Data Quality Executed Checks per Table" && (
          <DashboardEmbed
            dashboardId="3d119be0-c25e-4918-ade7-46ee428a57fc"
            filterId="4ei34f46nC"
          />
        )} */}
        {selectedSubSubSubTab === "Monitoring Issues Count per Check" && (
          <DashboardEmbed
            dashboardId="9af49881-2c3d-4349-aee3-db25aa0ee103"
            filterId="AMTeou2HHn"
          />
        )}
        {selectedSubSubSubTab === "Current Monitoring Check Results" && (
          <DashboardEmbed
            dashboardId="monitoring-current-check-results"
            filterId=""
          />
        )}
        {selectedSubSubSubTab === "History of Monitoring Check Results" && (
          <DashboardEmbed
            dashboardId="monitoring-history-check-results"
            filterId=""
          />
        )}
        {selectedSubSubSubTab === "History of Monitored PII Issues" && (
          <DashboardEmbed
            dashboardId="monitoring-history-pii-issues"
            filterId=""
          />
        )}
        {selectedSubSubSubTab === "Table Monitoring Comparison" && (
          <DashboardEmbed
            dashboardId="monitoring-table-comparison"
            filterId=""
          />
        )}
      </div>
    </div>
  );
}
