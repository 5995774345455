// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.switch-tab-three-heading {
  margin: 30px 0 15px;
}
.switch-tab-three-wrapper {
  border-radius: 10px;
  border: 1px solid #e7e7e7;
}

.tab-three-table-header {
  padding: 10px;
  display: grid;
  gap: 10px;
  grid-template-columns: 0.1fr 2fr 1fr 1fr 1fr;
  background-color: #fcfcfd;
  border-bottom: 1px solid #e7e7e7;
}
.tab-three-table-row {
  padding: 10px;
  display: grid;
  gap: 10px;
  grid-template-columns: 0.1fr 2fr 1fr 1fr 1fr;
  border-top: 1px solid #e7e7e7;
}
.tab-three-loading-bar {
  padding-left: 350px;
  margin-top: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/components/DataSetFlow/SwitchTabs/SwitchTabThree/SwitchTabThree.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;AACA;EACE,mBAAmB;EACnB,yBAAyB;AAC3B;;AAEA;EACE,aAAa;EACb,aAAa;EACb,SAAS;EACT,4CAA4C;EAC5C,yBAAyB;EACzB,gCAAgC;AAClC;AACA;EACE,aAAa;EACb,aAAa;EACb,SAAS;EACT,4CAA4C;EAC5C,6BAA6B;AAC/B;AACA;EACE,mBAAmB;EACnB,gBAAgB;AAClB","sourcesContent":[".switch-tab-three-heading {\n  margin: 30px 0 15px;\n}\n.switch-tab-three-wrapper {\n  border-radius: 10px;\n  border: 1px solid #e7e7e7;\n}\n\n.tab-three-table-header {\n  padding: 10px;\n  display: grid;\n  gap: 10px;\n  grid-template-columns: 0.1fr 2fr 1fr 1fr 1fr;\n  background-color: #fcfcfd;\n  border-bottom: 1px solid #e7e7e7;\n}\n.tab-three-table-row {\n  padding: 10px;\n  display: grid;\n  gap: 10px;\n  grid-template-columns: 0.1fr 2fr 1fr 1fr 1fr;\n  border-top: 1px solid #e7e7e7;\n}\n.tab-three-loading-bar {\n  padding-left: 350px;\n  margin-top: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
