// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tab-four-header-content {
  background-color: #fff2ef;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  margin: 25px 0;
}
.tab-four-header-heading {
  margin: 15px;
  display: flex;
  gap: 10px;
}

.tab-four-header-heading p {
  color: #fb7857;
}

.tab-four-btn {
  cursor: pointer;
  margin: 10px;
  padding: 6px 20px;
  border: 1px solid #fb7857;
  background-color: #ffffff;
  border-radius: 20px;
}
.switch-tab-four-wrapper {
  border-radius: 10px;
  border: 1px solid #e7e7e7;
}

.tab-four-table-header {
  padding: 10px;
  display: grid;
  gap: 10px;
  grid-template-columns: 0.1fr 1fr 1fr 1fr 2fr;
  background-color: #fcfcfd;
  border-bottom: 1px solid #e7e7e7;
}
.tab-four-table-row {
  padding: 10px;
  display: grid;
  gap: 10px;
  grid-template-columns: 0.1fr 1fr 1fr 1fr 2fr;
  border-top: 1px solid #e7e7e7;
}
.tab-four-loading-bar {
  padding-left: 520px;
}
`, "",{"version":3,"sources":["webpack://./src/components/DataSetFlow/SwitchTabs/SwitchTabFour/SwitchTabFour.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,mBAAmB;EACnB,aAAa;EACb,8BAA8B;EAC9B,cAAc;AAChB;AACA;EACE,YAAY;EACZ,aAAa;EACb,SAAS;AACX;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,eAAe;EACf,YAAY;EACZ,iBAAiB;EACjB,yBAAyB;EACzB,yBAAyB;EACzB,mBAAmB;AACrB;AACA;EACE,mBAAmB;EACnB,yBAAyB;AAC3B;;AAEA;EACE,aAAa;EACb,aAAa;EACb,SAAS;EACT,4CAA4C;EAC5C,yBAAyB;EACzB,gCAAgC;AAClC;AACA;EACE,aAAa;EACb,aAAa;EACb,SAAS;EACT,4CAA4C;EAC5C,6BAA6B;AAC/B;AACA;EACE,mBAAmB;AACrB","sourcesContent":[".tab-four-header-content {\n  background-color: #fff2ef;\n  border-radius: 10px;\n  display: flex;\n  justify-content: space-between;\n  margin: 25px 0;\n}\n.tab-four-header-heading {\n  margin: 15px;\n  display: flex;\n  gap: 10px;\n}\n\n.tab-four-header-heading p {\n  color: #fb7857;\n}\n\n.tab-four-btn {\n  cursor: pointer;\n  margin: 10px;\n  padding: 6px 20px;\n  border: 1px solid #fb7857;\n  background-color: #ffffff;\n  border-radius: 20px;\n}\n.switch-tab-four-wrapper {\n  border-radius: 10px;\n  border: 1px solid #e7e7e7;\n}\n\n.tab-four-table-header {\n  padding: 10px;\n  display: grid;\n  gap: 10px;\n  grid-template-columns: 0.1fr 1fr 1fr 1fr 2fr;\n  background-color: #fcfcfd;\n  border-bottom: 1px solid #e7e7e7;\n}\n.tab-four-table-row {\n  padding: 10px;\n  display: grid;\n  gap: 10px;\n  grid-template-columns: 0.1fr 1fr 1fr 1fr 2fr;\n  border-top: 1px solid #e7e7e7;\n}\n.tab-four-loading-bar {\n  padding-left: 520px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
