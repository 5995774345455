// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logout-wrapper {
  margin-bottom: 10px;
}
.logout-btn img {
  width: 22px;
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/components/Home/SideNavBar/Logout/Logout.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;AACA;EACE,WAAW;EACX,eAAe;AACjB","sourcesContent":[".logout-wrapper {\n  margin-bottom: 10px;\n}\n.logout-btn img {\n  width: 22px;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
