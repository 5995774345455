import "./ProfilingTable.css";
import { useState, useEffect } from "react";
import React from "react";
import delete1 from "./Images/delete1.svg";
import delete2 from "./Images/delete2.svg";
import stats from "./Images/stats.svg";
import drop from "./Images/drop-down.svg";
import video from "./Images/video-run.svg";
import Box from "@mui/material/Box";
import Switch from "@mui/material/Switch";
import Paper from "@mui/material/Paper";
import Fade from "@mui/material/Fade";
import FormControlLabel from "@mui/material/FormControlLabel";
import enable from "./Images/enable.svg";
import setting1 from "./Images/setting1.svg";
import clock from "./Images/clock.svg";
import runcheck from "./Images/run-check.svg";
import result from "./Images/result.svg";
import question from "./Images/question.svg";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import axios from "axios";
import { REACT_BASE_LOCAL_URL } from "../../config";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import {
  Checkbox,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  Select,
  Tooltip,
  tooltipClasses,
} from "@mui/material";
import AdjustIcon from "@mui/icons-material/Adjust";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import CheckAccordion from "../ProfilingColumn/ProfilingColumn";
import CircularProgress from '@mui/material/CircularProgress';

function ProfilingTable({ table, schema, connection, onBack }) {
  const [data, setData] = useState([]);
  const [loadingTab, setLoadingTab] = useState(false);
  const [columnName, setColumnName] = useState("");
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [refreshTrigger, setRefreshTrigger] = useState(0);
  const [activeTab, setActiveTab] = useState("data-statistics");
  const [activeTab2, setActiveTab2] = useState("column-stats");
  const [selectedTables, setSelectedTables] = useState([]);
  const [statusData, setStatusData] = useState(null);
  const [columnsData, setColumnsData] = useState([]);
  const [tableComparisons, setTableComparisons] = useState(false);
  const [connectionLevel, setConnectionLevel] = useState("");
  const [schemaLevel, setSchemaLevel] = useState("");
  const [tableLevel, setTableLevel] = useState("");
  const [severityLevel, setSeverityLevel] = useState();
  const [advancedSection, setAdvancedSection] = useState(false);
  useEffect(() => {
    if (activeTab2 === "profiling-column-rule1") {
      setLoadingTab(true); // Start loading when the tab is clicked

      // Simulate a delay or data fetching
      setTimeout(() => {
        setLoadingTab(false); // End loading after data is ready
      }); // You can adjust the delay to match data fetching time or remove if data is already being fetched
    }
  }, [activeTab2]);

  const fetchColumnStatistics = () => {
    axios
      .get(
        `${REACT_BASE_LOCAL_URL}/api/connections/${connection}/schemas/${schema}/tables/${table}/columns/statistics`
      )
      .then((response) => {
        // console.log("API Response:", response.data);
        const formattedData = response.data.column_statistics.map((column) => {
          const stats = column.statistics || [];
          const nullsPercentStat = stats.find(
            (stat) => stat.collector === "nulls_percent"
          );
          const nullsPercent = nullsPercentStat
            ? parseFloat(nullsPercentStat.result).toFixed(2) + "%"
            : "N/A";

          return {
            columnName: column.column_name || "N/A",
            detectedDataType:
              stats.find((stat) => stat.collector === "column_samples")
                ?.resultDataType || "N/A",
            importedDataType: column.type_snapshot?.column_type || "N/A",
            length: column.type_snapshot?.length || "N/A",
            nullsPercent: nullsPercent,
            distinctCount:
              stats.find((stat) => stat.collector === "distinct_count")
                ?.result || "N/A",
          };
        });
        setData(formattedData);
      })
      .catch((error) => {
        console.error("Error fetching the data:", error);
      });
  };

  useEffect(() => {
    fetchColumnStatistics();
  }, [connection, schema, table]);

  const [loadingStats, setLoadingStats] = useState({});
  const handleCollectStats = async (columnName) => {
    const payload = {
      connection: connection,
      fullTableName: `${schema}.${table}`,
      enabled: true,
      columnNames: columnName ? [columnName] : [],
    };

    setLoading1(true); // Start loading

    try {
      // Post request to collect statistics
      await axios.post(
        `${REACT_BASE_LOCAL_URL}/api/jobs/collectstatistics/table?configureTable=false&wait=false`,
        payload
      );

      // Wait for 2 seconds before fetching updated data
      await new Promise(resolve => setTimeout(resolve, 2000));

      // Fetch updated statistics
      const response = await axios.get(
        `${REACT_BASE_LOCAL_URL}/api/connections/${connection}/schemas/${schema}/tables/${table}/columns/statistics`
      );

      // Format the data
      const formattedData = response.data.column_statistics.map((column) => {
        const stats = column.statistics || [];
        const nullsPercentStat = stats.find(
          (stat) => stat.collector === "nulls_percent"
        );
        const nullsPercent = nullsPercentStat
          ? parseFloat(nullsPercentStat.result).toFixed(2) + "%"
          : "N/A";

        return {
          columnName: column.column_name || "N/A",
          detectedDataType:
            stats.find((stat) => stat.collector === "column_samples")?.resultDataType || "N/A",
          importedDataType: column.type_snapshot?.column_type || "N/A",
          length: column.type_snapshot?.length || "N/A",
          nullsPercent: nullsPercent,
          distinctCount:
            stats.find((stat) => stat.collector === "distinct_count")?.result || "N/A",
        };
      });

      setData(formattedData);
    } catch (error) {
      console.error("Error collecting statistics:", error);
    } finally {
      setLoading1(false); // Stop loading
    }
  };

  const handleCollectStats1 = async (columnName) => {
    const payload = {
      connection: connection,
      fullTableName: `${schema}.${table}`,
      enabled: true,
      columnNames: columnName ? [columnName] : [],
    };

    setLoadingStats((prevLoading) => ({
      ...prevLoading,
      [columnName]: true, // Set loading for the specific column
    }));

    try {
      // Post request to collect statistics
      await axios.post(
        `${REACT_BASE_LOCAL_URL}/api/jobs/collectstatistics/table?configureTable=false&wait=false`,
        payload
      );

      // Wait for 2 seconds before fetching updated data
      await new Promise(resolve => setTimeout(resolve, 2000));

      // Fetch updated statistics
      const response = await axios.get(
        `${REACT_BASE_LOCAL_URL}/api/connections/${connection}/schemas/${schema}/tables/${table}/columns/statistics`
      );

      // Format the data
      const formattedData = response.data.column_statistics.map((column) => {
        const stats = column.statistics || [];
        const nullsPercentStat = stats.find(
          (stat) => stat.collector === "nulls_percent"
        );
        const nullsPercent = nullsPercentStat
          ? parseFloat(nullsPercentStat.result).toFixed(2) + "%"
          : "N/A";

        return {
          columnName: column.column_name || "N/A",
          detectedDataType:
            stats.find((stat) => stat.collector === "column_samples")?.resultDataType || "N/A",
          importedDataType: column.type_snapshot?.column_type || "N/A",
          length: column.type_snapshot?.length || "N/A",
          nullsPercent: nullsPercent,
          distinctCount:
            stats.find((stat) => stat.collector === "distinct_count")?.result || "N/A",
        };
      });

      setData(formattedData);
    } catch (error) {
      console.error("Error collecting statistics:", error);
    } finally {
      setLoadingStats((prevLoading) => ({
        ...prevLoading,
        [columnName]: false, // Stop loading for the specific column
      }));
    }
  };

  const handleOpenDeleteDialog = (name) => {
    setColumnName(name);
    setOpenDeleteDialog(true);
  };
  const confirmDelete = () => {
    const deleteUrl = `${REACT_BASE_LOCAL_URL}/api/connections/${connection}/schemas/${schema}/tables/${table}/columns/${columnName}`;
    axios
      .delete(deleteUrl)
      .then((response) => {
        console.log("Delete successful:", response);
        setRefreshTrigger((prev) => prev + 1);
        setOpenDeleteDialog(false);
        fetchColumnStatistics();
      })
      .catch((error) => {
        console.error("Delete failed:", error);
        setOpenDeleteDialog(false);
      });
  };
  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };

  const handleCheckboxChange = (columnName) => {
    setSelectedTables((prevSelected) =>
      prevSelected.includes(columnName)
        ? prevSelected.filter((name) => name !== columnName)
        : [...prevSelected, columnName]
    );
  };

  const fetchStatusData = () => {
    const apiUrl = `${REACT_BASE_LOCAL_URL}/api/connections/${connection}/schemas/${schema}/tables/${table}/status?profiling=true&monitoring=false&partitioned=false`;
    axios
      .get(apiUrl)
      .then((response) => {
        setStatusData(response.data);
        const columns = Object.keys(response.data.columns).map((key) => ({
          name: key,
          ...response.data.columns[key],
        }));
        setColumnsData(columns);
      })
      .catch((error) => {
        console.error("Error fetching status data:", error);
      });
  };

  useEffect(() => {
    fetchStatusData();
  }, [connection, schema, table]);
  const formatValue = (value) =>
    value !== undefined && value !== null ? value : "N/A";

  const [checksVisibility, setChecksVisibility] = useState({});

  const toggleChecksVisibility = (columnName) => {
    setChecksVisibility((prev) => ({
      ...prev,
      [columnName]: !prev[columnName],
    }));
  };

  const [isVolumeExpanded, setIsVolumeExpanded] = useState(true);
  const toggleSection = (section) => {
    if (section === "volume") {
      setIsVolumeExpanded(!isVolumeExpanded);
    }
  };

  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const [checked, setChecked] = useState(false);

  const handleChange1 = () => {
    setChecked((prev) => !prev);
  };

  const options = ["Error", "Warning", "Fatal", "Multiple error"];
  const [value, setValue] = useState(options[0]);
  const [inputValue, setInputValue] = useState("Error");

  const [previewData, setPreviewData] = useState([]);
  const [maxSamplingResults, setMaxSamplingResults] = useState(0);

  const fetchData = async () => {
    try {
      // Fetch data from the API
      const response = await axios.get(
        `${REACT_BASE_LOCAL_URL}/api/connections/${connection}/schemas/${schema}/tables/${table}/columns/statistics`
      );

      // Process the response data
      const columnsData = response.data.column_statistics.map((col) => {
        const stats = {};

        // Ensure `col.statistics` exists and is an array before processing
        if (col.statistics && Array.isArray(col.statistics)) {
          col.statistics.forEach((stat) => {
            stats[stat.collector] = stat.result; // Store each stat result with its collector as the key
          });
        }

        // Extract sampling results (filter out only 'sampling' category)
        const samplingResults = col.statistics
          ? col.statistics.filter((stat) => stat.category === "sampling")
          : [];

        // Update `maxSamplingResults` to track the highest number of samples in any column
        if (samplingResults.length > maxSamplingResults) {
          setMaxSamplingResults((prevMax) =>
            Math.max(prevMax, samplingResults.length)
          );
        }

        // Return an object with all relevant column data
        return {
          columnName: col.column_name || "N/A",
          importedDatatype: col.type_snapshot?.column_type || "N/A",
          minValue: stats["min_value"] !== undefined ? stats["min_value"] : "N/A",
          maxValue: stats["max_value"] !== undefined ? stats["max_value"] : "N/A",
          distinctCount: stats["distinct_count"] !== undefined ? stats["distinct_count"] : "N/A",
          distinctPercent: stats["distinct_percent"] !== undefined
            ? stats["distinct_percent"].toFixed(2)
            : "N/A",
          duplicateCount: stats["duplicate_count"] !== undefined ? stats["duplicate_count"] : "N/A",
          duplicatePercent: stats["duplicate_percent"] !== undefined
            ? stats["duplicate_percent"].toFixed(2)
            : "N/A",
          nullsCount: stats["nulls_count"] !== undefined ? stats["nulls_count"] : "N/A",
          nullsPercent: stats["nulls_percent"] !== undefined
            ? stats["nulls_percent"].toFixed(2)
            : "N/A",
          notNullsCount: stats["not_nulls_count"] !== undefined ? stats["not_nulls_count"] : "N/A",
          notNullsPercent: stats["not_nulls_percent"] !== undefined
            ? stats["not_nulls_percent"].toFixed(2)
            : "N/A",
          textMeanLength: stats["text_mean_length"] !== undefined
            ? stats["text_mean_length"].toFixed(2)
            : "N/A",
          textMaxLength: stats["text_max_length"] !== undefined ? stats["text_max_length"] : "N/A",
          textMinLength: stats["text_min_length"] !== undefined ? stats["text_min_length"] : "N/A",
          samplingResults: samplingResults, // Store the array of sampling results for this column
        };
      });

      // Update state with the processed column data
      setPreviewData(columnsData);
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };



  useEffect(() => {
    fetchData();
  }, [connection, schema, table]);

  const columns = Array.from({ length: 9 }, (_, i) => `Column ${i + 1}`);

  const handleEditableValueChange = (index, event) => {
    setEditableValues((prev) => ({
      ...prev,
      [index]: event.target.value,
    }));
  };

  const handleToggle = (index) => (event) => {
    setCheckedStates((prev) => ({
      ...prev,
      [index]: event.target.checked,
    }));
  };

  const handleAutocompleteChange = (index) => (event, newValue) => {
    setSelectedValues((prev) => ({
      ...prev,
      [index]: newValue,
    }));
  };

  const handleInputChange = (index) => (event) => {
    setInputValues((prev) => ({
      ...prev,
      [index]: event.target.value,
    }));
  };

  const getTableRowCountResultClassName = () => {
    if (!comparisonResults1) return "white";
    const { profile_row_count_match } = comparisonResults1?.table_comparison_results;

    if (profile_row_count_match?.valid_results) {
      return "valid_result_cell";
    }
    if (profile_row_count_match?.warnings) {
      return "warning_cell";
    }
    if (profile_row_count_match?.errors) {
      return "error_cell";
    }
    return "white";
  };
  const getTableRowResultClassName = (referenceColumnName, metric) => {
    if (!comparisonResults2) return "white";

    const matchingRow = comparisonResults2.find(refRow => refRow.column_name === referenceColumnName);

    if (matchingRow) {
      const { comparison_results } = matchingRow;

      // Access profile_match results based on the metric
      const profileResults = comparison_results[`profile_${metric}_match`];

      if (profileResults) {
        if (profileResults.valid_results > 0) {
          return "valid_result_cell"; // Return valid class for valid results
        }
        if (profileResults.warnings > 0) {
          return "warning_cell"; // Return warning class for warnings
        }
        if (profileResults.fatals > 0) {
          return "error_cell"; // Return error class for fatals
        }
      }
    }
    return "white"; // Default class if no conditions are met
  };




  const getTableColumnCountResultClassName = () => {
    if (!comparisonResults1) return "white";
    const { profile_column_count_match } = comparisonResults1?.table_comparison_results;

    if (profile_column_count_match?.valid_results) {
      return "valid_result_cell";
    }
    if (profile_column_count_match?.warnings) {
      return "warning_cell";
    }
    if (profile_column_count_match?.errors) {
      return "error_cell";
    }
    return "white";
  }

  const formatDate = (dateStr) => {
    const date = new Date(dateStr);
    return date
      .toLocaleString("en-US", {
        year: "numeric",
        month: "short",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      })
      .replace(",", "");
  };

  const [editableValues, setEditableValues] = useState({});
  const [effectiveSchedule, setEffectiveSchedule] = useState(null);
  const [runChecksJobTemplate, setRunChecksJobTemplate] = useState(null);
  const [effectiveScheduleEnabledStatus, setEffectiveScheduleEnabledStatus] = useState("");

  const [checksData, setChecksData] = useState([]);
  const [checkedStates, setCheckedStates] = useState(
    Array(checksData.length).fill(false)
  );
  const [selectedValues, setSelectedValues] = useState(
    Array(checksData.length).fill("None")
  );
  const [inputValues, setInputValues] = useState(
    Array(checksData.length).fill("")
  );

  const fetchChecksData = async () => {
    try {
      const response = await axios.get(
        `${REACT_BASE_LOCAL_URL}/api/connections/${connection}/schemas/${schema}/tables/${table}/profiling/model`
      );
      const checks = response.data.categories;
      setChecksData(checks);

      const {
        effective_schedule,
        run_checks_job_template,
        effective_schedule_enabled_status,
      } = response.data;

      setEffectiveSchedule(effective_schedule);
      setRunChecksJobTemplate(run_checks_job_template);
      setEffectiveScheduleEnabledStatus(effective_schedule_enabled_status);

      const initialCheckedStates = [];
      const initialSelectedValues = [];
      const initialInputValues = [];
      const initialEditableValues = {};

      checks.forEach((category, catIndex) => {
        category.checks.forEach((check, checkIndex) => {
          const index = `${catIndex}-${checkIndex}`;
          initialCheckedStates[index] = false;
          initialSelectedValues[index] = "Error"; // Default selection
          initialInputValues[index] = "1"; // Default long_value
          initialEditableValues[index] =
            check.rule["error"].rule_parameters[0].definition.default_value; // Default value from the response
        });
      });

      setCheckedStates(initialCheckedStates);
      setSelectedValues(initialSelectedValues);
      setInputValues(initialInputValues);
      setEditableValues(initialEditableValues);
    } catch (error) {
      console.error("Error fetching checks data:", error);
    }
  };

  useEffect(() => {
    fetchChecksData();
  }, []);

  const [checksColumnData, setChecksColumnData] = useState([]);

  const fetchColumnChecksData = async (columnName) => {
    try {
      const response = await axios.get(
        `${REACT_BASE_LOCAL_URL}/api/connections/${connection}/schemas/${schema}/tables/${table}/columns/${columnName}/profiling/model`
      );
      const checks = response.data.categories;
      setChecksColumnData(checks);

      const {
        effective_schedule,
        run_checks_job_template,
        effective_schedule_enabled_status,
      } = response.data;

      setEffectiveSchedule(effective_schedule);
      setRunChecksJobTemplate(run_checks_job_template);
      setEffectiveScheduleEnabledStatus(effective_schedule_enabled_status);

      const initialCheckedStates = [];
      const initialSelectedValues = [];
      const initialInputValues = [];
      const initialEditableValues = {};

      checks.forEach((category, catIndex) => {
        category.checks.forEach((check, checkIndex) => {
          const index = `${catIndex}-${checkIndex}`;
          initialCheckedStates[index] = false;
          initialSelectedValues[index] = "Error"; // Default selection
          initialInputValues[index] = "1"; // Default long_value
          initialEditableValues[index] =
            check.rule["error"].rule_parameters[0].definition.default_value; // Default value from the response
        });
      });

      setCheckedStates(initialCheckedStates);
      setSelectedValues(initialSelectedValues);
      setInputValues(initialInputValues);
      setEditableValues(initialEditableValues);
    } catch (error) {
      console.error("Error fetching checks data:", error);
    }
  };

  const handleSave = async () => {
    const payload = {
      categories: checksData.map((category, catIndex) => {
        return {
          category: category.category,
          checks: category.checks.map((check, checkIndex) => {
            const index = `${catIndex}-${checkIndex}`;
            const severity = selectedValues[index]?.toLowerCase();
            const updatedLongValue =
              editableValues[index] ||
              check.rule[severity]?.rule_parameters[0]?.definition
                .default_value;

            return {
              friendly_name: check.friendly_name,
              check_name: check.check_name,
              quality_dimension: check.quality_dimension,
              rule: {
                [severity]: {
                  ...check.rule[severity],
                  long_value: Number(updatedLongValue),
                },
              },
              checked: checkedStates[index] || false,
            };
          }),
        };
      }),
    };

    setLoading(true); // Start loading state

    try {
      const response = await axios.put(
        `${REACT_BASE_LOCAL_URL}/api/connections/${connection}/schemas/${schema}/tables/${table}/profiling/model`,
        payload
      );
      console.log("Save response:", response.data);
    } catch (error) {
      console.error("Error saving checks data:", error);
    } finally {
      // Ensure loading stops after 2 seconds
      setTimeout(() => {
        setLoading(false); // Stop loading state after 2 seconds
      }, 2000);
    }
  };

  const handleColumnSave = async (columnName) => {
    // Ensure columnName is a string
    if (typeof columnName !== 'string' || columnName.trim() === '') {
      console.error("Invalid column name provided:", columnName);
      return;
    }

    const payload = {
      categories: checksColumnData.map((category, catIndex) => {
        return {
          category: category.category,
          checks: category.checks.map((check, checkIndex) => {
            const index = `${catIndex}-${checkIndex}`;
            const severity = selectedValues[index]?.toLowerCase();
            const updatedLongValue =
              editableValues[index] ||
              check.rule[severity]?.rule_parameters[0]?.definition.default_value;

            return {
              friendly_name: check.friendly_name,
              check_name: check.check_name,
              quality_dimension: check.quality_dimension,
              rule: {
                [severity]: {
                  ...check.rule[severity],
                  long_value: Number(updatedLongValue),
                },
              },
              checked: checkedStates[index] || false,
            };
          }),
        };
      }),
    };

    setLoading(true); // Start loading state

    try {
      const response = await axios.put(
        `${REACT_BASE_LOCAL_URL}/api/connections/${connection}/schemas/${schema}/tables/${table}/columns/${columnName}/profiling/model`,
        payload
      );
      console.log("Save response:", response.data);

      // Simulate 3 seconds loading time
      setTimeout(() => {
        setLoading(false); // Stop loading after at least 3 seconds
      }, 3000);

    } catch (error) {
      console.error("Error saving checks data:", error);

      // Stop loading in case of error after 3 seconds delay
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    }
  };

  const [loadingVideo, setLoadingVideo] = useState(false);
  const handleRunChecks = async () => {
    // Show loader
    setLoadingVideo(true);

    const payload = {
      check_search_filters: {
        connection: connection,
        fullTableName: `${schema}.${table}`,
        enabled: true,
        checkTarget: "table",
        checkType: "profiling",
      },
    };

    try {
      const apiUrl = `${REACT_BASE_LOCAL_URL}/api/jobs/runchecks?wait=false`;
      const response = await axios.post(apiUrl, payload);
      console.log("Run checks response:", response.data);

      // Simulate a 2-second delay after the request is made
      setTimeout(() => {
        setLoadingVideo(false); // Hide loader after 2 seconds
      }, 2000);
    } catch (error) {
      console.error("Error running checks:", error);
      setLoadingVideo(false); // Hide loader if an error occurs
    }
  };

  const handleRunCategoryChecks = async (category) => {
    const payload = {
      check_search_filters: {
        connection: connection,
        fullTableName: `${schema}.${table}`,
        enabled: true,
        checkTarget: "table",
        checkType: "profiling",
        checkCategory: category,
      },
      collect_error_samples: true,
    };

    try {
      const apiUrl = `${REACT_BASE_LOCAL_URL}/api/jobs/runchecks?wait=false`;
      const response = await axios.post(apiUrl, payload);
      console.log("Run checks response:", response.data);
    } catch (error) {
      console.error("Error running checks:", error);
    }
  };
  const [loadingCategory, setLoadingCategory] = useState(null);

  const [loadingIcon, setLoadingIcon] = useState(null);
  const handleIconClick = (event, category) => {
    event.stopPropagation();
    setLoadingCategory(category);
    handleRunCategoryChecks(category);
    setTimeout(() => {
      setLoadingCategory(null);
    }, 2000);
  };

  const [connections, setConnections] = useState([]);

  // Fetch connection names from API
  useEffect(() => {
    const fetchConnections = async () => {
      try {
        const response = await axios.get(
          `${REACT_BASE_LOCAL_URL}/api/connections`
        );
        const connectionNames = response.data.map(
          (conn) => conn.connection_name
        );
        setConnections(connectionNames); // Store connection names in state
      } catch (error) {
        console.error("Error fetching connections:", error);
      }
    };

    fetchConnections();
  }, []);

  const [schemas, setSchemas] = useState([]);

  // Fetch schemas when connectionLevel changes
  useEffect(() => {
    if (connectionLevel) {
      axios
        .get(
          `${REACT_BASE_LOCAL_URL}/api/connections/${connectionLevel}/schemas`
        )
        .then((response) => {
          const fetchedSchemas = response.data.map(
            (schema) => schema.schema_name
          );
          setSchemas(fetchedSchemas);
        })
        .catch((error) => {
          console.error("Error fetching schemas:", error);
        });
    }
  }, [connectionLevel]);

  const [tables, setTables] = useState([]);
  const [compareData, setcompareData] = useState([]);

  // Fetch tables when schemaLevel changes
  useEffect(() => {
    if (connectionLevel && schemaLevel) {
      axios
        .get(
          `${REACT_BASE_LOCAL_URL}/api/connections/${connectionLevel}/schemas/${schemaLevel}/tables`
        )
        .then((response) => {
          const fetchedTables = response.data.map(
            (table) => table.target.table_name
          );
          setTables(fetchedTables);
        })
        .catch((error) => {
          console.error("Error fetching tables:", error);
        });
    }
  }, [connectionLevel, schemaLevel]);

  const [columns1, setColumns1] = useState([]);
  const [referenceColumns, setReferenceColumns] = useState([]);
  const [selectedColumns, setSelectedColumns] = useState({});
  const [selectedReferenceColumns, setSelectedReferenceColumns] = useState({});

  useEffect(() => {
    const fetchColumns = async () => {
      try {
        const response = await fetch(
          `${REACT_BASE_LOCAL_URL}/api/connections/${connection}/schemas/${schema}/tables/${table}/columns?dataQualityStatus=false`
        );
        const data = await response.json();
        const columnNames = data.map((item) => item.column_name);
        setColumns1(columnNames);
      } catch (error) {
        console.error("Error fetching columns:", error);
      }
    };

    fetchColumns();
  }, [connection, schema, table]);

  const fetchGetReferenceColumns = async () => {
    try {
      const response = await fetch(
        `${REACT_BASE_LOCAL_URL}/api/connections/${connectionLevel}/schemas/${schemaLevel}/tables/${tableLevel}/columns?dataQualityStatus=false`
      );
      const data = await response.json();
      const referenceColumnNames = data.map((item) => item.column_name);
      setReferenceColumns(referenceColumnNames);
    } catch (error) {
      console.error("Error fetching reference columns:", error);
    }
  };

  useEffect(() => {
    if (connectionLevel && schemaLevel && tableLevel) {
      fetchGetReferenceColumns();
    }
  }, [connectionLevel, schemaLevel, tableLevel]);

  const handleColumnChange = (rowIndex, value) => {
    setSelectedColumns((prevSelected) => ({
      ...prevSelected,
      [rowIndex]: value,
    }));
  };

  const handleReferenceColumnChange = (rowIndex, value) => {
    setSelectedReferenceColumns((prevSelected) => ({
      ...prevSelected,
      [rowIndex]: value,
    }));
  };

  const [tableComparisonName, setTableComparisonName] = useState("");
  const [showResults, setShowResults] = useState(false);

  const [errorMessages, setErrorMessages] = useState({
    tableComparisonName: "",
    connectionLevel: "",
    schemaLevel: "",
    tableLevel: "",
  });

  const handleCompareSave = async () => {
    const errors = {};
    if (!tableComparisonName) {
      errors.tableComparisonName = "Table comparison configuration name is required";
    }
    if (!connectionLevel) {
      errors.connectionLevel = "Reference connection is required";
    }
    if (!schemaLevel) {
      errors.schemaLevel = "Reference schema is required";
    }
    if (!tableLevel) {
      errors.tableLevel = "Reference table is required";
    }
    if (Object.keys(errors).length > 0) {
      setErrorMessages(errors);
      return;
    }
    setErrorMessages({});

    const payload = {
      table_comparison_configuration_name: tableComparisonName,
      compared_connection: connection,
      compared_table: {
        schema_name: schema,
        table_name: table,
      },
      reference_connection: connectionLevel,
      reference_table: {
        schema_name: schemaLevel,
        table_name: tableLevel,
      },
      grouping_columns: [],
    };

    Object.keys(selectedColumns).forEach((rowIndex) => {
      const comparedColumn = selectedColumns[rowIndex];
      const referenceColumn = selectedReferenceColumns[rowIndex];

      if (comparedColumn && referenceColumn) {
        payload.grouping_columns.push({
          compared_table_column_name: comparedColumn,
          reference_table_column_name: referenceColumn,
        });
      }
    });

    setLoading(true); // Start loading state

    try {
      const response = await axios.post(
        `${REACT_BASE_LOCAL_URL}/api/connections/${connection}/schemas/${schema}/tables/${table}/tablecomparisons/profiling`,
        payload
      );

      console.log("Success:", response.data);

      const newComparison = {
        table_comparison_configuration_name: tableComparisonName,
        compared_connection: connection,
        compared_table: {
          schema_name: schema,
          table_name: table,
        },
        reference_connection: connectionLevel,
        reference_table: {
          schema_name: schemaLevel,
          table_name: tableLevel,
        },
      };

      setcompareData((prevData) => [...prevData, newComparison]);

      // Resetting state
      setTableComparisonName("");
      setConnectionLevel("");
      setSchemaLevel("");
      setTableLevel("");
      setSelectedColumns({});
      setSelectedReferenceColumns({});
      setColumns1([]);
      setReferenceColumns([]);

      setTableComparisons(false);

      // Simulate a 2 seconds loading time
      setTimeout(() => {
        setLoading(false); // Stop loading after at least 2 seconds
      }, 2000);
    } catch (error) {
      console.error("Error saving comparison:", error);

      // Stop loading in case of error after 2 seconds delay
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    }
  };

  const fetchComparisonData = async () => {
    try {
      const response = await fetch(
        `${REACT_BASE_LOCAL_URL}/api/connections/${connection}/schemas/${schema}/tables/${table}/tablecomparisonconfigurations?checkType=profiling`
      );
      const result = await response.json();
      setcompareData(result);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const [selectedComparison, setSelectedComparison] = useState(null);
  useEffect(() => {
    fetchComparisonData();
  }, []);
  const [comparisonResults, setComparisonResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleBackToComparisons = () => {
    setShowResults(false);
    setSelectedComparison(null);
  };

  const [expandedRows, setExpandedRows] = useState({});
  const [referenceTableDetails, setReferenceTableDetails] = useState({});

  const toggleRow = (index) => {
    setExpandedRows((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const [tableExpandedRows, setTableExpandedRows] = useState([]);

  const toggleTableRow = (index) => {
    setTableExpandedRows((prevExpandedRows) =>
      prevExpandedRows.includes(index)
        ? prevExpandedRows.filter((row) => row !== index)
        : [...prevExpandedRows, index]
    );
  };

  const handleComparisonClick = async (row) => {
    const comparisonName = row.table_comparison_configuration_name;
    setSelectedComparison(comparisonName);
    const apiUrl = `${REACT_BASE_LOCAL_URL}/api/connections/${connection}/schemas/${schema}/tables/${table}/tablecomparisons/${comparisonName}/profiling`;

    try {
      setIsLoading(true);
      const response = await fetch(apiUrl);
      const result = await response.json();

      if (result && result.columns) {
        const mappedResults = result.columns.map((col) => ({
          // Your existing mappings
          compared_table_name: result.compared_table.table_name,
          reference_connection: result.reference_connection,
          reference_schema: result.reference_table.schema_name,
          reference_table: result.reference_table.table_name,
          compared_column_name: col.compared_column_name,
          reference_column_name: col.reference_column_name,
          min_error_difference_percent: col.compare_min ? col.compare_min.error_difference_percent : null,
          min_fatal_difference_percent: col.compare_min ? col.compare_min.fatal_difference_percent : null,
          min_warning_difference_percent: col.compare_min ? col.compare_min.warning_difference_percent : null,
          max_error_difference_percent: col.compare_max ? col.compare_max.error_difference_percent : null,
          max_warning_difference_percent: col.compare_max ? col.compare_max.warning_difference_percent : null,
          max_fatal_difference_percent: col.compare_max ? col.compare_max.fatal_difference_percent : null,
          sum_error_difference_percent: col.compare_sum ? col.compare_sum.error_difference_percent : null,
          sum_warning_difference_percent: col.compare_sum ? col.compare_sum.warning_difference_percent : null,
          sum_fatal_difference_percent: col.compare_sum ? col.compare_sum.fatal_difference_percent : null,
          mean_error_difference_percent: col.compare_mean ? col.compare_mean.error_difference_percent : null,
          mean_warning_difference_percent: col.compare_mean ? col.compare_mean.warning_difference_percent : null,
          mean_fatal_difference_percent: col.compare_mean ? col.compare_mean.fatal_difference_percent : null,
          null_count_error_difference_percent: col.compare_null_count ? col.compare_null_count.error_difference_percent : null,
          null_count_warning_difference_percent: col.compare_null_count ? col.compare_null_count.warning_difference_percent : null,
          null_count_fatal_difference_percent: col.compare_null_count ? col.compare_null_count.fatal_difference_percent : null,
          not_null_count_error_difference_percent: col.compare_not_null_count ? col.compare_not_null_count.error_difference_percent : null,
          not_null_count_warning_difference_percent: col.compare_not_null_count ? col.compare_not_null_count.warning_difference_percent : null,
          not_null_count_fatal_difference_percent: col.compare_not_null_count ? col.compare_not_null_count.fatal_difference_percent : null,
          default_error_difference_percent: result.default_compare_thresholds ? result.default_compare_thresholds.error_difference_percent : null,

          compare_row_count_error_difference_percentt: result.compare_row_count ? result.compare_row_count.error_difference_percent : null,
          compare_column_count_error_difference_percent: result.compare_column_count ? result.compare_column_count.error_difference_percent : null,

          compare_row_count_warning_difference_percent: result.compare_row_count ? result.compare_row_count.warning_difference_percent : null,
          compare_row_count_fatal_difference_percent: result.compare_row_count ? result.compare_row_count.fatal_difference_percent : null,
          compare_column_count_warning_difference_percent: result.compare_column_count ? result.compare_column_count.warning_difference_percent : null,
          compare_column_count_fatal_difference_percent: result.compare_column_count ? result.compare_column_count.fatal_difference_percent : null,
        }));
        // Initialize checkedMetrics based on the API response
        const initialCheckedMetrics = mappedResults.map((col) => ({
          min: !!col.min_warning_difference_percent || !!col.min_error_difference_percent || !!col.min_fatal_difference_percent,
          max: !!col.max_warning_difference_percent || !!col.max_error_difference_percent || !!col.max_fatal_difference_percent,
          sum: !!col.sum_warning_difference_percent || !!col.sum_error_difference_percent || !!col.sum_fatal_difference_percent,
          mean: !!col.mean_warning_difference_percent || !!col.mean_error_difference_percent || !!col.mean_fatal_difference_percent,
          nullsCount: !!col.null_count_warning_difference_percent || !!col.null_count_error_difference_percent || !!col.null_count_fatal_difference_percent,
          notNullsCount: !!col.not_null_count_warning_difference_percent || !!col.not_null_count_error_difference_percent || !!col.not_null_count_fatal_difference_percent,
        }));
        // Check if there are any valid compared and reference columns for row and column counts
        const hasRowCount = mappedResults.some(col => col.compared_column_name !== null);
        const hasColumnCount = mappedResults.some(col => col.reference_column_name !== null);

        setCheckedMetrics(initialCheckedMetrics); // Update checkedMetrics state

        // Set the checked state for Row Count and Column Count checkboxes
        setCheckedRows([hasRowCount, hasColumnCount]);

        setComparisonResults(mappedResults);
        console.log(mappedResults)
        setReferenceTableDetails({
          referenceConnection: result.reference_connection,
          referenceSchema: result.reference_table.schema_name,
          referenceTable: result.reference_table.table_name,
        });
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching comparison results:", error);
      setIsLoading(false);
    }
    setShowResults(true);
    await fetchComparisonResults(comparisonName);
  };

  const handleBack = () => {
    setShowResults(false);
  };

  const [rowCount, setRowCount] = useState(null);
  const [columnCount, setColumnCount] = useState(null);
  const [collectedAt, setCollectedAt] = useState(null);

  useEffect(() => {
    axios
      .get(
        `${REACT_BASE_LOCAL_URL}/api/connections/${connection}/schemas/${schema}/tables/${table}/statistics`
      )
      .then((response) => {
        const { statistics } = response.data;
        const rowCountStat = statistics.find(
          (stat) => stat.collector === "row_count"
        );
        const columnCountStat = statistics.find(
          (stat) => stat.collector === "column_count"
        );

        if (rowCountStat) setRowCount(rowCountStat.result);
        if (columnCountStat) setColumnCount(columnCountStat.result);
        if (rowCountStat) {
          const date = new Date(rowCountStat.collectedAt);
          const formattedDate = `${date.getFullYear()}-${String(
            date.getMonth() + 1
          ).padStart(2, "0")}-${String(date.getDate()).padStart(
            2,
            "0"
          )} ${String(date.getHours()).padStart(2, "0")}:${String(
            date.getMinutes()
          ).padStart(2, "0")}:${String(date.getSeconds()).padStart(2, "0")}`;
          setCollectedAt(formattedDate);
        }
      })
      .catch((error) => {
        console.error("Error fetching statistics:", error);
      });
  }, []);

  const [severityLevel1, setSeverityLevel1] = useState("error");
  const [errorRate, setErrorRate] = useState("");
  const [copyFailedProfilingChecks, setCopyFailedProfilingChecks] = useState(false);
  const [copyDisabledProfilingChecks, setCopyDisabledProfilingChecks] = useState(false);
  const [copyProfilingChecks, setCopyProfilingChecks] = useState(false);
  const [reconfigurePolicyEnabledChecks, setReconfigurePolicyEnabledChecks] = useState(false);
  const [minimumRowCount, setMinimumRowCount] = useState(false);
  const [expectedColumnsCount, setExpectedColumnsCount] = useState(false);
  const [columnExists, setColumnExists] = useState(false);
  const [timelinessChecks, setTimelinessChecks] = useState(false);
  const [prohibitNulls, setProhibitNulls] = useState(false);
  const [requireNulls, setRequireNulls] = useState(false);
  const [detectedDatatypeTexts, setDetectedDatatypeTexts] = useState(false);
  const [uniquenessChecks, setUniquenessChecks] = useState(false);
  const [numericValueRanges, setNumericValueRanges] = useState(false);
  const [medianPercentileRanges, setMedianPercentileRanges] = useState(false);
  const [textLengthRanges, setTextLengthRanges] = useState(false);
  const [wordCountInRange, setWordCountInRange] = useState(false);
  const [percentTrueFalse, setPercentTrueFalse] = useState(false);
  const [datesOutOfRange, setDatesOutOfRange] = useState(false);
  const [valuesInSet, setValuesInSet] = useState(false);
  const [rareValuesInvalid, setRareValuesInvalid] = useState(false);
  const [textsTopValues, setTextsTopValues] = useState(false);
  const [textsParsableDataTypes, setTextsParsableDataTypes] = useState(false);
  const [standardTextPatterns, setStandardTextPatterns] = useState(false);
  const [detectRegEx, setDetectRegEx] = useState(false);
  const [whitespaceChecks, setWhitespaceChecks] = useState(false);
  const [applyPIICheck, setApplyPIICheck] = useState(false);
  const [customChecks, setCustomChecks] = useState(false);

  const handleSubmit = async () => {
    const payload = {
      severity_level: severityLevel1,
      fail_checks_at_percent_error_rows: parseInt(errorRate) || 0,
      copy_failed_profiling_checks: copyFailedProfilingChecks,
      copy_disabled_profiling_checks: copyDisabledProfilingChecks,
      copy_profiling_checks: copyProfilingChecks,
      reconfigure_policy_enabled_checks: reconfigurePolicyEnabledChecks,
      propose_minimum_row_count: minimumRowCount,
      propose_column_count: expectedColumnsCount,
      propose_column_exists: columnExists,
      propose_timeliness_checks: timelinessChecks,
      propose_nulls_checks: prohibitNulls,
      propose_not_nulls_checks: requireNulls,
      propose_text_values_data_type: detectedDatatypeTexts,
      propose_uniqueness_checks: uniquenessChecks,
      propose_numeric_ranges: numericValueRanges,
      propose_percentile_ranges: medianPercentileRanges,
      propose_text_length_ranges: textLengthRanges,
      propose_word_count_ranges: wordCountInRange,
      propose_bool_percent_checks: percentTrueFalse,
      propose_date_checks: datesOutOfRange,
      propose_values_in_set_checks: valuesInSet,
      values_in_set_treat_rare_values_as_invalid: rareValuesInvalid,
      propose_top_values_checks: textsTopValues,
      propose_text_conversion_checks: textsParsableDataTypes,
      propose_standard_pattern_checks: standardTextPatterns,
      detect_regular_expressions: detectRegEx,
      propose_whitespace_checks: whitespaceChecks,
      apply_pii_checks: applyPIICheck,
      propose_custom_checks: customChecks,
      category_filter: "",
      column_name_filter: "",
      check_name_filter: "",
    };

    setLoading2(true); // Start loading

    // Wait for 2 seconds before making the API call
    await new Promise(resolve => setTimeout(resolve, 2000));

    try {
      const response = await fetch(
        REACT_BASE_LOCAL_URL +
        `/api/connections/${connection}/schemas/${schema}/tables/${table}/profiling/propose`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      const data = await response.json();
      console.log("Success:", data);
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading2(false); // Stop loading
    }
  };

  const [editedValues, setEditedValues] = useState({});

  const handleInputChange1 = (event, index, field) => {
    const value = Number(event.target.value);
    setEditedValues((prev) => ({
      ...prev,
      [index]: {
        ...prev[index],
        [field]: value,
      },
    }));
  };
  const [comparisonResults1, setComparisonResults1] = useState(null);
  const [comparisonResults2, setComparisonResults2] = useState([]);
  const [showMismatched, setShowMismatched] = useState(false);
  const [mismatchedColumns, setMismatchedColumns] = useState([]);
  const fetchComparisonResults = async (comparisonName) => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${REACT_BASE_LOCAL_URL}/api/connections/${connection}/schemas/${schema}/tables/${table}/profiling/comparisons/${comparisonName}/results`
      );

      setComparisonResults1(response.data);
      console.log(response.data, "response for comparisonResults1");

      const columnComparisonResults = response.data.column_comparison_results;
      const comparisonResultsArray = Object.keys(columnComparisonResults).map((key) => ({
        column_name: columnComparisonResults[key].column_name,
        comparison_results: columnComparisonResults[key].column_comparison_results,
      }));

      setComparisonResults2(comparisonResultsArray);
      console.log(comparisonResultsArray, "response for comparisonResults2");
      extractNotMatchingDataGroups(response.data);
    } catch (error) {
      console.error("Error fetching comparison results:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const extractNotMatchingDataGroups = (data) => {
    const notMatchingDataGroups = [];
    if (
      data.table_comparison_results?.profile_row_count_match
        ?.not_matching_data_groups
    ) {
      notMatchingDataGroups.push(
        ...data.table_comparison_results.profile_row_count_match
          .not_matching_data_groups
      );
    }

    if (
      data.table_comparison_results?.profile_column_count_match
        ?.not_matching_data_groups
    ) {
      notMatchingDataGroups.push(
        ...data.table_comparison_results.profile_column_count_match
          .not_matching_data_groups
      );
    }

    Object.values(data.column_comparison_results).forEach((columnResult) => {
      Object.values(columnResult.column_comparison_results).forEach(
        (checkResult) => {
          if (checkResult.not_matching_data_groups) {
            notMatchingDataGroups.push(...checkResult.not_matching_data_groups);
          }
        }
      );
    });

    setMismatchedColumns(notMatchingDataGroups);
  };
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [loading3, setLoading3] = useState(false);

  const handleSubmit1 = async (row) => {
    const apiUrl = `${REACT_BASE_LOCAL_URL}/api/connections/${connection}/schemas/${schema}/tables/${table}/tablecomparisons/${selectedComparison}/profiling`;
    setLoading3(true);


    try {
      const response = await fetch(apiUrl);
      const result = await response.json();


      if (!result || !result.columns) {
        throw new Error("Invalid response data");
      }

      let payload = {
        table_comparison_configuration_name:
          result.table_comparison_configuration_name,
        compared_connection: result.compared_connection,
        compared_table: {
          schema_name: result.compared_table.schema_name,
          table_name: result.compared_table.table_name,
        },
        reference_connection: result.reference_connection,
        reference_table: {
          schema_name: result.reference_table.schema_name,
          table_name: result.reference_table.table_name,
        },
        default_compare_thresholds: {
          error_difference_percent:
            result.default_compare_thresholds?.error_difference_percent ?? 1,
        },
      };
      if (checkedRows[0]) {
        payload.compare_row_count = {
          warning_difference_percent: editedValues[0]?.compare_row_count_warning_difference_percent ??
            result.compare_row_count?.warning_difference_percent ?? 0,
          error_difference_percent: editedValues[0]?.compare_row_count_error_difference_percent ??
            result.compare_row_count?.error_difference_percent ?? 1,
          fatal_difference_percent: editedValues[0]?.compare_row_count_fatal_difference_percent ??
            result.compare_row_count?.fatal_difference_percent ?? 0,
        };
      }

      if (checkedRows[1]) {
        payload.compare_column_count = {
          warning_difference_percent: editedValues[0]?.compare_column_count_warning_difference_percent ??
            result.compare_column_count?.warning_difference_percent ?? 0,
          error_difference_percent: editedValues[0]?.compare_column_count_error_difference_percent ??
            result.compare_column_count?.error_difference_percent ?? 1,
          fatal_difference_percent: editedValues[0]?.compare_column_count_fatal_difference_percent ??
            result.compare_column_count?.fatal_difference_percent ?? 0,
        };
      }

      payload.columns = result.columns.map((col, index) => {
        const metrics = checkedMetrics[index] || {};
        const editedColumn = editedValues[index] || {};

        return {
          compared_column_name: col.compared_column_name,
          reference_column_name: col.reference_column_name || null,
          compare_min: metrics.min ? {
            error_difference_percent: editedColumn.min_error_difference_percent ?? col.compare_min?.error_difference_percent ?? 1,
            warning_difference_percent: editedColumn.min_warning_difference_percent ?? col.compare_min?.warning_difference_percent ?? 0,
            fatal_difference_percent: editedColumn.min_fatal_difference_percent ?? col.compare_min?.fatal_difference_percent ?? 0,
          } : undefined,
          compare_max: metrics.max ? {
            error_difference_percent: editedColumn.max_error_difference_percent ?? col.compare_max?.error_difference_percent ?? 1,
            warning_difference_percent: editedColumn.max_warning_difference_percent ?? col.compare_max?.warning_difference_percent ?? 0,
            fatal_difference_percent: editedColumn.max_fatal_difference_percent ?? col.compare_max?.fatal_difference_percent ?? 0,
          } : undefined,
          compare_sum: metrics.sum ? {
            error_difference_percent: editedColumn.sum_error_difference_percent ?? col.compare_sum?.error_difference_percent ?? 1,
            warning_difference_percent: editedColumn.sum_warning_difference_percent ?? col.compare_sum?.warning_difference_percent ?? 0,
            fatal_difference_percent: editedColumn.sum_fatal_difference_percent ?? col.compare_sum?.fatal_difference_percent ?? 0,
          } : undefined,
          compare_mean: metrics.mean ? {
            error_difference_percent: editedColumn.mean_error_difference_percent ?? col.compare_mean?.error_difference_percent ?? 1,
            warning_difference_percent: editedColumn.mean_warning_difference_percent ?? col.compare_mean?.warning_difference_percent ?? 0,
            fatal_difference_percent: editedColumn.mean_fatal_difference_percent ?? col.compare_mean?.fatal_difference_percent ?? 0,
          } : undefined,
          compare_null_count: metrics.nullsCount ? {
            error_difference_percent: editedColumn.null_count_error_difference_percent ?? col.compare_null_count?.error_difference_percent ?? 1,
            warning_difference_percent: editedColumn.null_count_warning_difference_percent ?? col.compare_null_count?.warning_difference_percent ?? 0,
            fatal_difference_percent: editedColumn.null_count_fatal_difference_percent ?? col.compare_null_count?.fatal_difference_percent ?? 0,
          } : undefined,
          compare_not_null_count: metrics.notNullsCount ? {
            error_difference_percent: editedColumn.not_null_count_error_difference_percent ?? col.compare_not_null_count?.error_difference_percent ?? 1,
            warning_difference_percent: editedColumn.not_null_count_warning_difference_percent ?? col.compare_not_null_count?.warning_difference_percent ?? 0,
            fatal_difference_percent: editedColumn.not_null_count_fatal_difference_percent ?? col.compare_not_null_count?.fatal_difference_percent ?? 0,
          } : undefined,
        };
      });

      const postApiUrl = `${REACT_BASE_LOCAL_URL}/api/connections/${connection}/schemas/${schema}/tables/${table}/tablecomparisons/profiling/${selectedComparison}`;
      const postResponse = await fetch(postApiUrl, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });


      if (!postResponse.ok) {
        throw new Error("Failed to update the comparison results");
      }


      const runChecksResponse = await handleRunComparisionChecks();
      const jobId = runChecksResponse?.jobId?.jobId;


      if (jobId) {
        await pollJobStatus(jobId);
      } else {
        throw new Error("No jobId returned from run checks");
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading3(false);
    }
  };


  const [checkedMetrics, setCheckedMetrics] = useState({});

  const handleMetricChange = (index, metric) => {
    setCheckedMetrics((prev) => ({
      ...prev,
      [index]: {
        ...prev[index],
        [metric]: !prev[index]?.[metric],
      },
    }));
  };


  const pollJobStatus = async (jobId) => {
    const statusApiUrl = `${REACT_BASE_LOCAL_URL}/api/jobs/jobs/${jobId}`;
    let status = "queued";
    while (status !== "finished") {
      const statusResponse = await fetch(statusApiUrl);
      const statusResult = await statusResponse.json();

      status = statusResult.status;
      if (status === "finished") {
        await fetchComparisonResults(selectedComparison);
        break;
      }
      await new Promise((resolve) => setTimeout(resolve, 5000));
    }
  };

  const handleRunComparisionChecks = async () => {
    const payload = {
      check_search_filters: {
        connection: connection,
        fullTableName: `${schema}.${table}`,
        enabled: true,
        checkTarget: "table",
        checkType: "profiling",
        checkCategory: "comparisons",
        tableComparisonName: selectedComparison,
      },
    };
    try {
      const apiUrl = `${REACT_BASE_LOCAL_URL}/api/jobs/runchecks?wait=false`;
      const response = await axios.post(apiUrl, payload);
      return response.data;
    } catch (error) {
      console.error("Error running checks:", error);
      throw error;
    }
  };

  const [selectedProfileColumns, setSelectedProfileColumns] = useState([]);
  const [isProfilingVisible, setProfilingVisible] = useState(true);

  // const handleColumnClick = (columnName) => {
  //   setSelectedProfileColumns([columnName]);
  //   setProfilingVisible(false);
  //   console.log("Selected Column:", columnName);
  // };

  const onBackProfile = () => {
    setProfilingVisible(true);
  };

  const [statsData, setStatsData] = useState(null);

  const fetchColumnStatistics1 = (columnName) => {
    axios
      .get(`${REACT_BASE_LOCAL_URL}/api/connections/${connection}/schemas/${schema}/tables/${table}/columns/${columnName}/statistics`)
      .then((response) => {
        setStatsData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching statistics:", error);
      });
  };

  const handleColumnClick = (columnName) => {
    setSelectedProfileColumns(columnName);
    setProfilingVisible(false);
    console.log("Selected Column:", columnName);
    fetchColumnStatistics1(columnName);
    fetchColumnChecksData(columnName);
  };

  const getStat = (category, collector, field = "result") => {
    const stat = statsData?.statistics.find(
      (item) => item.category === category && item.collector === collector
    );
    return stat ? stat[field] : "N/A";
  };

  const formatDate1 = (dateString) => {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return "N/A";
    }
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

  const [checkedRows, setCheckedRows] = useState([false, false]);
  const handleCheckboxChange1 = (index) => {
    setCheckedRows((prev) => {
      if (index < 0 || index >= prev.length) {
        console.error("Index out of bounds");
        return prev;
      }
      const updatedCheckedRows = [...prev];
      updatedCheckedRows[index] = !updatedCheckedRows[index];
      return updatedCheckedRows;
    });
  };

  const renderResults = (row, metricType) => {
    const results = comparisonResults2.find(refRow => refRow.column_name === row.reference_column_name)?.comparison_results;

    if (results) {
      const validResults = results[`profile_${metricType}_match`]?.valid_results || 0;
      const warnings = results[`profile_${metricType}_match`]?.warnings || 0;
      const fatals = results[`profile_${metricType}_match`]?.fatals || 0;
      const hasResults = validResults > 0 || warnings > 0 || fatals > 0;

      return (
        <React.Fragment>
          {hasResults ? (
            <>
              <p>Correct results: {validResults}</p>
              <p>Warning: {warnings}</p>
              <p>Fatal errors: {fatals}</p>
            </>
          ) : (
            <p>No results available for this column.</p>
          )}
        </React.Fragment>
      );
    }
    return <p>No results available for this column.</p>;
  };


  return (
    <div>
      {isProfilingVisible ? (
        <div className="profiling-container">
          <div className="top-profiling-conatiner">
            <h1>
              Profiling checks for {connection}.{schema}.{table}
            </h1>
            <div className="profiling-action">
              {/* <button onClick={handleSave}>Save</button> */}
              <LoadingButton
                size="20px"
                color="secondary"
                onClick={handleSave}
                loading={loading}
                loadingPosition="start"
                startIcon={<SaveIcon />}
                variant="contained"
                sx={{
                  height: "34px",
                  padding: "8px 12px",
                  textTransform: "none",
                  backgroundColor: "#f9f9f9",
                  color: "#000000",
                  borderRadius: "10px",
                  border: "1px solid #fb7857",
                  cursor: "pointer",
                  marginRight: "20px",
                  typography: {
                    fontSize: "14px",
                  },
                  "& .MuiButton-startIcon": {
                    fontSize: "10px",
                  },
                  "&:hover": {
                    backgroundColor: "#fb7857",
                  },
                }}
              >
                Save
              </LoadingButton>{" "}
              <LoadingButton
                size="20px"
                color="secondary"
                onClick={() => handleCollectStats()}
                loading={loading1}
                loadingIndicator="Loading…"
                variant="outlined"
                sx={{
                  height: "34px",
                  padding: "8px 12px",
                  textTransform: "none",
                  backgroundColor: "#f9f9f9",
                  color: "#000000",
                  borderRadius: "10px",
                  border: "1px solid #fb7857",
                  cursor: "pointer",
                  marginRight: "20px",
                  typography: {
                    fontSize: "14px",
                  },
                  "& .MuiButton-startIcon": {
                    fontSize: "10px",
                  },
                  "&:hover": {
                    backgroundColor: "#fb7857",
                  },
                }}
              >
                Collect statistics
              </LoadingButton>
              {/* <button onClick={() => handleCollectStats()}>
                Collect statistics
              </button> */}
              <button onClick={onBack}>Back</button>
            </div>
          </div>
          <div className="tab-data-overview">
            <div
              className={`tab-data ${activeTab === "data-statistics" ? "active" : ""
                }`}
              onClick={() => {
                setActiveTab("data-statistics");
              }}
            >
              <p>Basic data statistics</p>
            </div>
            <div
              className={`tab-data ${activeTab === "table-preview" ? "active" : ""
                }`}
              onClick={() => {
                setActiveTab("table-preview");
              }}
            >
              <p>Table preview</p>
            </div>
            <div
              className={`tab-data ${activeTab === "table-quality-status" ? "active" : ""
                }`}
              onClick={() => {
                setActiveTab("table-quality-status");
              }}
            >
              <p>Table quality status</p>
            </div>
            <div
              className={`tab-data ${activeTab === "profiling-checks-editor" ? "active" : ""
                }`}
              onClick={() => {
                setActiveTab("profiling-checks-editor");
              }}
            >
              <p>Profiling checks editor</p>
            </div>
            <div
              className={`tab-data ${activeTab === "profiling-comparision" ? "active" : ""
                }`}
              onClick={() => {
                setActiveTab("profiling-comparision");
              }}
            >
              <p>Table comparision</p>
            </div>
            <div
              className={`tab-data ${activeTab === "profiling-rule" ? "active" : ""
                }`}
              onClick={() => {
                setActiveTab("profiling-rule");
              }}
            >
              <p>Data quality rule mining</p>
            </div>
          </div>
          {activeTab === "data-statistics" && (
            <div className="data-statistics-conatiner">
              <div className="table-statistics">
                <div className="table-header">
                  <p>
                    Total rows:{" "}
                    <strong>
                      {rowCount !== null ? rowCount : "Loading..."}
                    </strong>
                  </p>
                  <p>
                    Column count:{" "}
                    <strong>
                      {columnCount !== null ? columnCount : "Loading..."}
                    </strong>
                  </p>
                  <p>
                    Collected at:{" "}
                    <strong>
                      {collectedAt !== null ? collectedAt : "Loading..."}
                    </strong>
                  </p>
                </div>
                <table className="statistics-table">
                  <thead>
                    <tr>
                      <th>Dimensions</th>
                      <th>Column name</th>
                      <th>Detected data type</th>
                      <th>Imported data type</th>
                      <th>Length</th>
                      <th>Nulls percent</th>
                      <th>Distinct count</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.length > 0 ? (
                      data.map((row, index) => (
                        <tr key={index}>
                          <td>
                            <div
                              className="dimension-wrapper"
                              style={{
                                display: "flex",
                                gap: "10px",
                                alignItems: "center",
                              }}
                            >
                              <input
                                className="stats-input"
                                type="checkbox"
                                checked={selectedTables.includes(
                                  row.columnName
                                )}
                                onChange={() =>
                                  handleCheckboxChange(row.columnName)
                                }
                              />
                              <div className="stats-wrapper">
                                <div className="round"></div>
                                <div className="red-round"></div>
                                <div className="round"></div>
                              </div>
                            </div>
                          </td>
                          <td
                            onClick={() => handleColumnClick(row.columnName)}
                            style={{
                              cursor: "pointer",
                              textDecoration: "underline",
                            }}
                          >
                            {row.columnName || "N/A"}
                          </td>
                          <td>{row.detectedDataType || "N/A"}</td>
                          <td>{row.importedDataType || "N/A"}</td>
                          <td>{row.length || "N/A"}</td>
                          <td>{row.nullsPercent || "N/A"}</td>
                          <td>
                            <div className="distinct-count">
                              {row.distinctCount || "N/A"}
                            </div>
                          </td>
                          <td>
                            <div className="profiling-action-wrapper">
                              <div className="profiling-action-buttom">
                                <img
                                  src={stats}
                                  alt="stats"
                                  onClick={() =>
                                    handleCollectStats(row.columnName)
                                  }
                                  style={{ cursor: "pointer" }}
                                />
                              </div>
                              <div className="profiling-action-buttom">
                                <img
                                  onClick={() =>
                                    handleOpenDeleteDialog(row.columnName)
                                  }
                                  src={delete1}
                                  alt="delete"
                                  style={{ cursor: "pointer" }}
                                />
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="8">No data available</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <Dialog
                open={openDeleteDialog}
                onClose={handleCloseDeleteDialog}
                sx={{
                  "& .MuiDialog-paper": {
                    padding: "20px",
                    borderRadius: "12px",
                  },
                }}
              >
                <DialogTitle
                  sx={{
                    backgroundColor: "#f5f5f5",
                    fontSize: "1.5rem",
                    fontWeight: "bold",
                    color: "#333",
                  }}
                >
                  Confirm Deletion
                </DialogTitle>
                <DialogContent sx={{ padding: "16px" }}>
                  <DialogContentText
                    sx={{
                      fontSize: "1rem",
                      color: "#666",
                      marginBottom: "16px",
                    }}
                  >
                    Are you sure you want to delete the column "{columnName}"?
                  </DialogContentText>
                </DialogContent>
                <DialogActions
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    padding: "16px",
                  }}
                >
                  <Button
                    onClick={handleCloseDeleteDialog}
                    sx={{
                      backgroundColor: "#f5f5f5",
                      color: "#333",
                      fontWeight: "bold",
                      marginRight: "8px",
                      padding: "8px 16px",
                      borderRadius: "4px",
                      "&:hover": { backgroundColor: "#e0e0e0" },
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={confirmDelete}
                    sx={{
                      backgroundColor: "#d32f2f",
                      color: "white",
                      fontWeight: "bold",
                      padding: "8px 16px",
                      borderRadius: "4px",
                      "&:hover": { backgroundColor: "#c62828" },
                    }}
                  >
                    Confirm
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
          )}

          {activeTab === "table-preview" && (
            <div className="table-preview-conatainer">
              <table className="table-preview">
                <thead>
                  <tr>
                    <th>Column Name</th>
                    <th>Imported Datatype</th>
                    <th>Min Value</th>
                    <th>Max Value</th>
                    <th>Distinct Count</th>
                    <th>Distinct Percent</th>
                    <th>Duplicate Count</th>
                    <th>Duplicate Percent</th>
                    <th>Nulls Count</th>
                    <th>Nulls Percent</th>
                    <th>Not Nulls Count</th>
                    <th>Not Nulls Percent</th>
                    <th>Text Mean Length</th>
                    <th>Text Max Length</th>
                    <th>Text Min Length</th>
                  </tr>
                </thead>
                <tbody>
                  {previewData.map((row, index) => (
                    <tr key={index}>
                      <td>{row.columnName || "N/A"}</td>
                      <td>{row.importedDatatype || "N/A"}</td>
                      <td className="sample-values">
                        {row.minValue !== undefined ? row.minValue : "N/A"}
                      </td>
                      <td className="sample-values">
                        {row.maxValue !== undefined ? row.maxValue : "N/A"}
                      </td>
                      <td>
                        {row.distinctCount
                          ? row.distinctCount
                          : "N/A"}
                      </td>
                      <td>
                        {row.distinctPercent !== undefined
                          ? row.distinctPercent
                          : "N/A"}
                      </td>
                      <td>
                        {row.duplicateCount !== undefined
                          ? row.duplicateCount
                          : "N/A"}
                      </td>
                      <td>
                        {row.duplicatePercent !== undefined
                          ? row.duplicatePercent
                          : "N/A"}
                      </td>
                      <td>
                        {row.nullsCount !== undefined ? row.nullsCount : "N/A"}
                      </td>
                      <td>
                        {row.nullsPercent !== undefined
                          ? row.nullsPercent
                          : "N/A"}
                      </td>
                      <td>
                        {row.notNullsCount !== undefined
                          ? row.notNullsCount
                          : "N/A"}
                      </td>
                      <td>
                        {row.notNullsPercent !== undefined
                          ? row.notNullsPercent
                          : "N/A"}
                      </td>
                      <td>
                        {row.textMeanLength !== undefined
                          ? row.textMeanLength
                          : "N/A"}
                      </td>
                      <td>
                        {row.textMaxLength !== undefined
                          ? row.textMaxLength
                          : "N/A"}
                      </td>
                      <td>
                        {row.textMinLength !== undefined
                          ? row.textMinLength
                          : "N/A"}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <h3>Sample values</h3>
              <div className="table-width">
                <table className="table-preview table-width">
                  <thead>
                    <tr>
                      <th>Column Name</th>
                      {/* Dynamic sample headers */}
                      {Array.from({ length: maxSamplingResults }, (_, i) => (
                        <th key={`sample-header-${i}`}>Sample {i + 1}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {previewData.map((row) => (
                      <tr key={row.columnName}>
                        <td>{row.columnName}</td>
                        {/* Loop through all possible sample result columns */}
                        {Array.from({ length: maxSamplingResults }, (_, i) => (
                          <td key={`sample-${row.columnName}-${i}`} className="sample-values">
                            {row.samplingResults[i] ? row.samplingResults[i].result : "N/A"}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}

          {activeTab === "table-quality-status" && (
            <div className="table-quality-status">
              <div className="filters-section">
                <div className="group-checks">
                  <label>Group checks by:</label>
                  <input
                    type="radio"
                    id="category"
                    name="group"
                    value="category"
                  />
                  <label htmlFor="category">Category</label>
                  <input
                    type="radio"
                    id="quality-dimension"
                    name="group"
                    value="quality-dimension"
                    defaultChecked
                  />
                  <label htmlFor="quality-dimension">Quality Dimension</label>
                </div>
                <div className="date-selection-wrapper">
                  <div className="date-selection">
                    <input
                      type="radio"
                      id="current-month"
                      name="time"
                      value="current-month"
                    />
                    <label htmlFor="current-month">Current month</label>
                    <input
                      type="radio"
                      id="last-3-months"
                      name="time"
                      value="last-3-months"
                    />
                    <label htmlFor="last-3-months">Last 3 months</label>
                    <input
                      type="radio"
                      id="since"
                      name="time"
                      value="since"
                      defaultChecked
                    />
                    <label htmlFor="since">Since</label>
                    <input type="date" id="date-start" />
                  </div>
                  <div className="severity-status">
                    <input
                      type="radio"
                      id="current-severity"
                      name="severity"
                      value="current-severity"
                    />
                    <label htmlFor="current-severity">
                      Current severity status
                    </label>
                    <input
                      type="radio"
                      id="highest-severity"
                      name="severity"
                      value="highest-severity"
                      defaultChecked
                    />
                    <label htmlFor="highest-severity">
                      Highest severity status
                    </label>
                  </div>
                </div>
              </div>
              <div className="status-summary">
                <div className="current-status">
                  <p style={{ marginBottom: "10px" }}>
                    <strong>Current table status</strong>
                  </p>
                  <p>
                    Status:{" "}
                    <strong>{formatValue(statusData.current_severity)}</strong>
                  </p>
                  <p>
                    Last check executed at:{" "}
                    <strong>
                      {formatValue(
                        new Date(
                          statusData.last_check_executed_at
                        ).toLocaleString()
                      )}
                    </strong>
                  </p>
                  <p>
                    Data quality KPI score:{" "}
                    <strong>{statusData?.data_quality_kpi ? formatValue(statusData.data_quality_kpi).toFixed(2) : '0.00'}</strong>
                  </p>
                </div>
                <div className="total-checks">
                  <p style={{ marginBottom: "10px" }}>
                    <strong>Total checks executed</strong>
                  </p>
                  <p>
                    Total checks executed:{" "}
                    <strong>{formatValue(statusData.executed_checks)}</strong>
                  </p>
                  <p>
                    Correct results:{" "}
                    <strong>{formatValue(statusData.valid_results)}</strong>
                  </p>
                  <p>
                    Warnings:{" "}
                    <strong>{formatValue(statusData.warnings)}</strong>
                  </p>
                  <p>
                    Errors: <strong>{formatValue(statusData.errors)}</strong>
                  </p>
                  <p>
                    Fatal results:{" "}
                    <strong>{formatValue(statusData.fatals)}</strong>
                  </p>
                  <p>
                    Execution errors:{" "}
                    <strong>{formatValue(statusData.execution_errors)}</strong>
                  </p>
                </div>
              </div>
              <div className="table-checks-wrapper">
                <div className="table-checks-first-row">
                  <p>Validity</p>
                </div>
                <div className="table-checks-second-row">
                  <p>Table level checks</p>
                </div>
                {columnsData.map((column) => (
                  <div className="table-checks-third-row" key={column.name}>
                    <div className="table-checks-left-coloumn">
                      <p>{formatValue(column.name)}</p>
                    </div>
                    <div className="table-checks-right-coloumn">
                      <div
                        className="drop-details"
                        onClick={() => toggleChecksVisibility(column.name)}
                      >
                        <img
                          src={drop}
                          alt="drop icon"
                          className={
                            checksVisibility[column.name] ? "rotated" : ""
                          }
                        />
                        {checksVisibility[column.name] && (
                          <div className="checks-wrapper">
                            {column.checks &&
                              Object.keys(column.checks).map((checkKey) => {
                                const check = column.checks[checkKey];
                                return (
                                  <div className="checks" key={checkKey}>
                                    <div className="check-key-value">
                                      <h3>Data quality checks:</h3>
                                      <p>{checkKey}</p>
                                    </div>
                                    <div className="check-key-value">
                                      <h3>Last executed at:</h3>
                                      <p>
                                        {formatValue(
                                          new Date(
                                            check.last_executed_at
                                          ).toLocaleString()
                                        )}
                                      </p>
                                    </div>
                                    <div className="check-key-value">
                                      <h3>Current severity level:</h3>
                                      <p>
                                        {formatValue(check.current_severity)}
                                      </p>
                                    </div>
                                    <div className="check-key-value">
                                      <h3>
                                        Highest historical severity level:
                                      </h3>
                                      <p>
                                        {formatValue(
                                          check.highest_historical_severity
                                        )}
                                      </p>
                                    </div>
                                    <div className="check-key-value">
                                      <h3>Category:</h3>
                                      <p>{formatValue(check.category)}</p>
                                    </div>
                                    <div className="check-key-value">
                                      <h3>Quality dimensions:</h3>
                                      <p>
                                        {formatValue(check.quality_dimension)}
                                      </p>
                                    </div>
                                  </div>
                                );
                              })}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}

          {activeTab === "profiling-checks-editor" && (
            <div className="profiling-checks-editor">
              <div className="top-checks-editor">
                <div className="checks-status">
                  <p>Scheduling status: </p>
                  <p>
                    {runChecksJobTemplate?.enabled ? "Enabled" : "Disabled"}
                  </p>
                </div>
                <div className="checks-status">
                  <p>Scheduling configured at: </p>
                  <p>{effectiveSchedule?.schedule_level}</p>
                </div>
                <div className="checks-status">
                  <p>Effective cron expression: </p>
                  <p>{effectiveSchedule?.cron_expression}</p>
                </div>
                <div className="checks-status">
                  <p>Next execution at: </p>
                  <p>
                    {effectiveSchedule
                      ? formatDate(effectiveSchedule.time_of_execution)
                      : ""}
                  </p>
                </div>
                <div className="checks-status">
                  <p>Schedule configuration: </p>
                  <p>{effectiveSchedule?.schedule_group}</p>
                </div>
              </div>
              <div className="check-editor-wrapper">
                <div className="header-all-checks">
                  <p>Data quality check</p>
                  <p>Issue severity level</p>
                  <p>Rule thresholds</p>
                  <div className="all-check-action2">
                    <img src={delete2} alt="delete" />
                    {loadingVideo ? (
                      <CircularProgress size={20} />
                    ) : (
                      <img
                        src={video}
                        alt="video"
                        onClick={handleRunChecks}
                        style={{ cursor: "pointer" }}
                      />
                    )}
                  </div>
                </div>
                <div>
                  {checksData.map((category, catIndex) => (
                    <CheckAccordion
                      key={catIndex}
                      category={category}
                      catIndex={catIndex}
                      expanded={expanded}
                      handleChange={handleChange}
                      checkedStates={checkedStates}
                      handleToggle={handleToggle}
                      selectedValues={selectedValues}
                      handleAutocompleteChange={handleAutocompleteChange}
                      editableValues={editableValues}
                      handleEditableValueChange={handleEditableValueChange}
                      options={options}
                      delete2={delete2}
                      video={video}
                      enable={enable}
                      setting1={setting1}
                      clock={clock}
                      runcheck={runcheck}
                      result={result}
                      question={question}
                      handleIconClick={handleIconClick}
                      loadingCategory={loadingCategory} // Pass loadingCategory state
                      setLoadingCategory={setLoadingCategory} // Pass the function
                    />
                  ))}
                </div>
              </div>
            </div>
          )}

          {activeTab === "profiling-comparision" && (
            <div className="TableComparionsContainer">
              {" "}
              {tableComparisons ? (
                <div className="TableComparisons">
                  {" "}
                  <div className="table-comparision-information">
                    <div className="TableComparisonsHeading">
                      {" "}
                      <StyledTextField
                        sx={{ width: "32%" }}
                        margin="dense"
                        label="Table comparison configuration name"
                        value={tableComparisonName}
                        onChange={(e) => setTableComparisonName(e.target.value)}
                        error={!!errorMessages.tableComparisonName} // Show error state
                        helperText={errorMessages.tableComparisonName} // Display the error message
                      />{" "}
                      <div className="TableComparisonsButtons">
                        {" "}
                        <button
                          onClick={() => {
                            setTableComparisons(false);
                          }}
                        >
                          {" "}
                          Back{" "}
                        </button>{" "}
                        <LoadingButton
                          size="20px"
                          color="secondary"
                          onClick={handleCompareSave}
                          loading={loading}
                          loadingPosition="start"
                          startIcon={<SaveIcon />}
                          variant="contained"
                          sx={{
                            height: "34px",
                            padding: "8px 12px",
                            textTransform: "none",
                            backgroundColor: "#f9f9f9",
                            color: "#000000",
                            borderRadius: "10px",
                            border: "1px solid #fb7857",
                            cursor: "pointer",
                            marginRight: "20px",
                            typography: {
                              fontSize: "14px",
                            },
                            "& .MuiButton-startIcon": {
                              fontSize: "10px",
                            },
                            "&:hover": {
                              backgroundColor: "#fb7857",
                            },
                          }}
                        >
                          Save
                        </LoadingButton>{" "}
                      </div>{" "}
                    </div>{" "}
                    <div className="ReferenceTable">
                      {" "}
                      <h3 className="Filters">
                        {" "}
                        Reference table (the source of truth){" "}
                      </h3>{" "}
                      <div>
                        {" "}
                        <StyledFormControl
                          sx={{ width: "33.3%" }}
                          error={!!errorMessages.connectionLevel}
                        >
                          <InputLabel id="connection-level-label">
                            Connection
                          </InputLabel>
                          <StyledSelect
                            labelId="connection-level-label"
                            id="connection-level"
                            value={connectionLevel}
                            label="Connection"
                            onChange={(e) => setConnectionLevel(e.target.value)}
                          >
                            {connections.map((connection, index) => (
                              <MenuItem key={index} value={connection}>
                                {connection}
                              </MenuItem>
                            ))}
                          </StyledSelect>
                          {errorMessages.connectionLevel && (
                            <FormHelperText>
                              {errorMessages.connectionLevel}
                            </FormHelperText>
                          )}
                        </StyledFormControl>{" "}
                        <StyledFormControl
                          sx={{ width: "33.3%" }}
                          error={!!errorMessages.schemaLevel}
                        >
                          <InputLabel id="schema-level-label">
                            Schema
                          </InputLabel>
                          <StyledSelect
                            labelId="schema-level-label"
                            id="schema-level"
                            value={schemaLevel}
                            label="schema-level"
                            onChange={(e) => setSchemaLevel(e.target.value)}
                          >
                            {schemas.length > 0 ? (
                              schemas.map((schema, index) => (
                                <MenuItem key={index} value={schema}>
                                  {schema}
                                </MenuItem>
                              ))
                            ) : (
                              <MenuItem disabled>No schemas available</MenuItem>
                            )}
                          </StyledSelect>
                          {errorMessages.schemaLevel && (
                            <FormHelperText>
                              {errorMessages.schemaLevel}
                            </FormHelperText>
                          )}
                        </StyledFormControl>{" "}
                        <StyledFormControl
                          sx={{ width: "33.3%" }}
                          error={!!errorMessages.tableLevel}
                        >
                          <InputLabel id="table-level-label">Table</InputLabel>
                          <StyledSelect
                            labelId="table-level-label"
                            id="table-level"
                            value={tableLevel}
                            label="table-level"
                            onChange={(e) => setTableLevel(e.target.value)}
                          >
                            {tables.length > 0 ? (
                              tables.map((table, index) => (
                                <MenuItem key={index} value={table}>
                                  {table}
                                </MenuItem>
                              ))
                            ) : (
                              <MenuItem disabled>No tables available</MenuItem>
                            )}
                          </StyledSelect>
                          {errorMessages.tableLevel && (
                            <FormHelperText>
                              {errorMessages.tableLevel}
                            </FormHelperText>
                          )}
                        </StyledFormControl>{" "}
                      </div>{" "}
                    </div>{" "}
                    <Box sx={{ overflow: "hidden" }}>
                      {" "}
                      <TableContainer component={Paper}>
                        {" "}
                        <Table
                          sx={{ minWidth: 650 }}
                          aria-label="data grouping table"
                        >
                          {" "}
                          <TableHead>
                            {" "}
                            <TableRow>
                              {" "}
                              <TableCell></TableCell>{" "}
                              <TableCell>
                                {" "}
                                <h5 className="dataTableHeading">
                                  {" "}
                                  Data grouping on compared table{" "}
                                </h5>{" "}
                              </TableCell>{" "}
                              <TableCell>
                                {" "}
                                <h5 className="dataTableHeading">
                                  {" "}
                                  Data grouping on reference table{" "}
                                </h5>{" "}
                              </TableCell>{" "}
                            </TableRow>{" "}
                          </TableHead>{" "}
                          <TableBody>
                            {" "}
                            {columns.map((column, rowIndex) => (
                              <TableRow key={column}>
                                {/* Column Label */}
                                <TableCell component="th" scope="row">
                                  {column}
                                </TableCell>

                                {/* Dropdown for selecting a column from the compared table */}
                                <TableCell>
                                  <StyledFormControl fullWidth>
                                    <InputLabel
                                      id={`select-column-label-${rowIndex}`}
                                    >
                                      Select column on compared table
                                    </InputLabel>
                                    <StyledSelect
                                      value={selectedColumns[rowIndex] || ""} // Get the selected column for the current row
                                      onChange={(event) =>
                                        handleColumnChange(
                                          rowIndex,
                                          event.target.value
                                        )
                                      } // Update the selected column for the specific row
                                      labelId={`select-column-label-${rowIndex}`}
                                      label="Select column on compared table"
                                    >
                                      {columns1.map((col) => (
                                        <MenuItem key={col} value={col}>
                                          {col}
                                        </MenuItem>
                                      ))}
                                    </StyledSelect>
                                  </StyledFormControl>
                                </TableCell>

                                {/* Dropdown for selecting a column from the reference table */}
                                <TableCell>
                                  <StyledFormControl fullWidth>
                                    <InputLabel
                                      id={`select-reference-label-${rowIndex}`}
                                    >
                                      Select column on reference table
                                    </InputLabel>
                                    <StyledSelect
                                      value={
                                        selectedReferenceColumns[rowIndex] || ""
                                      } // Get the selected reference column for the current row
                                      onChange={(event) =>
                                        handleReferenceColumnChange(
                                          rowIndex,
                                          event.target.value
                                        )
                                      } // Update the selected reference column for the specific row
                                      labelId={`select-reference-label-${rowIndex}`}
                                      label="Select column on reference table"
                                    >
                                      {referenceColumns.map((col) => (
                                        <MenuItem key={col} value={col}>
                                          {col}
                                        </MenuItem>
                                      ))}
                                    </StyledSelect>
                                  </StyledFormControl>
                                </TableCell>
                              </TableRow>
                            ))}{" "}
                            <TableRow>
                              {" "}
                              <TableCell component="th" scope="row">
                                {" "}
                                WHERE filters{" "}
                              </TableCell>{" "}
                              <TableCell>
                                {" "}
                                <StyledTextField
                                  fullWidth
                                  multiline
                                  rows={4}
                                  variant="outlined"
                                  placeholder="WHERE filters for compared table"
                                />{" "}
                              </TableCell>{" "}
                              <TableCell>
                                {" "}
                                <StyledTextField
                                  fullWidth
                                  multiline
                                  rows={4}
                                  variant="outlined"
                                  placeholder="WHERE filters for reference table"
                                />{" "}
                              </TableCell>{" "}
                            </TableRow>{" "}
                          </TableBody>{" "}
                        </Table>{" "}
                      </TableContainer>{" "}
                    </Box>{" "}
                  </div>
                </div>
              ) : (
                <div>
                  {!showResults ? (
                    <div className="test">
                      <h3>Table comparison and its results</h3>
                      <button
                        onClick={() => setTableComparisons(true)}
                        style={{ fontSize: "16px" }}
                      >
                        New table comparison configuration
                      </button>
                      <table className="statistics-table">
                        <thead>
                          <tr>
                            <th>Table comparison configuration name</th>
                            <th>Reference connection</th>
                            <th>Reference schema</th>
                            <th>Reference table name</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {compareData.length > 0 ? (
                            compareData.map((row, index) => (
                              <tr
                                key={index}
                                onClick={() => handleComparisonClick(row)}
                              >
                                <td
                                  style={{
                                    textDecoration: "underline",
                                    cursor: "pointer",
                                  }}
                                >
                                  {row.table_comparison_configuration_name ||
                                    "N/A"}
                                </td>
                                <td>{row.reference_connection || "N/A"}</td>
                                <td>
                                  {row.reference_table
                                    ? row.reference_table.schema_name
                                    : "N/A"}
                                </td>
                                <td>
                                  {row.reference_table
                                    ? row.reference_table.table_name
                                    : "N/A"}
                                </td>
                                <td>
                                  <div className="profiling-action-wrapper">
                                    <div className="profiling-action-buttom">
                                      <img
                                        src={stats}
                                        alt="stats"
                                        style={{ cursor: "pointer" }}
                                      />
                                    </div>
                                    <div className="profiling-action-buttom">
                                      <img
                                        src={delete1}
                                        alt="delete"
                                        style={{ cursor: "pointer" }}
                                      />
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="5">No data available</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    <div className="result2">
                      <div className="top-result-wrapper">
                        <div className="top-table-result">
                          <div className="left-top-result">
                            <p>Table comparison configuration name:</p>
                            <p>{selectedComparison || "Select a comparison"}</p>
                          </div>
                          <div className="right-top-results">
                            <button onClick={handleBack}>Back</button>
                            <LoadingButton
                              size="20px"
                              color="secondary"
                              onClick={handleSubmit1}
                              loading={loading3}
                              loadingPosition="start"
                              startIcon={<SaveIcon />}
                              variant="contained"
                              sx={{
                                height: "34px",
                                padding: "8px 12px",
                                textTransform: "none",
                                backgroundColor: "#f9f9f9",
                                color: "#000000",
                                borderRadius: "10px",
                                border: "1px solid #fb7857",
                                cursor: "pointer",
                                marginRight: "20px",
                                typography: {
                                  fontSize: "14px",
                                },
                                "& .MuiButton-startIcon": {
                                  fontSize: "10px",
                                },
                                "&:hover": {
                                  backgroundColor: "#fb7857",
                                },
                              }}
                            >
                              Compare table
                            </LoadingButton>
                          </div>
                        </div>
                        <div style={{ display: "flex", gap: "5px" }}>
                          <p>Comparing this table to the reference table:</p>{" "}
                          <p>{`${referenceTableDetails.referenceConnection ||
                            "Reference Connection"
                            }.${referenceTableDetails.referenceSchema ||
                            "Reference Schema"
                            }.${referenceTableDetails.referenceTable ||
                            "Reference Table"
                            }`}</p>
                        </div>
                      </div>
                      <div className="bottom-result-wrapper">
                        <div className="table-level-compare">
                          <table className="statistics-table">
                            <thead>
                              <tr>
                                <th>Table-level comparison</th>
                                <th></th>
                                <th>Row count </th>
                                <th>Column count</th>
                              </tr>
                            </thead>
                            <tbody>
                              {comparisonResults &&
                                comparisonResults.length > 0 ? (
                                <React.Fragment>
                                  <tr>
                                    <td>
                                      <button onClick={() => toggleTableRow(0)}>
                                        {tableExpandedRows.includes(0)
                                          ? "▼"
                                          : "▶"}
                                      </button>
                                      {comparisonResults[0].compared_table_name}
                                    </td>
                                    <td></td>
                                    <td className={getTableRowCountResultClassName()}>
                                      <input
                                        type="checkbox"
                                        checked={checkedRows[0]}
                                        onChange={() => handleCheckboxChange1(0)}
                                      />
                                    </td>
                                    <td className={getTableColumnCountResultClassName()}>

                                      <input
                                        type="checkbox"
                                        checked={checkedRows[1]}
                                        onChange={() => handleCheckboxChange1(1)}
                                      />
                                    </td>
                                  </tr>
                                  {tableExpandedRows.includes(0) && (
                                    <tr className="expanded-row">
                                      <td colSpan="2"></td>{" "}
                                      <td style={{ padding: "0" }}>
                                        {checkedRows[0] && (
                                          <>
                                            <div
                                              className="result-yellow-background"
                                              style={{
                                                backgroundColor: "rgb(253 253 237)",
                                              }}
                                            >
                                              <input
                                                type="number"
                                                className="result-values"
                                                value={
                                                  editedValues[0]
                                                    ?.compare_row_count_warning_difference_percent ??
                                                  comparisonResults[0]
                                                    .compare_row_count_warning_difference_percent ??
                                                  ""
                                                }
                                                onChange={(e) =>
                                                  handleInputChange1(
                                                    e,
                                                    0,
                                                    "compare_row_count_warning_difference_percent"
                                                  )
                                                }
                                              />
                                              <p>%</p>
                                            </div>
                                            <div
                                              className="result-yellow-background"
                                              style={{
                                                backgroundColor: "rgb(255 239 214)",
                                              }}
                                            >
                                              <input
                                                type="number"
                                                className="result-values"
                                                value={
                                                  editedValues[0]
                                                    ?.default_error_difference_percent ??
                                                  comparisonResults[0]
                                                    .default_error_difference_percent ??
                                                  ""
                                                }
                                                onChange={(e) =>
                                                  handleInputChange1(
                                                    e,
                                                    0,
                                                    "default_error_difference_percent"
                                                  )
                                                }
                                              />
                                              <p>%</p>
                                            </div>
                                            <div
                                              className="result-yellow-background"
                                              style={{
                                                backgroundColor: "rgb(254 237 236)",
                                              }}
                                            >
                                              <input
                                                type="number"
                                                className="result-values"
                                                value={
                                                  editedValues[0]
                                                    ?.compare_row_count_fatal_difference_percent ??
                                                  comparisonResults[0]
                                                    .compare_row_count_fatal_difference_percent ??
                                                  ""
                                                }
                                                onChange={(e) =>
                                                  handleInputChange1(
                                                    e,
                                                    0,
                                                    "compare_row_count_fatal_difference_percent"
                                                  )
                                                }
                                              />
                                              <p>%</p>
                                            </div>
                                            {comparisonResults1 &&
                                              comparisonResults1.table_comparison_results ? (
                                              <div className="compare-results-wrapper">
                                                <h3>Results</h3>

                                                {/* Results for Row Count */}
                                                <div className="correct-result">
                                                  <p>Correct results:</p>
                                                  <p>
                                                    {
                                                      comparisonResults1
                                                        .table_comparison_results
                                                        .profile_row_count_match
                                                        ? comparisonResults1
                                                          .table_comparison_results
                                                          .profile_row_count_match
                                                          .valid_results || 0
                                                        : 0 // Fallback to 0 if profile_row_count_match is undefined
                                                    }
                                                  </p>
                                                </div>

                                                <div className="correct-result">
                                                  <p>Warning:</p>
                                                  <p>
                                                    {
                                                      comparisonResults1
                                                        .table_comparison_results
                                                        .profile_row_count_match
                                                        ? comparisonResults1
                                                          .table_comparison_results
                                                          .profile_row_count_match
                                                          .warnings || 0
                                                        : 0 // Fallback to 0 if profile_row_count_match is undefined
                                                    }
                                                  </p>
                                                </div>
                                                <div className="correct-result">
                                                  <p>Errors:</p>
                                                  <p>
                                                    {
                                                      comparisonResults1
                                                        .table_comparison_results
                                                        .profile_row_count_match
                                                        ? comparisonResults1
                                                          .table_comparison_results
                                                          .profile_row_count_match
                                                          .errors || 0
                                                        : 0 // Fallback to 0 if profile_column_count_match is undefined
                                                    }
                                                  </p>
                                                </div>

                                                <div className="correct-result">
                                                  <p>Fatal errors:</p>
                                                  <p>
                                                    {
                                                      comparisonResults1
                                                        .table_comparison_results
                                                        .profile_row_count_match
                                                        ? comparisonResults1
                                                          .table_comparison_results
                                                          .profile_row_count_match
                                                          .fatals || 0
                                                        : 0 // Fallback to 0 if profile_row_count_match is undefined
                                                    }
                                                  </p>
                                                </div>
                                                <div
                                                  className="show-mix-matched"
                                                  onMouseEnter={() =>
                                                    setShowMismatched(true)
                                                  }
                                                  onMouseLeave={() =>
                                                    setShowMismatched(false)
                                                  }
                                                >
                                                  <h3>Show mismatched</h3>
                                                </div>

                                                {showMismatched && (
                                                  <div className="mismatched-columns">
                                                    <div>
                                                      {mismatchedColumns.length >
                                                        0 ? (
                                                        mismatchedColumns.map(
                                                          (email, index) => (
                                                            <p key={index}>
                                                              {email}
                                                            </p>
                                                          )
                                                        )
                                                      ) : (
                                                        <p>
                                                          No mismatched data found.
                                                        </p>
                                                      )}
                                                    </div>
                                                  </div>
                                                )}
                                              </div>
                                            ) : (
                                              <p>No comparison results available</p>
                                            )}
                                          </>
                                        )}
                                      </td>
                                      <td style={{ padding: "0" }}>
                                        {checkedRows[1] && (
                                          <>
                                            <div
                                              className="result-yellow-background"
                                              style={{
                                                backgroundColor: "rgb(253 253 237)",
                                              }}
                                            >
                                              <input
                                                type="number"
                                                className="result-values"
                                                value={
                                                  editedValues[0]
                                                    ?.compare_column_count_warning_difference_percent ??
                                                  comparisonResults[0]
                                                    .compare_column_count_warning_difference_percent ??
                                                  ""
                                                }
                                                onChange={(e) =>
                                                  handleInputChange1(
                                                    e,
                                                    0,
                                                    "compare_column_count_warning_difference_percent"
                                                  )
                                                }
                                              />
                                              <p>%</p>
                                            </div>
                                            <div
                                              className="result-yellow-background"
                                              style={{
                                                backgroundColor: "rgb(255 239 214)",
                                              }}
                                            >
                                              <input
                                                type="number"
                                                className="result-values"
                                                value={
                                                  editedValues[0]
                                                    ?.default_error_difference_percent ??
                                                  comparisonResults[0]
                                                    .default_error_difference_percent ??
                                                  ""
                                                }
                                                onChange={(e) =>
                                                  handleInputChange1(
                                                    e,
                                                    0,
                                                    "default_error_difference_percent"
                                                  )
                                                }
                                              />
                                              <p>%</p>
                                            </div>
                                            <div
                                              className="result-yellow-background"
                                              style={{
                                                backgroundColor: "rgb(254 237 236)",
                                              }}
                                            >
                                              <input
                                                type="number"
                                                className="result-values"
                                                value={
                                                  editedValues[0]
                                                    ?.compare_column_count_fatal_difference_percent ??
                                                  comparisonResults[0]
                                                    .compare_column_count_fatal_difference_percent ??
                                                  ""
                                                }
                                                onChange={(e) =>
                                                  handleInputChange1(
                                                    e,
                                                    0,
                                                    "compare_column_count_fatal_difference_percent"
                                                  )
                                                }
                                              />
                                              <p>%</p>
                                            </div>
                                            {comparisonResults1 &&
                                              comparisonResults1.table_comparison_results ? (
                                              <div className="compare-results-wrapper">
                                                <h3>Results</h3>

                                                <div className="correct-result">
                                                  <p>Correct results:</p>
                                                  <p>
                                                    {
                                                      comparisonResults1
                                                        .table_comparison_results
                                                        .profile_column_count_match
                                                        ? comparisonResults1
                                                          .table_comparison_results
                                                          .profile_column_count_match
                                                          .valid_results || 0
                                                        : 0 // Fallback to 0 if profile_column_count_match is undefined
                                                    }
                                                  </p>
                                                </div>
                                                <div className="correct-result">
                                                  <p>Warning:</p>
                                                  <p>
                                                    {
                                                      comparisonResults1
                                                        .table_comparison_results
                                                        .profile_column_count_match
                                                        ? comparisonResults1
                                                          .table_comparison_results
                                                          .profile_column_count_match
                                                          .warnings || 0
                                                        : 0 // Fallback to 0 if profile_column_count_match is undefined
                                                    }
                                                  </p>
                                                </div>
                                                <div className="correct-result">
                                                  <p>Error:</p>
                                                  <p>
                                                    {
                                                      comparisonResults1
                                                        .table_comparison_results
                                                        .profile_column_count_match
                                                        ? comparisonResults1
                                                          .table_comparison_results
                                                          .profile_column_count_match
                                                          .errors || 0
                                                        : 0 // Fallback to 0 if profile_column_count_match is undefined
                                                    }
                                                  </p>
                                                </div>
                                                <div className="correct-result">
                                                  <p>Fatal errors:</p>
                                                  <p>
                                                    {
                                                      comparisonResults1
                                                        .table_comparison_results
                                                        .profile_column_count_match
                                                        ? comparisonResults1
                                                          .table_comparison_results
                                                          .profile_column_count_match
                                                          .fatals || 0
                                                        : 0 // Fallback to 0 if profile_column_count_match is undefined
                                                    }
                                                  </p>
                                                </div>
                                              </div>
                                            ) : (
                                              <p>No comparison results available</p>
                                            )}
                                          </>
                                        )}
                                      </td>
                                    </tr>
                                  )}
                                </React.Fragment>
                              ) : (
                                <tr>
                                  <td colSpan="8">No data available</td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                        <div className="compared-column">
                          <table className="statistics-table">
                            <thead>
                              <tr>
                                <th>Compared column</th>
                                <th></th>
                                <th>Min</th>
                                <th>Max</th>
                                <th>Sum</th>
                                <th>Mean</th>
                                <th>Nulls count</th>
                                <th>Not nulls count</th>
                              </tr>
                            </thead>
                            <tbody>
                              {comparisonResults &&
                                comparisonResults.length > 0 ? (
                                comparisonResults.map((row, index) => (
                                  <React.Fragment key={index}>
                                    <tr>
                                      <td>
                                        <button
                                          onClick={() => toggleRow(index)}
                                        >
                                          {expandedRows[index] ? "▼" : "▶"}
                                        </button>
                                        {row.compared_column_name}
                                      </td>
                                      <td>
                                        <select>
                                          <option>
                                            {row.reference_column_name}
                                          </option>
                                          {/* Additional options can be added */}
                                        </select>
                                      </td>
                                      <td className={getTableRowResultClassName(row.reference_column_name, 'min')}>
                                        <input
                                          type="checkbox"
                                          checked={checkedMetrics[index]?.min || false}
                                          onChange={() => handleMetricChange(index, "min")}
                                        />
                                      </td>
                                      <td className={getTableRowResultClassName(row.reference_column_name, 'max')}>
                                        <input
                                          type="checkbox"
                                          checked={checkedMetrics[index]?.max || false}
                                          onChange={() => handleMetricChange(index, "max")}
                                        />
                                      </td>
                                      <td className={getTableRowResultClassName(row.reference_column_name, 'sum')}>
                                        <input
                                          type="checkbox"
                                          checked={checkedMetrics[index]?.sum || false}
                                          onChange={() => handleMetricChange(index, "sum")}
                                        />
                                      </td>
                                      <td className={getTableRowResultClassName(row.reference_column_name, 'mean')}>
                                        <input
                                          type="checkbox"
                                          checked={checkedMetrics[index]?.mean || false}
                                          onChange={() => handleMetricChange(index, "mean")}
                                        />
                                      </td>
                                      <td className={getTableRowResultClassName(row.reference_column_name, 'null_count')}>
                                        <input
                                          type="checkbox"
                                          checked={checkedMetrics[index]?.nullsCount || false}
                                          onChange={() => handleMetricChange(index, "nullsCount")}
                                        />
                                      </td>
                                      <td className={getTableRowResultClassName(row.reference_column_name, 'not_null_count')}>
                                        <input
                                          type="checkbox"
                                          checked={checkedMetrics[index]?.notNullsCount || false}
                                          onChange={() => handleMetricChange(index, "notNullsCount")}
                                        />
                                      </td>

                                    </tr>
                                    {expandedRows[index] && (
                                      <tr className="expanded-row">
                                        <td colSpan="2"></td>{" "}
                                        {/* Empty cells for the first two columns */}
                                        <td style={{ padding: "0" }}>
                                          {checkedMetrics[index]?.min && (
                                            <>
                                              <div
                                                className="result-yellow-background"
                                                style={{
                                                  backgroundColor:
                                                    "rgb(253 253 237)",
                                                }}
                                              >
                                                <input
                                                  type="number"
                                                  className="result-values"
                                                  value={
                                                    editedValues[index]
                                                      ?.min_warning_difference_percent ??
                                                    row.min_warning_difference_percent
                                                  }
                                                  onChange={(e) =>
                                                    handleInputChange1(
                                                      e,
                                                      index,
                                                      "min_warning_difference_percent"
                                                    )
                                                  }
                                                />
                                                <p>%</p>
                                              </div>
                                              <div
                                                className="result-yellow-background"
                                                style={{
                                                  backgroundColor:
                                                    "rgb(255 239 214)",
                                                }}
                                              >
                                                <div className="min_error_difference">
                                                  <input
                                                    type="number"
                                                    className="result-values"
                                                    value={
                                                      editedValues[index]
                                                        ?.min_error_difference_percent ??
                                                      row.min_error_difference_percent
                                                    }
                                                    onChange={(e) =>
                                                      handleInputChange1(
                                                        e,
                                                        index,
                                                        "min_error_difference_percent"
                                                      )
                                                    }
                                                  />
                                                </div>
                                                <p>%</p>
                                              </div>
                                              <div
                                                className="result-yellow-background"
                                                style={{
                                                  backgroundColor:
                                                    "rgb(254 237 236)",
                                                }}
                                              >
                                                <input
                                                  type="number"
                                                  className="result-values"
                                                  value={
                                                    editedValues[index]
                                                      ?.min_fatal_difference_percent ??
                                                    row.min_fatal_difference_percent
                                                  }
                                                  onChange={(e) =>
                                                    handleInputChange1(
                                                      e,
                                                      index,
                                                      "min_fatal_difference_percent"
                                                    )
                                                  }
                                                />
                                                <p>%</p>
                                              </div>
                                              <div className="compare-results-wrapper">
                                                <h3>Results</h3>
                                                {renderResults(row, 'min')}
                                              </div>
                                            </>
                                          )}
                                        </td>
                                        <td style={{ padding: "0" }}>
                                          {checkedMetrics[index]?.max && (
                                            <>
                                              <div
                                                className="result-yellow-background"
                                                style={{
                                                  backgroundColor:
                                                    "rgb(253 253 237)",
                                                }}
                                              >
                                                <input
                                                  type="number"
                                                  className="result-values"
                                                  value={
                                                    editedValues[index]
                                                      ?.max_warning_difference_percent ??
                                                    row.max_warning_difference_percent
                                                  }
                                                  onChange={(e) =>
                                                    handleInputChange1(
                                                      e,
                                                      index,
                                                      "max_warning_difference_percent"
                                                    )
                                                  }
                                                />
                                                <p>%</p>
                                              </div>
                                              <div
                                                className="result-yellow-background"
                                                style={{
                                                  backgroundColor:
                                                    "rgb(255 239 214)",
                                                }}
                                              >
                                                <input
                                                  type="number"
                                                  className="result-values"
                                                  value={
                                                    editedValues[index]
                                                      ?.max_error_difference_percent ??
                                                    row.max_error_difference_percent
                                                  }
                                                  onChange={(e) =>
                                                    handleInputChange1(
                                                      e,
                                                      index,
                                                      "max_error_difference_percent"
                                                    )
                                                  }
                                                />
                                                <p>%</p>
                                              </div>
                                              <div
                                                className="result-yellow-background"
                                                style={{
                                                  backgroundColor:
                                                    "rgb(254 237 236)",
                                                }}
                                              >
                                                <input
                                                  type="number"
                                                  className="result-values"
                                                  value={
                                                    editedValues[index]
                                                      ?.max_fatal_difference_percent ??
                                                    row.max_fatal_difference_percent
                                                  }
                                                  onChange={(e) =>
                                                    handleInputChange1(
                                                      e,
                                                      index,
                                                      "max_fatal_difference_percent"
                                                    )
                                                  }
                                                />
                                                <p>%</p>
                                              </div>
                                              <div className="compare-results-wrapper">
                                                <h3>Results</h3>
                                                {renderResults(row, 'max')}
                                              </div>
                                            </>
                                          )}
                                        </td>
                                        <td style={{ padding: "0" }}>
                                          {checkedMetrics[index]?.sum && (
                                            <>
                                              <div
                                                className="result-yellow-background"
                                                style={{
                                                  backgroundColor:
                                                    "rgb(253 253 237)",
                                                }}
                                              >
                                                <input
                                                  type="number"
                                                  className="result-values"
                                                  value={
                                                    editedValues[index]
                                                      ?.sum_warning_difference_percent ??
                                                    row.sum_warning_difference_percent
                                                  }
                                                  onChange={(e) =>
                                                    handleInputChange1(
                                                      e,
                                                      index,
                                                      "sum_warning_difference_percent"
                                                    )
                                                  }
                                                />
                                                <p>%</p>
                                              </div>
                                              <div
                                                className="result-yellow-background"
                                                style={{
                                                  backgroundColor:
                                                    "rgb(255 239 214)",
                                                }}
                                              >
                                                <input
                                                  type="number"
                                                  className="result-values"
                                                  value={
                                                    editedValues[index]
                                                      ?.sum_error_difference_percent ??
                                                    row.sum_error_difference_percent
                                                  }
                                                  onChange={(e) =>
                                                    handleInputChange1(
                                                      e,
                                                      index,
                                                      "sum_error_difference_percent"
                                                    )
                                                  }
                                                />
                                                <p>%</p>
                                              </div>
                                              <div
                                                className="result-yellow-background"
                                                style={{
                                                  backgroundColor:
                                                    "rgb(254 237 236)",
                                                }}
                                              >
                                                <input
                                                  type="number"
                                                  className="result-values"
                                                  value={
                                                    editedValues[index]
                                                      ?.sum_fatal_difference_percent ??
                                                    row.sum_fatal_difference_percent
                                                  }
                                                  onChange={(e) =>
                                                    handleInputChange1(
                                                      e,
                                                      index,
                                                      "sum_error_difference_percent"
                                                    )
                                                  }
                                                />
                                                <p>%</p>
                                              </div>
                                              <div className="compare-results-wrapper">
                                                <h3>Results</h3>
                                                {renderResults(row, 'sum')}
                                              </div>
                                            </>
                                          )}
                                        </td>
                                        <td style={{ padding: "0" }}>
                                          {checkedMetrics[index]?.mean && (
                                            <>
                                              <div
                                                className="result-yellow-background"
                                                style={{
                                                  backgroundColor:
                                                    "rgb(253 253 237)",
                                                }}
                                              >
                                                <input
                                                  type="number"
                                                  className="result-values"
                                                  value={
                                                    editedValues[index]
                                                      ?.mean_warning_difference_percent ??
                                                    row.mean_warning_difference_percent
                                                  }
                                                  onChange={(e) =>
                                                    handleInputChange1(
                                                      e,
                                                      index,
                                                      "mean_warning_difference_percent"
                                                    )
                                                  }
                                                />
                                                <p>%</p>
                                              </div>
                                              <div
                                                className="result-yellow-background"
                                                style={{
                                                  backgroundColor:
                                                    "rgb(255 239 214)",
                                                }}
                                              >
                                                <input
                                                  type="number"
                                                  className="result-values"
                                                  value={
                                                    editedValues[index]
                                                      ?.mean_error_difference_percent ??
                                                    row.mean_error_difference_percent
                                                  }
                                                  onChange={(e) =>
                                                    handleInputChange1(
                                                      e,
                                                      index,
                                                      "mean_error_difference_percent"
                                                    )
                                                  }
                                                />
                                                <p>%</p>
                                              </div>
                                              <div
                                                className="result-yellow-background"
                                                style={{
                                                  backgroundColor:
                                                    "rgb(254 237 236)",
                                                }}
                                              >
                                                <input
                                                  type="number"
                                                  className="result-values"
                                                  value={
                                                    editedValues[index]
                                                      ?.mean_fatal_difference_percent ??
                                                    row.mean_fatal_difference_percent
                                                  }
                                                  onChange={(e) =>
                                                    handleInputChange1(
                                                      e,
                                                      index,
                                                      "mean_fatal_difference_percent"
                                                    )
                                                  }
                                                />
                                                <p>%</p>
                                              </div>
                                              <div className="compare-results-wrapper">
                                                <h3>Results</h3>
                                                {renderResults(row, 'mean')}
                                              </div>
                                            </>
                                          )}
                                        </td>
                                        <td style={{ padding: "0" }}>
                                          {checkedMetrics[index]?.nullsCount && (
                                            <>
                                              <div
                                                className="result-yellow-background"
                                                style={{
                                                  backgroundColor:
                                                    "rgb(253 253 237)",
                                                }}
                                              >
                                                <input
                                                  type="number"
                                                  className="result-values"
                                                  value={
                                                    editedValues[index]
                                                      ?.null_count_warning_difference_percent ??
                                                    row.null_count_warning_difference_percent
                                                  }
                                                  onChange={(e) =>
                                                    handleInputChange1(
                                                      e,
                                                      index,
                                                      "null_count_warning_difference_percent"
                                                    )
                                                  }
                                                />
                                                <p>%</p>
                                              </div>
                                              <div
                                                className="result-yellow-background"
                                                style={{
                                                  backgroundColor:
                                                    "rgb(255 239 214)",
                                                }}
                                              >
                                                <input
                                                  type="number"
                                                  className="result-values"
                                                  value={
                                                    editedValues[index]
                                                      ?.null_count_error_difference_percent ??
                                                    row.null_count_error_difference_percent
                                                  }
                                                  onChange={(e) =>
                                                    handleInputChange1(
                                                      e,
                                                      index,
                                                      "null_count_error_difference_percent"
                                                    )
                                                  }
                                                />
                                                <p>%</p>
                                              </div>
                                              <div
                                                className="result-yellow-background"
                                                style={{
                                                  backgroundColor:
                                                    "rgb(254 237 236)",
                                                }}
                                              >
                                                <input
                                                  type="number"
                                                  className="result-values"
                                                  value={
                                                    editedValues[index]
                                                      ?.null_count_fatal_difference_percent ??
                                                    row.null_count_fatal_difference_percent
                                                  }
                                                  onChange={(e) =>
                                                    handleInputChange1(
                                                      e,
                                                      index,
                                                      "null_count_fatal_difference_percent"
                                                    )
                                                  }
                                                />
                                              </div>
                                              <div className="compare-results-wrapper">
                                                <h3>Results</h3>
                                                {renderResults(row, 'null_count')}
                                              </div>
                                            </>
                                          )}
                                        </td>
                                        <td style={{ padding: "0" }}>
                                          {checkedMetrics[index]?.notNullsCount && (
                                            <>
                                              <div
                                                className="result-yellow-background"
                                                style={{
                                                  backgroundColor:
                                                    "rgb(253 253 237)",
                                                }}
                                              >
                                                <input
                                                  type="number"
                                                  className="result-values"
                                                  value={
                                                    editedValues[index]
                                                      ?.not_null_count_warning_difference_percent ??
                                                    row.not_null_count_warning_difference_percent
                                                  }
                                                  onChange={(e) =>
                                                    handleInputChange1(
                                                      e,
                                                      index,
                                                      "not_null_count_warning_difference_percent"
                                                    )
                                                  }
                                                />
                                                <p>%</p>
                                              </div>
                                              <div
                                                className="result-yellow-background"
                                                style={{
                                                  backgroundColor:
                                                    "rgb(255 239 214)",
                                                }}
                                              >
                                                <input
                                                  type="number"
                                                  className="result-values"
                                                  value={
                                                    editedValues[index]
                                                      ?.not_null_count_error_difference_percent ??
                                                    row.not_null_count_error_difference_percent
                                                  }
                                                  onChange={(e) =>
                                                    handleInputChange1(
                                                      e,
                                                      index,
                                                      "not_null_count_error_difference_percent"
                                                    )
                                                  }
                                                />
                                                <p>%</p>
                                              </div>
                                              <div
                                                className="result-yellow-background"
                                                style={{
                                                  backgroundColor:
                                                    "rgb(254 237 236)",
                                                }}
                                              >
                                                <input
                                                  type="number"
                                                  className="result-values"
                                                  value={
                                                    editedValues[index]
                                                      ?.not_null_count_fatal_difference_percent ??
                                                    row.not_null_count_fatal_difference_percent
                                                  }
                                                  onChange={(e) =>
                                                    handleInputChange1(
                                                      e,
                                                      index,
                                                      "not_null_count_fatal_difference_percent"
                                                    )
                                                  }
                                                />
                                                <p>%</p>
                                              </div>
                                              <div className="compare-results-wrapper">
                                                <h3>Results</h3>
                                                {renderResults(row, 'not_null_count')}
                                              </div>
                                            </>
                                          )}
                                        </td>
                                      </tr>
                                    )}
                                  </React.Fragment>
                                ))
                              ) : (
                                <tr>
                                  <td colSpan="8">No data available</td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}{" "}
            </div>
          )}

          {activeTab === "profiling-rule" && (
            <div className="tabContainer">
              <div className="ruleMiningHeading">
                <p>
                  The number of propositions shown depends on the activated
                  checks in the Profiling section.
                </p>
                <p>
                  To increase the number of propositions, you can either
                  activate more profiling checks manually or use the data
                  quality rule miner in the Profiling section.
                </p>
              </div>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <StyledBox
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <h3 className="Filters">Filters</h3>
                    <div
                      style={{
                        display: "flex",
                        gap: "10px",
                        justifyContent: "space-evenly",
                      }}
                    >
                      <StyledTextField
                        sx={{ width: "32%" }}
                        margin="dense"
                        label="Check category"
                      />
                      <StyledTextField
                        sx={{ width: "32%" }}
                        margin="dense"
                        label="Check name"
                      />
                      <StyledTextField
                        sx={{ width: "32%" }}
                        margin="dense"
                        label="Column name"
                      />
                    </div>
                  </StyledBox>
                </Grid>
                <Grid item xs={3}>
                  <StyledBox
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        gap: "6px",
                        alignItems: "center",
                      }}
                    >
                      <h3 className="Filters">Error rate (% rows)</h3>
                      <StyledTooltip
                        title={
                          <React.Fragment>
                            <Typography
                              variant="h6"
                              sx={{ fontSize: "16px" }}
                              color="inherit"
                            >
                              The desired percentage of error rows. When a
                              profiling data quality check identifies incorrect
                              rows and their number is below this desired error
                              rate, DQOps rule mining engine will configure the
                              rule threshold make the check fail.
                            </Typography>
                          </React.Fragment>
                        }
                      >
                        <AdjustIcon
                          sx={{ fontSize: "16px", color: "#a1a1aa" }}
                        />
                      </StyledTooltip>
                    </div>
                    <StyledTextField
                      fullWidth
                      margin="dense"
                      label="Error rate"
                      value={errorRate}
                      onChange={(e) => setErrorRate(e.target.value)}
                    />
                  </StyledBox>
                </Grid>
                <Grid item xs={3}>
                  <StyledBox
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "6px",
                      }}
                    >
                      <h3 className="Filters">Default severity level</h3>
                      <StyledSelect
                        labelId="severity-level-label"
                        id="severity-level"
                        value={severityLevel}
                        onChange={(e) => setSeverityLevel(e.target.value)}
                      >
                        <MenuItem value="warning">Warning</MenuItem>
                        <MenuItem value="error">Error</MenuItem>
                        <MenuItem value="fatal">Fatal</MenuItem>
                      </StyledSelect>
                    </div>
                  </StyledBox>
                </Grid>
              </Grid>

              <div className="AdvancedParametersContainer">
                <div
                  className="AdvancedParameters"
                  onClick={() => {
                    setAdvancedSection((prevState) => !prevState);
                  }}
                >
                  {advancedSection ? (
                    <KeyboardArrowDownIcon />
                  ) : (
                    <KeyboardArrowRightIcon />
                  )}
                  <p>Advanced parameters</p>
                </div>

                {advancedSection ? (
                  <div className="optionsBox">
                    <Grid
                      container
                      spacing={2}
                      sx={{
                        borderBottom: "1px solid #e7e7e7",
                        paddingBottom: "0.625rem",
                      }}
                    >
                      <Grid item xs={2.4}>
                        <div className="checkBox">
                          <Checkbox
                            checked={copyFailedProfilingChecks}
                            onChange={() =>
                              setCopyFailedProfilingChecks(
                                !copyFailedProfilingChecks
                              )
                            }
                          />
                          <p>Copy failed profiling checks</p>
                          <StyledTooltip
                            title={
                              <React.Fragment>
                                <Typography
                                  variant="h6"
                                  sx={{ fontSize: "16px" }}
                                  color="inherit"
                                >
                                  Copy the configuration of profiling checks
                                  that failed during the last execution. The
                                  preferred approach is to review the profiling
                                  checks, disable false-positive checks, and
                                  enable this configuration to copy the reviewed
                                  checks to the monitoring and partitioned
                                  checks for continuous monitoring.
                                </Typography>
                              </React.Fragment>
                            }
                          >
                            <AdjustIcon
                              sx={{ fontSize: "16px", color: "#a1a1aa" }}
                            />
                          </StyledTooltip>
                        </div>
                      </Grid>
                      <Grid item xs={2.4}>
                        <div className="checkBox">
                          <Checkbox
                            checked={copyDisabledProfilingChecks}
                            onChange={() =>
                              setCopyDisabledProfilingChecks(
                                !copyDisabledProfilingChecks
                              )
                            }
                          />
                          <p>Copy disabled profiling checks</p>
                          <StyledTooltip
                            title={
                              <React.Fragment>
                                <Typography
                                  variant="h6"
                                  sx={{ fontSize: "16px" }}
                                  color="inherit"
                                >
                                  Copy the configuration of disabled profiling
                                  checks. This option is effective for
                                  monitoring or partitioned checks only. By
                                  default it is disabled, leaving failed or
                                  incorrectly configured profiling checks only
                                  in the profiling section to avoid decreasing
                                  the data quality KPI.
                                </Typography>
                              </React.Fragment>
                            }
                          >
                            <AdjustIcon
                              sx={{ fontSize: "16px", color: "#a1a1aa" }}
                            />
                          </StyledTooltip>
                        </div>
                      </Grid>
                      <Grid item xs={2.4}>
                        <div className="checkBox">
                          <Checkbox
                            checked={copyProfilingChecks}
                            onChange={() =>
                              setCopyProfilingChecks(!copyProfilingChecks)
                            }
                          />
                          <p>Copy profiling checks</p>
                          <StyledTooltip
                            title={
                              <React.Fragment>
                                <Typography
                                  variant="h6"
                                  sx={{ fontSize: "16px" }}
                                  color="inherit"
                                >
                                  Copy the configuration of enabled profiling
                                  checks to the monitoring or partitioned
                                  checks. This option is effective for
                                  monitoring or partitioned checks only. By
                                  default it is enabled, allowing to migrate
                                  configured profiling checks to the monitoring
                                  section to enable Data Observability of these
                                  checks.
                                </Typography>
                              </React.Fragment>
                            }
                          >
                            <AdjustIcon
                              sx={{ fontSize: "16px", color: "#a1a1aa" }}
                            />
                          </StyledTooltip>
                        </div>
                      </Grid>
                      <Grid item xs={2.4}>
                        <div className="checkBox">
                          <Checkbox
                            checked={reconfigurePolicyEnabledChecks}
                            onChange={() =>
                              setReconfigurePolicyEnabledChecks(
                                !reconfigurePolicyEnabledChecks
                              )
                            }
                          />
                          <p>Tune quality policy checks</p>
                          <StyledTooltip
                            title={
                              <React.Fragment>
                                <Typography
                                  variant="h6"
                                  sx={{ fontSize: "16px" }}
                                  color="inherit"
                                >
                                  Reconfigure the rule thresholds of data
                                  quality checks that were activated using data
                                  observability rule patterns (data quality
                                  policies).
                                </Typography>
                              </React.Fragment>
                            }
                          >
                            <AdjustIcon
                              sx={{ fontSize: "16px", color: "#a1a1aa" }}
                            />
                          </StyledTooltip>
                        </div>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      spacing={2}
                      sx={{
                        padding: "0.625rem 0",
                      }}
                    >
                      <Grid item xs={2.4}>
                        <div className="checkBox">
                          <Checkbox
                            checked={minimumRowCount}
                            onChange={() =>
                              setMinimumRowCount(!minimumRowCount)
                            }
                            inputProps={{ "aria-label": "Minimum row count" }}
                          />
                          <p>Minimum row count</p>
                          <StyledTooltip
                            title={
                              <React.Fragment>
                                <Typography
                                  variant="h6"
                                  sx={{ fontSize: "16px" }}
                                  color="inherit"
                                >
                                  Configure the minimum row count based on the
                                  basic statistics captured from the table.
                                </Typography>
                              </React.Fragment>
                            }
                          >
                            <AdjustIcon
                              sx={{ fontSize: "16px", color: "#a1a1aa" }}
                            />
                          </StyledTooltip>
                        </div>
                      </Grid>
                      <Grid item xs={2.4}>
                        <div className="checkBox">
                          <Checkbox
                            checked={expectedColumnsCount}
                            onChange={() =>
                              setExpectedColumnsCount(!expectedColumnsCount)
                            }
                            inputProps={{
                              "aria-label": "Expected columns count",
                            }}
                          />
                          <p>Excepted columns count</p>
                          <StyledTooltip
                            title={
                              <React.Fragment>
                                <Typography
                                  variant="h6"
                                  sx={{ fontSize: "16px" }}
                                  color="inherit"
                                >
                                  Configure a table schema check that ensures
                                  that the count of column stays the same as the
                                  count of columns detected during data
                                  profiling.
                                </Typography>
                              </React.Fragment>
                            }
                          >
                            <AdjustIcon
                              sx={{ fontSize: "16px", color: "#a1a1aa" }}
                            />
                          </StyledTooltip>
                        </div>
                      </Grid>
                      <Grid item xs={2.4}>
                        <div className="checkBox">
                          <Checkbox
                            checked={columnExists}
                            onChange={() => setColumnExists(!columnExists)}
                            inputProps={{ "aria-label": "Column exists" }}
                          />
                          <p>Column exists</p>
                          <StyledTooltip
                            title={
                              <React.Fragment>
                                <Typography
                                  variant="h6"
                                  sx={{ fontSize: "16px" }}
                                  color="inherit"
                                >
                                  Configure a column exists check for each
                                  column to report when the column is no longer
                                  present.
                                </Typography>
                              </React.Fragment>
                            }
                          >
                            <AdjustIcon
                              sx={{ fontSize: "16px", color: "#a1a1aa" }}
                            />
                          </StyledTooltip>
                        </div>
                      </Grid>
                      <Grid item xs={2.4}>
                        <div className="checkBox">
                          <Checkbox
                            checked={timelinessChecks}
                            onChange={() =>
                              setTimelinessChecks(!timelinessChecks)
                            }
                            inputProps={{ "aria-label": "Timeliness checks" }}
                          />
                          <p>Timeliness checks</p>
                          <StyledTooltip
                            title={
                              <React.Fragment>
                                <Typography
                                  variant="h6"
                                  sx={{ fontSize: "16px" }}
                                  color="inherit"
                                >
                                  Configure all types of table's timeliness
                                  checks (freshness, staleness, ingestion
                                  delay). This option works only when the table
                                  is correctly configured to be analyzable by
                                  timeliness check, and has the timestamp column
                                  selected in the configuration of the table in
                                  the Data sources section.
                                </Typography>
                              </React.Fragment>
                            }
                          >
                            <AdjustIcon
                              sx={{ fontSize: "16px", color: "#a1a1aa" }}
                            />
                          </StyledTooltip>
                        </div>
                      </Grid>
                      <Grid item xs={2.4}>
                        <div className="checkBox">
                          <Checkbox
                            checked={prohibitNulls}
                            onChange={() => setProhibitNulls(!prohibitNulls)}
                            inputProps={{ "aria-label": "Prohibit nulls" }}
                          />
                          <p>Nulls (prohibit nulls)</p>
                          <StyledTooltip
                            title={
                              <React.Fragment>
                                <Typography
                                  variant="h6"
                                  sx={{ fontSize: "16px" }}
                                  color="inherit"
                                >
                                  Configure data quality checks that detect
                                  columns that have some null values in columns.
                                  When the percentage of null columns is below
                                  the value of the 'Error rate (% rows)' field,
                                  DQOps will raise a data quality issue when any
                                  null values are detected.
                                </Typography>
                              </React.Fragment>
                            }
                          >
                            <AdjustIcon
                              sx={{ fontSize: "16px", color: "#a1a1aa" }}
                            />
                          </StyledTooltip>
                        </div>
                      </Grid>
                      <Grid item xs={2.4}>
                        <div className="checkBox">
                          <Checkbox
                            checked={requireNulls}
                            onChange={() => setRequireNulls(!requireNulls)}
                            inputProps={{ "aria-label": "Require nulls" }}
                          />
                          <p>Not nulls (require nulls)</p>
                          <StyledTooltip
                            title={
                              <React.Fragment>
                                <Typography
                                  variant="h6"
                                  sx={{ fontSize: "16px" }}
                                  color="inherit"
                                >
                                  Configure data quality checks that require
                                  that columns already containing null values
                                  will contain some null values. This option
                                  could be desirable in some rare cases.
                                </Typography>
                              </React.Fragment>
                            }
                          >
                            <AdjustIcon
                              sx={{ fontSize: "16px", color: "#a1a1aa" }}
                            />
                          </StyledTooltip>
                        </div>
                      </Grid>
                      <Grid item xs={2.4}>
                        <div className="checkBox">
                          <Checkbox
                            checked={detectedDatatypeTexts}
                            onChange={() =>
                              setDetectedDatatypeTexts(!detectedDatatypeTexts)
                            }
                            inputProps={{
                              "aria-label": "Detected datatype in texts",
                            }}
                          />
                          <p>Detected datatype in texts</p>
                          <StyledTooltip
                            title={
                              <React.Fragment>
                                <Typography
                                  variant="h6"
                                  sx={{ fontSize: "16px" }}
                                  color="inherit"
                                >
                                  Configure a check that verifies all values in
                                  a text column and detects a common data type.
                                  If all column values matched the same data
                                  type, such as integers, floats, dates or
                                  timestamps, DQOps will configure a check that
                                  will monitor if any values not matching that
                                  data type appear in the column. This check is
                                  usable for raw tables in the landing zone.
                                </Typography>
                              </React.Fragment>
                            }
                          >
                            <AdjustIcon
                              sx={{ fontSize: "16px", color: "#a1a1aa" }}
                            />
                          </StyledTooltip>
                        </div>
                      </Grid>
                      <Grid item xs={2.4}>
                        <div className="checkBox">
                          <Checkbox
                            checked={uniquenessChecks}
                            onChange={() =>
                              setUniquenessChecks(!uniquenessChecks)
                            }
                            inputProps={{ "aria-label": "Uniqueness checks" }}
                          />
                          <p>Uniqueness checks</p>
                          <StyledTooltip
                            title={
                              <React.Fragment>
                                <Typography
                                  variant="h6"
                                  sx={{ fontSize: "16px" }}
                                  color="inherit"
                                >
                                  Configure uniqueness checks that detect
                                  columns with just a few duplicate values, and
                                  columns with a relatively low number of
                                  distinct values. DQOps will monitor if
                                  duplicate values appear, or the number of
                                  distinct values increases.
                                </Typography>
                              </React.Fragment>
                            }
                          >
                            <AdjustIcon
                              sx={{ fontSize: "16px", color: "#a1a1aa" }}
                            />
                          </StyledTooltip>
                        </div>
                      </Grid>
                      <Grid item xs={2.4}>
                        <div className="checkBox">
                          <Checkbox
                            checked={numericValueRanges}
                            onChange={() =>
                              setNumericValueRanges(!numericValueRanges)
                            }
                            inputProps={{
                              "aria-label": "Numeric values ranges",
                            }}
                          />
                          <p>Numeric values ranges</p>
                          <StyledTooltip
                            title={
                              <React.Fragment>
                                <Typography
                                  variant="h6"
                                  sx={{ fontSize: "16px" }}
                                  color="inherit"
                                >
                                  Validate the values in numeric columns to
                                  detect if the values fall within the ranges
                                  that were observed during data profiling.
                                  DQOps will try to configure the 'min', 'max',
                                  'mean' and 'median' checks.
                                </Typography>
                              </React.Fragment>
                            }
                          >
                            <AdjustIcon
                              sx={{ fontSize: "16px", color: "#a1a1aa" }}
                            />
                          </StyledTooltip>
                        </div>
                      </Grid>
                      <Grid item xs={2.4}>
                        <div className="checkBox">
                          <Checkbox
                            checked={medianPercentileRanges}
                            onChange={() =>
                              setMedianPercentileRanges(!medianPercentileRanges)
                            }
                            inputProps={{
                              "aria-label": "Median and percentile ranges",
                            }}
                          />
                          <p>Median and percentile ranges</p>
                          <StyledTooltip
                            title={
                              <React.Fragment>
                                <Typography
                                  variant="h6"
                                  sx={{ fontSize: "16px" }}
                                  color="inherit"
                                >
                                  Propose the default configuration of the
                                  median and percentile in range checks that
                                  validate the value at a given percentile, such
                                  as a value in middle of all column values. The
                                  default value of this parameter is 'false'
                                  because calculating the median requires
                                  running a separate query on the data source,
                                  which is not advisable for data observability.
                                </Typography>
                              </React.Fragment>
                            }
                          >
                            <AdjustIcon
                              sx={{ fontSize: "16px", color: "#a1a1aa" }}
                            />
                          </StyledTooltip>
                        </div>
                      </Grid>
                      <Grid item xs={2.4}>
                        <div className="checkBox">
                          <Checkbox
                            checked={textLengthRanges}
                            onChange={() =>
                              setTextLengthRanges(!textLengthRanges)
                            }
                            inputProps={{ "aria-label": "Text length ranges" }}
                          />
                          <p>Text length ranges</p>
                          <StyledTooltip
                            title={
                              <React.Fragment>
                                <Typography
                                  variant="h6"
                                  sx={{ fontSize: "16px" }}
                                  color="inherit"
                                >
                                  Configure the checks that analyze text
                                  statistics of text columns, such as the
                                  minimum and maximum length of text values.
                                </Typography>
                              </React.Fragment>
                            }
                          >
                            <AdjustIcon
                              sx={{ fontSize: "16px", color: "#a1a1aa" }}
                            />
                          </StyledTooltip>
                        </div>
                      </Grid>
                      <Grid item xs={2.4}>
                        <div className="checkBox">
                          <Checkbox
                            checked={wordCountInRange}
                            onChange={() =>
                              setWordCountInRange(!wordCountInRange)
                            }
                            inputProps={{ "aria-label": "Word count in range" }}
                          />
                          <p>Word count in range</p>
                          <StyledTooltip
                            title={
                              <React.Fragment>
                                <Typography
                                  variant="h6"
                                  sx={{ fontSize: "16px" }}
                                  color="inherit"
                                >
                                  Propose the default configuration of the
                                  minimum and maximum word count of text
                                  columns. DQOps applies this checks when the
                                  minimum and maximum row count is at least 3
                                  words.
                                </Typography>
                              </React.Fragment>
                            }
                          >
                            <AdjustIcon
                              sx={{ fontSize: "16px", color: "#a1a1aa" }}
                            />
                          </StyledTooltip>
                        </div>
                      </Grid>
                      <Grid item xs={2.4}>
                        <div className="checkBox">
                          <Checkbox
                            checked={percentTrueFalse}
                            onChange={() =>
                              setPercentTrueFalse(!percentTrueFalse)
                            }
                            inputProps={{
                              "aria-label": "Percent of true/false",
                            }}
                          />
                          <p>Percent of true/false</p>
                          <StyledTooltip
                            title={
                              <React.Fragment>
                                <Typography
                                  variant="h6"
                                  sx={{ fontSize: "16px" }}
                                  color="inherit"
                                >
                                  Configure the checks that analyze boolean
                                  values and will configure a range percent
                                  check for the less common value (true or
                                  false).
                                </Typography>
                              </React.Fragment>
                            }
                          >
                            <AdjustIcon
                              sx={{ fontSize: "16px", color: "#a1a1aa" }}
                            />
                          </StyledTooltip>
                        </div>
                      </Grid>
                      <Grid item xs={2.4}>
                        <div className="checkBox">
                          <Checkbox
                            checked={datesOutOfRange}
                            onChange={() =>
                              setDatesOutOfRange(!datesOutOfRange)
                            }
                            inputProps={{ "aria-label": "Dates out of range" }}
                          />
                          <p>Dates out of range</p>
                          <StyledTooltip
                            title={
                              <React.Fragment>
                                <Typography
                                  variant="h6"
                                  sx={{ fontSize: "16px" }}
                                  color="inherit"
                                >
                                  Configure the checks that detect invalid dates
                                  that are far in the past, or far in the
                                  future.
                                </Typography>
                              </React.Fragment>
                            }
                          >
                            <AdjustIcon
                              sx={{ fontSize: "16px", color: "#a1a1aa" }}
                            />
                          </StyledTooltip>
                        </div>
                      </Grid>
                      <Grid item xs={2.4}>
                        <div className="checkBox">
                          <Checkbox
                            checked={valuesInSet}
                            onChange={() => setValuesInSet(!valuesInSet)}
                            inputProps={{ "aria-label": "Values in a set" }}
                          />
                          <p>Values in a set (dictionary)</p>
                          <StyledTooltip
                            title={
                              <React.Fragment>
                                <Typography
                                  variant="h6"
                                  sx={{ fontSize: "16px" }}
                                  color="inherit"
                                >
                                  Proposes the configuration the categorical
                                  values checks 'value_in_set_percent from the
                                  'accepted_values' category. These checks will
                                  be configured to ensure that the column
                                  contains only sample values that were
                                  identified during data profiling.
                                </Typography>
                              </React.Fragment>
                            }
                          >
                            <AdjustIcon
                              sx={{ fontSize: "16px", color: "#a1a1aa" }}
                            />
                          </StyledTooltip>
                        </div>
                      </Grid>
                      <Grid item xs={2.4}>
                        <div className="checkBox">
                          <Checkbox
                            checked={rareValuesInvalid}
                            onChange={() =>
                              setRareValuesInvalid(!rareValuesInvalid)
                            }
                            inputProps={{
                              "aria-label": "Treat rare values as invalid",
                            }}
                          />
                          <p>Treat rare values as invalid</p>
                          <StyledTooltip
                            title={
                              <React.Fragment>
                                <Typography
                                  variant="h6"
                                  sx={{ fontSize: "16px" }}
                                  color="inherit"
                                >
                                  Configure the 'value_in_set_percent' checks
                                  from the 'accepted_values' category to raise
                                  data quality issues for rare values. DQOps
                                  will not add rare categorical values to the
                                  list of expected values.
                                </Typography>
                              </React.Fragment>
                            }
                          >
                            <AdjustIcon
                              sx={{ fontSize: "16px", color: "#a1a1aa" }}
                            />
                          </StyledTooltip>
                        </div>
                      </Grid>
                      <Grid item xs={2.4}>
                        <div className="checkBox">
                          <Checkbox
                            checked={textsTopValues}
                            onChange={() => setTextsTopValues(!textsTopValues)}
                            inputProps={{ "aria-label": "Texts in top values" }}
                          />
                          <p>Texts in top values</p>
                          <StyledTooltip
                            title={
                              <React.Fragment>
                                <Typography
                                  variant="h6"
                                  sx={{ fontSize: "16px" }}
                                  color="inherit"
                                >
                                  Propose the configuration the
                                  texts_in_top_values check from the
                                  'accepted_values' category. This check find
                                  the most common values in a table and ensures
                                  that they are the same values that were
                                  identified during data profiling.
                                </Typography>
                              </React.Fragment>
                            }
                          >
                            <AdjustIcon
                              sx={{ fontSize: "16px", color: "#a1a1aa" }}
                            />
                          </StyledTooltip>
                        </div>
                      </Grid>
                      <Grid item xs={2.4}>
                        <div className="checkBox">
                          <Checkbox
                            checked={textsParsableDataTypes}
                            onChange={() =>
                              setTextsParsableDataTypes(!textsParsableDataTypes)
                            }
                            inputProps={{
                              "aria-label": "Texts parsable of data types",
                            }}
                          />
                          <p>Texts parsable of data types</p>
                          <StyledTooltip
                            title={
                              <React.Fragment>
                                <Typography
                                  variant="h6"
                                  sx={{ fontSize: "16px" }}
                                  color="inherit"
                                >
                                  Proposes the configuration the data type
                                  conversion checks that verify if text values
                                  can be converted to more specific data types
                                  such as boolean, date, float or integer. This
                                  type of checks are used to verify raw tables
                                  in the landing zones.
                                </Typography>
                              </React.Fragment>
                            }
                          >
                            <AdjustIcon
                              sx={{ fontSize: "16px", color: "#a1a1aa" }}
                            />
                          </StyledTooltip>
                        </div>
                      </Grid>
                      <Grid item xs={2.4}>
                        <div className="checkBox">
                          <Checkbox
                            checked={standardTextPatterns}
                            onChange={() =>
                              setStandardTextPatterns(!standardTextPatterns)
                            }
                            inputProps={{
                              "aria-label": "Standard text patterns",
                            }}
                          />
                          <p>Standard text patterns</p>
                          <StyledTooltip
                            title={
                              <React.Fragment>
                                <Typography
                                  variant="h6"
                                  sx={{ fontSize: "16px" }}
                                  color="inherit"
                                >
                                  Propose the default configuration for the
                                  patterns check that validate the format of
                                  popular text patterns, such as UUIDS, phone
                                  numbers, or emails. DQOps will configure these
                                  data quality checks when analyzed columns
                                  contain enough values matching a standard
                                  pattern.
                                </Typography>
                              </React.Fragment>
                            }
                          >
                            <AdjustIcon
                              sx={{ fontSize: "16px", color: "#a1a1aa" }}
                            />
                          </StyledTooltip>
                        </div>
                      </Grid>
                      <Grid item xs={2.4}>
                        <div className="checkBox">
                          <Checkbox
                            checked={detectRegEx}
                            onChange={() => setDetectRegEx(!detectRegEx)}
                            inputProps={{
                              "aria-label": "Detect regular expressions",
                            }}
                          />
                          <p>Detect regular expressions</p>
                          <StyledTooltip
                            title={
                              <React.Fragment>
                                <Typography
                                  variant="h6"
                                  sx={{ fontSize: "16px" }}
                                  color="inherit"
                                >
                                  Analyze sample text values and try to find a
                                  regular expression that detects valid values
                                  similar to the sample values.
                                </Typography>
                              </React.Fragment>
                            }
                          >
                            <AdjustIcon
                              sx={{ fontSize: "16px", color: "#a1a1aa" }}
                            />
                          </StyledTooltip>
                        </div>
                      </Grid>
                      <Grid item xs={2.4}>
                        <div className="checkBox">
                          <Checkbox
                            checked={whitespaceChecks}
                            onChange={() =>
                              setWhitespaceChecks(!whitespaceChecks)
                            }
                            inputProps={{ "aria-label": "Whitespace checks" }}
                          />
                          <p>Whitespace checks</p>
                          <StyledTooltip
                            title={
                              <React.Fragment>
                                <Typography
                                  variant="h6"
                                  sx={{ fontSize: "16px" }}
                                  color="inherit"
                                >
                                  Propose the default configuration for the
                                  whitespace detection checks. Whitespace checks
                                  detect common data quality issues with storing
                                  text representations of null values, such as
                                  'null', 'None', 'n/a' and other texts that
                                  should be stored as regular NULL values.
                                </Typography>
                              </React.Fragment>
                            }
                          >
                            <AdjustIcon
                              sx={{ fontSize: "16px", color: "#a1a1aa" }}
                            />
                          </StyledTooltip>
                        </div>
                      </Grid>
                      <Grid item xs={2.4}>
                        <div className="checkBox">
                          <Checkbox
                            checked={applyPIICheck}
                            onChange={() => setApplyPIICheck(!applyPIICheck)}
                            inputProps={{
                              "aria-label": "Apply PII check rules",
                            }}
                          />
                          <p>Apply PII check rules</p>
                          <StyledTooltip
                            title={
                              <React.Fragment>
                                <Typography
                                  variant="h6"
                                  sx={{ fontSize: "16px" }}
                                  color="inherit"
                                >
                                  Applies rules to Personal Identifiable
                                  Information checks (sensitive data), but only
                                  when the checks were activated manually as
                                  profiling checks, or through a data quality
                                  check pattern that scans all columns for PII
                                  data.
                                </Typography>
                              </React.Fragment>
                            }
                          >
                            <AdjustIcon
                              sx={{ fontSize: "16px", color: "#a1a1aa" }}
                            />
                          </StyledTooltip>
                        </div>
                      </Grid>
                      <Grid item xs={2.4}>
                        <div className="checkBox">
                          <Checkbox
                            checked={customChecks}
                            onChange={() => setCustomChecks(!customChecks)}
                            inputProps={{ "aria-label": "Custom checks" }}
                          />
                          <p>Custom checks</p>
                          <StyledTooltip
                            title={
                              <React.Fragment>
                                <Typography
                                  variant="h6"
                                  sx={{ fontSize: "16px" }}
                                  color="inherit"
                                >
                                  Custom data quality checks must use DQOps
                                  built-in data quality rules, such as
                                  max_percent, min_percent or max_count to find
                                  invalid values.
                                </Typography>
                              </React.Fragment>
                            }
                          >
                            <AdjustIcon
                              sx={{ fontSize: "16px", color: "#a1a1aa" }}
                            />
                          </StyledTooltip>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                ) : (
                  ""
                )}

                <div className="AdvancedParametersButtons">
                  <LoadingButton
                    size="20px"
                    color="secondary"
                    onClick={handleSubmit}
                    loading={loading2}
                    loadingIndicator="Loading"
                    variant="outlined"
                    sx={{
                      height: "34px",
                      padding: "8px 12px",
                      textTransform: "none",
                      backgroundColor: "#f9f9f9",
                      color: "#000000",
                      borderRadius: "10px",
                      border: "1px solid #fb7857",
                      cursor: "pointer",
                      marginRight: "20px",
                      typography: {
                        fontSize: "14px",
                      },
                      "& .MuiButton-startIcon": {
                        fontSize: "10px",
                      },
                      "&:hover": {
                        backgroundColor: "#fb7857",
                      },
                    }}
                  >
                    Purpose
                  </LoadingButton>
                  {/* <button onClick={handleSubmit}>Purpose</button> */}
                  {/* <button>Apply</button> */}
                </div>
              </div>

              <div className="ruleMiningHeading" style={{ marginTop: "10px" }}>
                <p>
                  No new data quality checks are suggested. Please configure
                  additional profiling checks manually to detect other data
                  quality issues and get a new rule proposal
                </p>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="new-class">
          <div className="top-profiling-conatiner">
            <h1>
              Profiling checks for {connection}.{schema}.{table}.
              {selectedProfileColumns}
            </h1>
            <div className="profiling-action">
              {/* <button onClick={() => handleColumnSave(selectedProfileColumns)}>
                Save
                </button> */}
              <LoadingButton
                size="20px"
                color="secondary"
                onClick={() => handleColumnSave(selectedProfileColumns)}
                // onClick={handleSubmit1}
                loading={loading}
                loadingPosition="start"
                startIcon={<SaveIcon />}
                variant="contained"
                sx={{
                  height: "34px",
                  padding: "8px 12px",
                  textTransform: "none",
                  backgroundColor: "#f9f9f9",
                  color: "#000000",
                  borderRadius: "10px",
                  border: "1px solid #fb7857",
                  cursor: "pointer",
                  marginRight: "20px",
                  typography: {
                    fontSize: "14px",
                  },
                  "& .MuiButton-startIcon": {
                    fontSize: "10px",
                  },
                  "&:hover": {
                    backgroundColor: "#fb7857",
                  },
                }}
              >
                Save
              </LoadingButton>
              <LoadingButton
                size="20px"
                color="secondary"
                onClick={() => handleCollectStats()}
                loading={loading1}
                loadingIndicator="Loading…"
                variant="outlined"
                sx={{
                  height: "34px",
                  padding: "8px 12px",
                  textTransform: "none",
                  backgroundColor: "#f9f9f9",
                  color: "#000000",
                  borderRadius: "10px",
                  border: "1px solid #fb7857",
                  cursor: "pointer",
                  marginRight: "20px",
                  typography: {
                    fontSize: "14px",
                  },
                  "& .MuiButton-startIcon": {
                    fontSize: "10px",
                  },
                  "&:hover": {
                    backgroundColor: "#fb7857",
                  },
                }}
              >
                Collect statistics
              </LoadingButton>
              {/* <button onClick={() => handleCollectStats()}>
                  Collect statistics
              </button> */}
              <button onClick={onBackProfile}>Back</button>
            </div>
          </div>
          <div className="tab-data-overview">
            <div
              className={`tab-data ${activeTab2 === "column-stats" ? "active" : ""
                }`}
              onClick={() => {
                setActiveTab2("column-stats");
              }}
            >
              <p>Basic data statistics</p>
            </div>
            <div
              className={`tab-data ${activeTab2 === "profiling-column-rule1" ? "active" : ""
                }`}
              onClick={() => {
                setActiveTab2("profiling-column-rule1");
              }}
            >
              <p>Profiling checks editors</p>
            </div>
          </div>
          {activeTab2 === "column-stats" && (
            <div className="column-stats">
              <div className="stats-header">
                <p>
                  Detected data type:{" "}
                  <strong>
                    {getStat("sampling", "column_samples", "resultDataType") ||
                      "N/A"}
                  </strong>
                </p>
                <p>
                  Data type:{" "}
                  <strong>
                    {statsData?.type_snapshot?.column_type || "N/A"}
                  </strong>
                </p>
                <p>
                  Collected at:{" "}
                  <strong>
                    {formatDate1(
                      getStat("sampling", "column_samples", "collectedAt")
                    )}
                  </strong>
                </p>
              </div>
              <div className="stats-grid">
                {/* Nulls Section */}
                <div className="stats-card">
                  <h3>Nulls</h3>
                  <div className="results-inner">
                    <p>Nulls count: </p>
                    <strong>{getStat("nulls", "nulls_count") || "N/A"}</strong>
                  </div>
                  <div className="results-inner">
                    <p>Nulls percent: </p>
                    <strong>
                      {typeof getStat("nulls", "nulls_percent") === "number"
                        ? getStat("nulls", "nulls_percent").toFixed(2) + "%"
                        : "N/A"}
                    </strong>
                  </div>
                  <div className="results-inner">
                    <p>Not nulls count: </p>
                    <strong>
                      {getStat("nulls", "not_nulls_count") || "N/A"}
                    </strong>
                  </div>
                  <div className="results-inner">
                    <p>Not nulls percent: </p>
                    <strong>
                      {typeof getStat("nulls", "not_nulls_percent") === "number"
                        ? getStat("nulls", "not_nulls_percent").toFixed(2) + "%"
                        : "N/A"}
                    </strong>
                  </div>
                </div>
                {/* Uniqueness Section */}
                <div className="stats-card">
                  <h3>Uniqueness</h3>
                  <div className="results-inner">
                    <p>Distinct count: </p>
                    <strong>
                      {getStat("uniqueness", "distinct_count") || "N/A"}
                    </strong>
                  </div>
                  <div className="results-inner">
                    <p>Distinct percent: </p>
                    <strong>
                      {typeof getStat("uniqueness", "distinct_percent") ===
                        "number"
                        ? getStat("uniqueness", "distinct_percent").toFixed(2) +
                        "%"
                        : "N/A"}
                    </strong>
                  </div>
                  <div className="results-inner">
                    <p>Duplicate count: </p>
                    <strong>
                      {getStat("uniqueness", "duplicate_count") || "N/A"}
                    </strong>
                  </div>
                  <div className="results-inner">
                    <p>Duplicate percent: </p>
                    <strong>
                      {typeof getStat("uniqueness", "duplicate_percent") ===
                        "number"
                        ? getStat("uniqueness", "duplicate_percent").toFixed(
                          2
                        ) + "%"
                        : "N/A"}
                    </strong>
                  </div>
                </div>
                {/* Range Section */}
                <div className="stats-card">
                  <h3>Range</h3>
                  <div className="results-inner">
                    <p>Min value: </p>
                    <strong>{getStat("range", "min_value") || "N/A"}</strong>
                  </div>
                  <div className="results-inner">
                    <p>Max value:</p>
                    <strong>{getStat("range", "max_value") || "N/A"}</strong>
                  </div>
                  <div className="results-inner">
                    <p>Median value: </p>
                    <strong>{getStat("range", "median_value") || "N/A"}</strong>
                  </div>
                  <div className="results-inner">
                    <p>Sum value: </p>
                    <strong>{getStat("range", "sum_value") || "N/A"}</strong>
                  </div>
                  <div className="results-inner">
                    <p>Mean value: </p>
                    <strong>{getStat("range", "mean_value") || "N/A"}</strong>
                  </div>
                </div>
                {/* Text Length Section */}
                <div className="stats-card">
                  <h3>Text length</h3>
                  <div className="results-inner">
                    <p>Text min length: </p>
                    <strong>
                      {getStat("text", "text_min_length") || "N/A"}
                    </strong>
                  </div>
                  <div className="results-inner">
                    <p>Text max length: </p>
                    <strong>
                      {typeof getStat("text", "text_max_length") === "number"
                        ? getStat("text", "text_max_length").toFixed(2)
                        : "N/A"}
                    </strong>
                  </div>
                  <div className="results-inner">
                    <p>Text mean length: </p>
                    <strong>
                      {typeof getStat("text", "text_mean_length") === "number"
                        ? getStat("text", "text_mean_length").toFixed(2)
                        : "N/A"}
                    </strong>
                  </div>
                  <div className="results-inner">
                    <p>Text min word count: </p>
                    <strong>
                      {getStat("text", "text_min_word_count") || "N/A"}
                    </strong>
                  </div>
                  <div className="results-inner">
                    <p>Text max word count: </p>
                    <strong>
                      {getStat("text", "text_max_word_count") || "N/A"}
                    </strong>
                  </div>
                </div>
                {/* Sampling Section */}
                <div className="stats-card">
                  <h3>Top most common values</h3>
                  <div className="results-inner">
                    <p>
                      {getStat("sampling", "column_samples", "result") || "N/A"}
                    </p>
                    <strong>
                      {getStat("sampling", "column_samples", "sampleCount") ||
                        "N/A"}
                    </strong>
                  </div>
                </div>
              </div>
            </div>
          )}

          {activeTab2 === "profiling-column-rule1" && (
            <div className="profiling-checks-editor1">
              <div>
                <div className="top-checks-editor">
                  <div className="checks-status">
                    <p>Scheduling status: </p>
                    <p>
                      {runChecksJobTemplate?.enabled ? "Enabled" : "Disabled"}
                    </p>
                  </div>
                  <div className="checks-status">
                    <p>Scheduling configured at: </p>
                    <p>{effectiveSchedule?.schedule_level}</p>
                  </div>
                  <div className="checks-status">
                    <p>Effective cron expression: </p>
                    <p>{effectiveSchedule?.cron_expression}</p>
                  </div>
                  <div className="checks-status">
                    <p>Next execution at: </p>
                    <p>
                      {effectiveSchedule
                        ? formatDate(effectiveSchedule.time_of_execution)
                        : ""}
                    </p>
                  </div>
                  <div className="checks-status">
                    <p>Schedule configuration: </p>
                    <p>{effectiveSchedule?.schedule_group}</p>
                  </div>
                </div>
                <div className="check-editor-wrapper">
                  <div className="header-all-checks">
                    <p>Data quality check</p>
                    <p>Issue severity level</p>
                    <p>Rule thresholds</p>
                    <div className="all-check-action2">
                      <img src={delete2} alt="delete" />

                      {loadingVideo ? (
                        <CircularProgress size={20} />
                      ) : (
                        <img
                          src={video}
                          alt="video"
                          onClick={handleRunChecks}
                          style={{ cursor: "pointer" }}
                        />
                      )}
                    </div>
                  </div>
                  <div>
                    {checksColumnData.map((category, catIndex) => (
                      <CheckAccordion
                        key={catIndex}
                        category={category}
                        catIndex={catIndex}
                        expanded={expanded}
                        handleChange={handleChange}
                        checkedStates={checkedStates}
                        handleToggle={handleToggle}
                        selectedValues={selectedValues}
                        handleAutocompleteChange={handleAutocompleteChange}
                        editableValues={editableValues}
                        handleEditableValueChange={handleEditableValueChange}
                        options={options}
                        delete2={delete2}
                        video={video}
                        enable={enable}
                        setting1={setting1}
                        clock={clock}
                        runcheck={runcheck}
                        result={result}
                        question={question}
                        handleIconClick={handleIconClick}
                        loadingCategory={loadingCategory} // Pass loadingCategory state
                        setLoadingCategory={setLoadingCategory} // Pass the function
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default ProfilingTable;

const StyledSelect = styled(Select)({
  "&.MuiOutlinedInput-root": {
    borderRadius: ".75rem",
    borderColor: "var(--clr-border)",
    "&:hover fieldset": { borderColor: "var(--clr-dark)" },
    "&.Mui-focused fieldset": { borderColor: "var(--clr-dark)" },
  },
  "& .MuiInputLabel-root": { color: "var(--clr-dark)" },
  "& .MuiInputLabel-root.Mui-focused": { color: "var(--clr-dark)" },
  "& .MuiSelect-select": { color: "var(--clr-dark)" },
  "& .MuiInputLabel-outlined.Mui-focused": { color: "var(--clr-dark)" },
  "& .MuiOutlinedInput-notchedOutline": {
    color: "var(--clr-dark)",
    borderColor: "var(--clr-border)",
  },
});
const StyledFormControl = styled(FormControl)(({ theme }) => ({
  "& .MuiInputLabel-root": { color: "var(--clr-dark)" },
  "& .Mui-focused .MuiInputLabel-root": { color: "var(--clr-dark)" },
  "& .MuiSelect-select": { color: "var(--clr-dark)" },
  "& .MuiInputLabel-outlined.Mui-focused": { color: "var(--clr-dark)" },
  "& .MuiOutlinedInput-notchedOutline": {
    color: "var(--clr-dark)",
    borderColor: "var(--clr-border)",
  },
}));
const StyledTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": { borderRadius: ".75rem", borderColor: "var(--clr-border)" },
    "&:hover fieldset": { borderColor: "var(--clr-dark)" },
    "&.Mui-focused fieldset": { borderColor: "var(--clr-dark)" },
  },
  "& .MuiInputLabel-root": { color: "var(--clr-dark)" },
  "& .MuiInputLabel-root.Mui-focused": { color: "var(--clr-dark)" },
  "& .MuiInputBase-input": { color: "var(--clr-dark)" },
  "& .MuiInputBase-input::placeholder": { color: "var(--clr-dark)" },
});
const StyledBox = styled(Box)(({ theme }) => ({
  padding: "0.625rem",
  height: "100%",
  border: "1px solid var(--clr-border)",
  borderRadius: "1rem",
}));
const StyledRadio = styled(Radio)({
  color: "#fb7857",
  "&.Mui-checked": { color: "#fb7857" },
});
const StyledTableCell = styled(TableCell)({
  fontWeight: "600",
  color: "#424242",
});
const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: { padding: "10px", borderRadius: "12px" },
});
