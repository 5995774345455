// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboard {
  background-color: #ffffff;
  border: 1px solid #e7e7e7;
  border-radius: 20px;
  padding: 20px;
  max-height: 810px;
}
.dashboard-wrapper {
  height: 100%;
  overflow-y: scroll;
  scrollbar-width: none;
}

.dashboard-header {
  font-size: 28px;
  font-weight: 600;
  border-bottom: 1px solid #e7e7e7;
  padding-bottom: 10px;
  margin-bottom: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Home/Dashboard/Dashboard.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,yBAAyB;EACzB,mBAAmB;EACnB,aAAa;EACb,iBAAiB;AACnB;AACA;EACE,YAAY;EACZ,kBAAkB;EAClB,qBAAqB;AACvB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,gCAAgC;EAChC,oBAAoB;EACpB,mBAAmB;AACrB","sourcesContent":[".dashboard {\n  background-color: #ffffff;\n  border: 1px solid #e7e7e7;\n  border-radius: 20px;\n  padding: 20px;\n  max-height: 810px;\n}\n.dashboard-wrapper {\n  height: 100%;\n  overflow-y: scroll;\n  scrollbar-width: none;\n}\n\n.dashboard-header {\n  font-size: 28px;\n  font-weight: 600;\n  border-bottom: 1px solid #e7e7e7;\n  padding-bottom: 10px;\n  margin-bottom: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
