import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import SideNavBar from "../Home/SideNavBar/SideNavBar";
import Badge from "@mui/joy/Badge";
import { Typography } from "@mui/material";
import staricon from "../DataSource/images/star-icon.svg";
import leftarrow from "../DataSource/images/left-arrow-icon.svg";
import right from "../DataSource/images/right.png";
import add from "../DataSource/images/add1.svg";
import Tabs from "./SwitchTabs/Tabs";
import "./DataSetFlow.css";
import { REACT_BASE_LOCAL_URL } from "../../config";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import AddIcon from '@mui/icons-material/Add';

export default function DataSetFlow() {
  const { connectionName } = useParams();
  const [openNotification, setOpenNotification] = useState(false);
  const [jobs, setJobs] = useState([]);
  const [unseenCount, setUnseenCount] = useState(0);
  const [showDetails, setShowDetails] = useState({});
  const [readJobIds, setReadJobIds] = useState([]);
  const notificationRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const tabData = [
    { label: "Data Overview" },
    { label: "Profiling Results" },
    { label: "Monitoring Checks" },
    { label: "Partition checks" },
    { label: "Rules Detected" },
    { label: "Data Quality" },
    { label: "Report" },
  ];

  function onClickHandler() {
    navigate("/data-sources");
  }

  function onClickHandlerChatBot() {
    navigate("/chat-bot");
  }

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const fetchJobs = () => {
    axios
      .get(REACT_BASE_LOCAL_URL + "/api/jobs/jobs")
      .then((response) => {
        const newJobs = response.data.jobs;
        newJobs.sort((a, b) => new Date(b.jobId.createdAt) - new Date(a.jobId.createdAt));
        const unseenJobs = newJobs.filter(job => !readJobIds.includes(job.jobId.jobId));
        if (!openNotification) {
          setUnseenCount(unseenJobs.length);
        }

        setJobs(newJobs);
      })
      .catch((error) => {
        console.error("Error fetching jobs data:", error);
      });
  };

  useEffect(() => {
    const intervalId = setInterval(fetchJobs, 5000);

    return () => {
      clearInterval(intervalId);
    };
  }, [openNotification, readJobIds]);

  const toggleNotification = () => {
    setOpenNotification(!openNotification);
    if (!openNotification) {
      const readJobIdsList = jobs.map(job => job.jobId.jobId);
      setReadJobIds(readJobIdsList);
      setUnseenCount(0);
    }
  };

  const toggleDetails = (jobId) => {
    setShowDetails((prevState) => ({
      ...prevState,
      [jobId]: !prevState[jobId],
    }));
  };

  const formatDate = (dateStr) => {
    const date = new Date(dateStr);
    return date.toLocaleString("sv-SE").replace("T", " ");
  };

  const getJobTypeText = (jobType) => {
    switch (jobType) {
      case "run_scheduled_checks_cron":
        return "Run scheduled checks cron";
      case "run_checks_on_table":
        return "Run checks on table";
      case "run_checks":
        return "Run checks";
      case "collect_statistics":
        return "Collect statistics";
      case "collect_statistics_on_table":
        return "Collect statistics on table";
      case "collect_statis":
        return "Collect statis";
      case "synchronize_multiple_folders":
        return "Synchronize multiple folders";
      case "synchronize_folder":
        return "Synchronize folders";
      case "import_tables":
        return "Import tables";
      default:
        return jobType;
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        notificationRef.current &&
        !notificationRef.current.contains(event.target)
      ) {
        setOpenNotification(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [notificationRef]);

  return (
    <div className="data-set-flow-container">
      <SideNavBar />
      <div className="data-set-flow-right-wrapper">
        <div className="data-set-flow-header">
          <div
            className="back-btn"
            onClick={onClickHandler}
            style={{ cursor: "pointer" }}
          >
            <img src={leftarrow} alt="img" />
            <p>Back</p>
          </div>
          <div className="notification" ref={notificationRef}>
            <Badge
              badgeContent={unseenCount}
              onClick={toggleNotification}
              sx={{ cursor: "pointer" }}
            >
              <Typography sx={{ fontSize: "20px" }}>🔔</Typography>
            </Badge>
            {openNotification && (
              <div className="run-checks-notification">
                <div className="top-run-checks">
                  <h1>Notification:</h1>
                  <h1>{jobs.length}</h1>
                </div>
                <div className="bottom-run-checks">
                  {jobs.map((job) => {
                    const result = job.parameters?.runChecksOnTableParameters?.run_checks_result;
                    let iconColor;
                    if (job.status === "failed") {
                      iconColor = 'red';
                    } else if (job.status === "queued") {
                      iconColor = 'orange';
                    } else if (result && result.warnings === 0 && result.errors === 0 && result.fatals === 0) {
                      iconColor = 'green';
                    } else if (result && (result.warnings > 0 || result.errors > 0 || result.fatals > 0)) {
                      iconColor = 'rgb(235, 229, 30)';
                    } else {
                      iconColor = 'grey';
                    }

                    return (
                      <div className="run-checks-row" key={job.jobId.jobId}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px solid rgb(224 224 224)",
                            padding: "5px 0px",
                          }}
                        >
                          <div className="left-run-checks-row">
                            <p>{getJobTypeText(job.jobType)}</p>
                          </div>
                          <div className="right-run-checks-row">
                            <div className="run-check-status">
                              <div className="run-checks-valid" style={{ display: 'flex', alignItems: 'center' }}>
                                {job.status === "failed" ? (
                                  <CancelIcon style={{ color: iconColor }} />
                                ) : job.status === "queued" ? (
                                  <HourglassEmptyIcon style={{ color: iconColor }} />
                                ) : (
                                  <CheckCircleIcon style={{ color: iconColor }} />
                                )}
                              </div>
                              <p>{formatDate(job.jobId.createdAt)}</p>
                            </div>
                            <img
                              src={add}
                              alt="add details"
                              onClick={() => toggleDetails(job.jobId.jobId)}
                              style={{ cursor: "pointer" }}
                            />
                          </div>
                        </div>
                        {showDetails[job.jobId.jobId] && (
                          <div className="job-details">
                            <p>Status: {job.status}</p>
                            <p>Last changed: {formatDate(job.statusChangedAt)}</p>
                            {job.parameters?.runChecksOnTableParameters && (
                              <>
                                <p>
                                  Highest severity:{" "}
                                  {
                                    job.parameters.runChecksOnTableParameters
                                      .run_checks_result?.highest_severity
                                  }
                                </p>
                                <p>
                                  Executed checks:{" "}
                                  {
                                    job.parameters.runChecksOnTableParameters
                                      .run_checks_result?.executed_checks
                                  }
                                </p>
                                <p>
                                  Valid results:{" "}
                                  {
                                    job.parameters.runChecksOnTableParameters
                                      .run_checks_result?.valid_results
                                  }
                                </p>
                                <p>
                                  Warnings:{" "}
                                  {
                                    job.parameters.runChecksOnTableParameters
                                      .run_checks_result?.warnings
                                  }
                                </p>
                                <p>
                                  Errors:{" "}
                                  {
                                    job.parameters.runChecksOnTableParameters
                                      .run_checks_result?.errors
                                  }
                                </p>
                                <p>
                                  Fatals:{" "}
                                  {
                                    job.parameters.runChecksOnTableParameters
                                      .run_checks_result?.fatals
                                  }
                                </p>
                                <p>
                                  Execution errors:{" "}
                                  {
                                    job.parameters.runChecksOnTableParameters
                                      .run_checks_result?.execution_errors
                                  }
                                </p>
                              </>
                            )}
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="data-set-flow-wrapper">
          <div className="data-set-flow">
            <div className="data-set-flow-heading">
              <h2>{connectionName}</h2>
              <div className="ask-ai-btn" onClick={onClickHandlerChatBot}>
                <img src={staricon} alt="img" />
                <p>Ask AI</p>
              </div>
            </div>
            <Tabs tabs={tabData} />
          </div>
        </div>
      </div>
    </div>
  );
}
