import React, { useEffect } from "react";
import axios from "axios";
import { embedDashboard } from "@superset-ui/embedded-sdk";
import { useParams } from "react-router-dom";

export default function DashboardEmbed({ dashboardId, filterId }) {
  const supersetApiUrl = process.env.REACT_APP_SUPERSET_API_URL; 
  const username = process.env.REACT_APP_SUPERSET_USERNAME; 
  const password = process.env.REACT_APP_SUPERSET_PASSWORD; 
  const supersetDomain = process.env.REACT_APP_SUPERSET_DOMAIN; 
  const embeddedUsername = process.env.REACT_APP_EMBEDDED_USERNAME; 
  const firstName = process.env.REACT_APP_FIRST_NAME; 
  const lastName = process.env.REACT_APP_LAST_NAME; 
  let params = useParams();
  const connectionName = params.connectionName;

  useEffect(() => {
    async function getToken() {
      try {
        const csrfResponse = await axios.get(`${supersetApiUrl}/csrf_token/`);
        const csrfToken = csrfResponse.data;

        const login_body = {
          username: username,
          password: password,
          refresh: true,
          provider: "db"
        };

        const login_headers = {
          headers: {
            "Content-Type": "application/json",
          }
        };

        const { data } = await axios.post(`${supersetApiUrl}/login`, login_body, login_headers);
        const access_token = data['access_token'];

        if (!access_token) {
          throw new Error("Failed to obtain access token");
        }

        const guest_token_body = {
          resources: [
            {
              type: "dashboard",
              id: dashboardId,
            }
          ],
          rls: [],
          user: {
            username: embeddedUsername,
            first_name: firstName,
            last_name: lastName,
          }
        };

        const guest_token_headers = {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${access_token}`,
            'X-Csrftoken': csrfToken['csrf_token'],
            'Cookie': csrfToken['session_cookie']
          }
        };

        const guestTokenResponse = await axios.post(`${supersetApiUrl}/guest_token/`, guest_token_body, guest_token_headers);

        const container = document.getElementById("superset-container");
        if (!container) {
          console.error("Container for embedding dashboard not found.");
          return;
        }

        setTimeout(() => {
          embedDashboard({
            id: dashboardId,
            supersetDomain: supersetDomain,
            mountPoint: container,
            fetchGuestToken: () => guestTokenResponse.data['token'],
            dashboardUiConfig: { 
              hideTitle: true,
              hideChartControls: true,
              filters: { expanded: true },
              urlParams: {
                native_filters:`(NATIVE_FILTER-${filterId}:(extraFormData:(filters:!((col:'connection_name',op:IN,val:!('${connectionName}')))),filterState:(label:'${connectionName}',value:!('${connectionName}')),id:NATIVE_FILTER-${filterId},ownState:()))`
              }
            }
          });

          var iframe = document.querySelector("iframe");
          if (iframe) {
            iframe.style.width = '68vw'; 
            iframe.style.minHeight = '48vw'; 
            iframe.style.border = 'none';
          }
        }, 100); 

      } catch (error) {
        console.error("Error during API call:", error.response ? error.response.data : error.message);
      }
    }   

    getToken();
  }, [supersetApiUrl, dashboardId, username, password, supersetDomain, embeddedUsername, firstName, lastName, filterId, connectionName]);

  return <div id='superset-container'></div>;
}
