// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tabs {
  display: flex;
  gap: 30px;
  padding-bottom: 10px;
  border-bottom: 1px solid #e7e7e7;
}
.tab {
  cursor: pointer;
  padding: 8px 16px;
  font-size: 14px;
  font-weight: 400;
  color: #6e6e6e;
}
.tab-wrapper .active {
  background-color: #f9f9f9;
  color: #000000;
  border-radius: 10px;
  border: 1px solid #fb7857;
}
/* .tab-pane {
  display: none;
} */
.active-tab {
  display: block;
}
`, "",{"version":3,"sources":["webpack://./src/components/DataSetFlow/SwitchTabs/Tabs.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,SAAS;EACT,oBAAoB;EACpB,gCAAgC;AAClC;AACA;EACE,eAAe;EACf,iBAAiB;EACjB,eAAe;EACf,gBAAgB;EAChB,cAAc;AAChB;AACA;EACE,yBAAyB;EACzB,cAAc;EACd,mBAAmB;EACnB,yBAAyB;AAC3B;AACA;;GAEG;AACH;EACE,cAAc;AAChB","sourcesContent":[".tabs {\n  display: flex;\n  gap: 30px;\n  padding-bottom: 10px;\n  border-bottom: 1px solid #e7e7e7;\n}\n.tab {\n  cursor: pointer;\n  padding: 8px 16px;\n  font-size: 14px;\n  font-weight: 400;\n  color: #6e6e6e;\n}\n.tab-wrapper .active {\n  background-color: #f9f9f9;\n  color: #000000;\n  border-radius: 10px;\n  border: 1px solid #fb7857;\n}\n/* .tab-pane {\n  display: none;\n} */\n.active-tab {\n  display: block;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
