import React, { useState, useEffect, useCallback, useRef } from "react";
import axios from "axios";
import SideNavBar from "../Home/SideNavBar/SideNavBar";
import "./DataSource.css";

import DataSourceForm from "../Home/SideNavBar/DataSourceForm/DataSourceForm";
import crossicon from "./images/cross-icon.svg";

import emptyBoxImg from "./images/empty-box 1.svg";
import postgreSQLimg from "./images/postgreSQL.svg";
import snowflakeImg from "./images/snowflake.svg";
import amazonRedshiftImg from "./images/amazonRedshift.svg";
import microsoftAzureImg from "./images/microsoftAzure.svg";
import googleBigQueryImg from "./images/googleBigQuery.svg";
import oracleImg from "./images/oracle.svg";
import databricksImg from "./images/databricks.svg";
import teradataImg from "./images/teradata.svg";
import amazonAthinaImg from "./images/amazonAthena.svg";
import DataSourceTable from "./DataSourceTable/DataSourceTable";
import { REACT_BASE_LOCAL_URL } from "../../config";
import Badge from "@mui/joy/Badge";
import { Checkbox, FormControlLabel, Typography, Divider, InputAdornment } from "@mui/material";
import right from "./images/right.png";
import add from "./images/add1.svg";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import AddIcon from '@mui/icons-material/Add';

export default function DataSource() {
  const [data, setData] = useState([]);
  const tokenStr = localStorage.getItem("accessToken");

  const fetchData = useCallback(() => {
    const storedData = localStorage.getItem("dataSourceTableData");

    if (storedData) {
      setData(JSON.parse(storedData));
    } else {
      axios
        .get(
          "https://data-quality-backend.lab.neuralcompany.team/get_the_csv_data",
          {
            headers: { Authorization: `Bearer ${tokenStr}` },
          }
        )
        .then((response) => {
          setData(response.data);
          localStorage.setItem(
            "dataSourceTableData",
            JSON.stringify(response.data)
          );
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [tokenStr]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleDeleteRow = (id) => {
    axios
      .delete(
        `https://data-quality-backend.lab.neuralcompany.team/delete_the_csv_data/${id}`,
        {
          headers: { Authorization: `Bearer ${tokenStr}` },
        }
      )
      .then(() => {
        const updatedData = data.filter((item) => item.file_id !== id);
        setData(updatedData);
        localStorage.setItem(
          "dataSourceTableData",
          JSON.stringify(updatedData)
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleRefresh = () => {
    fetchData();
  };

  return (
    <div className="data-source">
      <SideNavBar />
      {data.length === 0 ? (
        <NoDataSourceWrapper />
      ) : (
        <DataSourceWrapper
          data={data}
          onRefresh={handleRefresh}
          onDeleteRow={handleDeleteRow}
        />
      )}
    </div>
  );
}

const DataSourceWrapper = ({ data, onRefresh, onDeleteRow }) => {
  const [openNotification, setOpenNotification] = useState(false);
  const [jobs, setJobs] = useState([]);
  const [showDetails, setShowDetails] = useState({});
  const [unseenCount, setUnseenCount] = useState(0);
  const [readJobIds, setReadJobIds] = useState([]);
  const notificationRef = useRef(null);
  const fetchJobs = () => {
    axios
      .get(REACT_BASE_LOCAL_URL + "/api/jobs/jobs")
      .then((response) => {
        const newJobs = response.data.jobs;
        newJobs.sort((a, b) => new Date(b.jobId.createdAt) - new Date(a.jobId.createdAt));
        const unseenJobs = newJobs.filter(job => !readJobIds.includes(job.jobId.jobId));
        if (!openNotification) {
          setUnseenCount(unseenJobs.length);
        }

        setJobs(newJobs);
      })
      .catch((error) => {
        console.error("Error fetching jobs data:", error);
      });
  };

  useEffect(() => {
    const intervalId = setInterval(fetchJobs, 5000);

    return () => {
      clearInterval(intervalId);
    };
  }, [openNotification, readJobIds]);

  const toggleNotification = () => {
    setOpenNotification(!openNotification);
    if (!openNotification) {
      const readJobIdsList = jobs.map(job => job.jobId.jobId);
      setReadJobIds(readJobIdsList);
      setUnseenCount(0);
    }
  };

  const toggleDetails = (jobId) => {
    setShowDetails((prevState) => ({
      ...prevState,
      [jobId]: !prevState[jobId],
    }));
  };

  const formatDate = (dateStr) => {
    const date = new Date(dateStr);
    return date.toLocaleString("sv-SE").replace("T", " ");
  };

  const getJobTypeText = (jobType) => {
    switch (jobType) {
      case "run_scheduled_checks_cron":
        return "Run scheduled checks cron";
      case "run_checks_on_table":
        return "Run checks on table";
      case "run_checks":
        return "Run checks";
      case "collect_statistics":
        return "Collect statistics";
      case "collect_statistics_on_table":
        return "Collect statistics on table";
      case "collect_statis":
        return "Collect statis";
      case "synchronize_multiple_folders":
        return "Synchronize multiple folders";
      case "synchronize_folder":
        return "Synchronize folders";
      case "import_tables":
        return "Import tables";
      default:
        return jobType;
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        notificationRef.current &&
        !notificationRef.current.contains(event.target)
      ) {
        setOpenNotification(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [notificationRef]);
  return (
    <div className="data-source-wrapper">
      <div className="data-source-header">
        <div className="data-source-header-back-btn">
          <p>Data Source Catalog</p>
        </div>
        <div className="notification" ref={notificationRef}>
          <Badge
            badgeContent={unseenCount}
            onClick={toggleNotification}
            sx={{ cursor: "pointer" }}
          >
            <Typography sx={{ fontSize: "20px" }}>🔔</Typography>
          </Badge>
          {openNotification && (
            <div className="run-checks-notification">
              <div className="top-run-checks">
                <h1>Notification:</h1>
                <h1>{jobs.length}</h1>
              </div>
              <div className="bottom-run-checks">
                {jobs.map((job) => {
                  const result = job.parameters?.runChecksOnTableParameters?.run_checks_result;
                  let iconColor;
                  if (job.status === "failed") {
                    iconColor = 'red';
                  } else if (job.status === "queued") {
                    iconColor = 'orange';
                  } else if (result && result.warnings === 0 && result.errors === 0 && result.fatals === 0) {
                    iconColor = 'green';
                  } else if (result && (result.warnings > 0 || result.errors > 0 || result.fatals > 0)) {
                    iconColor = 'rgb(235, 229, 30)';
                  } else {
                    iconColor = 'grey';
                  }

                  return (
                    <div className="run-checks-row" key={job.jobId.jobId}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          borderBottom: "1px solid rgb(224 224 224)",
                          padding: "5px 0px",
                        }}
                      >
                        <div className="left-run-checks-row">
                          <p>{getJobTypeText(job.jobType)}</p>
                        </div>
                        <div className="right-run-checks-row">
                          <div className="run-check-status">
                            <div className="run-checks-valid" style={{ display: 'flex', alignItems: 'center' }}>
                              {job.status === "failed" ? (
                                <CancelIcon style={{ color: iconColor }} />
                              ) : job.status === "queued" ? (
                                <HourglassEmptyIcon style={{ color: iconColor }} />
                              ) : (
                                <CheckCircleIcon style={{ color: iconColor }} />
                              )}
                            </div>
                            <p>{formatDate(job.jobId.createdAt)}</p>
                          </div>
                          <img
                            src={add}
                            alt="add details"
                            onClick={() => toggleDetails(job.jobId.jobId)}
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                      </div>
                      {showDetails[job.jobId.jobId] && (
                        <div className="job-details">
                          <p>Status: {job.status}</p>
                          <p>Last changed: {formatDate(job.statusChangedAt)}</p>
                          {job.parameters?.runChecksOnTableParameters && (
                            <>
                              <p>
                                Highest severity:{" "}
                                {
                                  job.parameters.runChecksOnTableParameters
                                    .run_checks_result?.highest_severity
                                }
                              </p>
                              <p>
                                Executed checks:{" "}
                                {
                                  job.parameters.runChecksOnTableParameters
                                    .run_checks_result?.executed_checks
                                }
                              </p>
                              <p>
                                Valid results:{" "}
                                {
                                  job.parameters.runChecksOnTableParameters
                                    .run_checks_result?.valid_results
                                }
                              </p>
                              <p>
                                Warnings:{" "}
                                {
                                  job.parameters.runChecksOnTableParameters
                                    .run_checks_result?.warnings
                                }
                              </p>
                              <p>
                                Errors:{" "}
                                {
                                  job.parameters.runChecksOnTableParameters
                                    .run_checks_result?.errors
                                }
                              </p>
                              <p>
                                Fatals:{" "}
                                {
                                  job.parameters.runChecksOnTableParameters
                                    .run_checks_result?.fatals
                                }
                              </p>
                              <p>
                                Execution errors:{" "}
                                {
                                  job.parameters.runChecksOnTableParameters
                                    .run_checks_result?.execution_errors
                                }
                              </p>
                            </>
                          )}
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="data-source-heading-wrapper"></div>
      <DataSourceTable
        data={data}
        onRefresh={onRefresh}
        onDeleteRow={onDeleteRow}
      />
    </div>
  );
};

const NoDataSourceWrapper = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };
  return (
    <div className="data-source-wrapper">
      <div className="data-source-header">
        <div className="data-source-header-back-btn">
          <p>Data Source Catalog</p>
        </div>
      </div>
      <div className="data-source-main-container">
        <div className="no-data-source-wrapper">
          <div className="no-data-source-heading">
            <img src={emptyBoxImg} alt="emptyBoxImg" />
            <p>No Data Sources added</p>
            <p>Add data source and start exploring your data</p>
          </div>
          <div className="list-of-data-sources-wrapper">
            <div className="data-source-tool">
              <img src={postgreSQLimg} alt="postgreSQL" />
            </div>
            <div className="data-source-tool">
              <img src={microsoftAzureImg} alt="postgreSQL" />
            </div>
            <div className="data-source-tool">
              <img src={amazonRedshiftImg} alt="postgreSQL" />
            </div>
            <div className="data-source-tool">
              <img src={snowflakeImg} alt="postgreSQL" />
            </div>
            <div className="data-source-tool">
              <img src={googleBigQueryImg} alt="postgreSQL" />
            </div>
            <div className="data-source-tool">
              <img src={amazonAthinaImg} alt="postgreSQL" />
            </div>
            <div className="data-source-tool">
              <img src={teradataImg} alt="postgreSQL" />
            </div>
            <div className="data-source-tool">
              <img src={oracleImg} alt="postgreSQL" />
            </div>
            <div className="data-source-tool">
              <img src={databricksImg} alt="postgreSQL" />
            </div>
          </div>
          <div
            className="drag-drop-file-wrapper drag-drop"
            onClick={toggleSidebar}
          >
            <div id="form-file-upload">
              <label id="label-file-upload">
                <div>
                  <p>Drag and drop your CSV file here or</p>
                  <p>Upload a file</p>
                </div>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div className={`sidebar ${isOpen ? "active" : ""}`}>
        <div className="sd-header">
          <h3 className="">Add Data source</h3>
          <div className="sidebar-cross-btn" onClick={toggleSidebar}>
            <img src={crossicon} alt="crossicon" />
          </div>
        </div>
        <div className="sd-body">
          <DataSourceForm onClose={toggleSidebar} />
        </div>
      </div>
      <div
        className={`sidebar-overlay ${isOpen ? "active" : ""}`}
        onClick={toggleSidebar}
      ></div>
    </div>
  );
};