import React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
  Fade,
  FormControlLabel,
  Switch,
  Autocomplete,
  TextField,
  CircularProgress,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const CheckAccordion = ({
  category,
  catIndex,
  expanded,
  handleChange,
  checkedStates,
  handleToggle,
  selectedValues,
  handleAutocompleteChange,
  editableValues,
  handleEditableValueChange,
  options,
  delete2,
  video,
  enable,
  setting1,
  clock,
  runcheck,
  result,
  question,
  handleIconClick,
  loadingCategory, // Added to receive loading state
}) => {
  return (
    <Accordion
      expanded={expanded === `panel${catIndex + 1}`}
      onChange={handleChange(`panel${catIndex + 1}`)}
    >
      <AccordionSummary
        aria-controls={`panel${catIndex + 1}-content`}
        id={`panel${catIndex + 1}-header`}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          borderBottom: "1px solid #e7e7e7",
          padding: "0px 22px",
        }}
      >
        <div style={{ display: "flex", alignItems: "center", flexGrow: 1 }}>
          <ExpandMoreIcon
            sx={{
              marginRight: "8px",
              transition: "transform 0.3s",
              transform:
                expanded === `panel${catIndex + 1}`
                  ? "rotate(180deg)"
                  : "rotate(0deg)",
            }}
          />
          <Typography style={{ fontWeight: "bold" }}>
            {category.category}
          </Typography>
        </div>

        <div
          className="all-check-action2"
          style={{ display: "flex", gap: "8px" }}
        >
          <img src={delete2} alt="delete" />
          
          {/* Conditionally render the loading spinner or the video icon */}
          {loadingCategory === category.category ? (
            <CircularProgress size={20} />
          ) : (
            <img
              src={video}
              alt="video"
              onClick={(event) => handleIconClick(event, category.category)}
              style={{ cursor: "pointer" }}
            />
          )}
        </div>
      </AccordionSummary>

      {expanded === `panel${catIndex + 1}` && (
        <AccordionDetails style={{ padding: "15px 22px" }}>
          {category.checks.map((check, index) => (
            <div
              className="run-all-action-details"
              key={index}
              style={{ marginBottom: "16px" }}
            >
              <div className="left-action-details">
                <div className="form-controller">
                  <FormControlLabel
                    control={
                      <Switch
                        checked={checkedStates[index] || false}
                        onChange={handleToggle(index)}
                      />
                    }
                  />
                </div>
                <img src={enable} alt="enable" />
                <img src={setting1} alt="settings" />
                <img src={clock} alt="clock" />
                <img src={runcheck} alt="run check" />
                <img src={result} alt="result" />
                <img src={question} alt="question" />

                <div className="row-count-details">
                  <h3>{check.friendly_name}</h3>
                  <p>{`${check.check_name} (${check.quality_dimension})`}</p>
                </div>
                <img src={question} alt="question" />
              </div>

              <div>
                <Autocomplete
                  value={selectedValues[index] || "None"}
                  onChange={handleAutocompleteChange(index)}
                  options={options}
                  disabled={!checkedStates[index]}
                  sx={{ width: 200 }}
                  renderInput={(params) => (
                    <TextField {...params} label="Alert" />
                  )}
                />
              </div>
              <Box sx={{ mt: 1 }}>
                <Fade in={checkedStates[index] || false}>
                  <div
                    className="run-checks-data-wrapper"
                    style={{
                      backgroundColor: "#fef2e3",
                      padding: "10px",
                      borderRadius: "5px",
                    }}
                  >
                    <div
                      className="run-check-top"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <h3 style={{ margin: 0 }}>Values: </h3>
                      <img
                        src={question}
                        alt="question"
                        style={{ marginLeft: "5px" }}
                      />
                    </div>
                    <div className="run-check-data">
                      <TextField
                        value={
                          editableValues[index] !== undefined
                            ? editableValues[index]
                            : check.rule[selectedValues[index]?.toLowerCase()]
                                ?.rule_parameters[0]?.definition.default_value
                        }
                        onChange={(event) =>
                          handleEditableValueChange(index, event)
                        }
                        disabled={!checkedStates[index]}
                        sx={{
                          width: "120px",
                          backgroundColor: "white",
                          padding: "0",
                        }}
                        variant="outlined"
                      />
                    </div>
                  </div>
                </Fade>
              </Box>
            </div>
          ))}
        </AccordionDetails>
      )}
    </Accordion>
  );
};

export default CheckAccordion;
