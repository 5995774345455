import { useParams } from "react-router-dom";
import database from "../SwitchTabOne/images/database.png";
import styles from "./SwitchTabTwo.css";
import { useEffect, useState } from "react";
import { handleGetSchema } from "./switchTabTwoAPI.jsx";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import datasource from "../SwitchTabOne/images/data-source.png";
import profiling from "../SwitchTabOne/images/profiling.png";
import monitoringchecks from "../SwitchTabOne/images/monitoring-checks.png";
import partitionchecks from "../SwitchTabOne/images/partition-checks.png";
import { REACT_BASE_LOCAL_URL, REACT_BASE_PROD_URL } from "../../../../config";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import ProfilingTable from "../../../ProfilingTable/ProfilingTable.jsx";
import { Tooltip } from "@mui/material";
import CheckAccordion from "../../../ProfilingColumn/ProfilingColumn.jsx";
import delete2 from "../../../ProfilingTable/Images/delete2.svg";
import video from "../../../ProfilingTable/Images/video-run.svg";
import enable from "../../../ProfilingTable/Images/enable.svg";
import setting1 from "../../../ProfilingTable/Images/setting1.svg";
import clock from "../../../ProfilingTable/Images/clock.svg";
import runcheck from "../../../ProfilingTable/Images/run-check.svg";
import result from "../../../ProfilingTable/Images/result.svg";
import question from "../../../ProfilingTable/Images/question.svg";


function SwitchTabMonitoringChecks({setActiveTab}) {
  const [data, setData] = useState([]);
  const [activeTab2, setActiveTab2] = useState("column-stats");
  const handleImageClick1 = () => {
    setActiveTab(0);
  };
  const handleImageClick2 = () => {
    setActiveTab(1);
  };
  const handleImageClick3 = () => {
    setActiveTab(2);
  };
  const handleImageClick4 = () => {
    setActiveTab(3);
  };
  const datasourceInfo = "Data sources";
  const profilingInfo = "Profiling checks";
  const monitoringInfo = "Monitoring checks";
  const partitionInfo = "Partition checks";

  const { connectionName } = useParams();
  const navigate = useNavigate();

  const [activeTab1, setActiveTab1] = useState("data-quality");
  const [schemaData, setSchemaData] = useState([]);

  const [tables, setTables] = useState([]);
  const [selectedTables, setSelectedTables] = useState([]);

  const [tableData, setTableData] = useState([]);
  const [loadingTables, setLoadingTables] = useState(true);
  const [errorTables, setErrorTables] = useState(null);

  const [columnData, setColumnData] = useState([]);
  const [loadingColumns, setLoadingColumns] = useState(true);
  const [errorColumns, setErrorColumns] = useState(null);
  const [activeScheduleTab1, setActiveScheduleTab1] = useState("data-tables");

  const handleScheduleTabClick1 = (tab) => {
    setActiveScheduleTab1(tab);
  };

  const [schemaFilter, setSchemaFilter] = useState("");
  const [tableFilter, setTableFilter] = useState("");
  const [columnFilter, setColumnFilter] = useState("");
  const [typeFilter, setTypeFilter] = useState("");
  const [searchTriggered, setSearchTriggered] = useState(false);

  // Separate filters for each view
  const [tableSchemaFilter, setTableSchemaFilter] = useState("");
  const [tableTableFilter, setTableTableFilter] = useState("");
  const [columnSchemaFilter, setColumnSchemaFilter] = useState("");
  const [columnTableFilter, setColumnTableFilter] = useState("");
  // const [columnFilter, setColumnFilter] = useState('');
  // const [typeFilter, setTypeFilter] = useState('');

  const [filteredTableRows, setFilteredTableRows] = useState([]);
  const [filteredColumnRows, setFilteredColumnRows] = useState([]);

  // pagination states for columns
  const [currentColumnPage, setCurrentColumnPage] = useState(1);
  const rowsPerPage = 10;

  // pagination states for tables
  const [currentTablePage, setCurrentTablePage] = useState(1);

  const [selectedTable, setSelectedTable] = useState(null);
  const [viewingTableInfo, setViewingTableInfo] = useState(false);


  const handleTablePageChange = (event, value) => {
    setCurrentTablePage(value);
  };
  const handleColumnPageChange = (event, value) => {
    setCurrentColumnPage(value);
  };

  // pagination logic for columns
  const indexOfLastColumnRow = currentColumnPage * rowsPerPage;
  const indexOfFirstColumnRow = indexOfLastColumnRow - rowsPerPage;
  const currentColumnRows = columnData.slice(
    indexOfFirstColumnRow,
    indexOfLastColumnRow
  );
  const totalColumnPages = Math.ceil(columnData.length / rowsPerPage);

  // pagination logic for tables
  const indexOfLastTableRow = currentTablePage * rowsPerPage;
  const indexOfFirstTableRow = indexOfLastTableRow - rowsPerPage;
  const currentTableRows = tableData.slice(
    indexOfFirstTableRow,
    indexOfLastTableRow
  );
  const totalTablePages = Math.ceil(tableData.length / rowsPerPage);

  useEffect(() => {
    const fetchSchema = async () => {
      const data = await handleGetSchema({ DB_NAME: connectionName });
      setSchemaData(data);
    };

    fetchSchema();
  }, []);

  useEffect(() => {
    const fetchColumnsData = async () => {
      try {
        const response = await axios.get(
          REACT_BASE_LOCAL_URL +
            `/api/search/columns?connection=${connectionName}&schema=&table=&column=&checkType=profiling`
        );
        setColumnData(response.data);
        setLoadingColumns(false);
      } catch (err) {
        setErrorColumns(err);
        setLoadingColumns(false);
      }
    };

    fetchColumnsData();
  }, [connectionName]);

  useEffect(() => {
    const fetchTablesData = async () => {
      try {
        const response = await axios.get(
          REACT_BASE_LOCAL_URL +
            `/api/search/tables?connection=${connectionName}&schema=&table=&checkType=profiling`
        );
        setTableData(response.data);
        setLoadingTables(false);
      } catch (err) {
        setErrorTables(err);
        setLoadingTables(false);
      }
    };

    fetchTablesData();
  }, [connectionName]);

  useEffect(() => {
    if (searchTriggered) {
      const filteredTables = currentTableRows.filter((item) => {
        const schemaMatch = item.target.schema_name
          .toLowerCase()
          .includes(tableSchemaFilter.toLowerCase());
        const tableMatch = item.target.table_name
          .toLowerCase()
          .includes(tableTableFilter.toLowerCase());
        return schemaMatch && tableMatch;
      });
  
      if (JSON.stringify(filteredTables) !== JSON.stringify(filteredTableRows)) {
        setFilteredTableRows(filteredTables);
      }
    } else {
      if (JSON.stringify(currentTableRows) !== JSON.stringify(filteredTableRows)) {
        setFilteredTableRows(currentTableRows);
      }
    }
  }, [searchTriggered, tableSchemaFilter, tableTableFilter, currentTableRows]);
  
  useEffect(() => {
    if (searchTriggered) {
      const filteredColumns = currentColumnRows.filter((item) => {
        const schemaMatch = item.table.schema_name
          .toLowerCase()
          .includes(columnSchemaFilter.toLowerCase());
        const tableMatch = item.table.table_name
          .toLowerCase()
          .includes(columnTableFilter.toLowerCase());
        const columnMatch = item.column_name
          .toLowerCase()
          .includes(columnFilter.toLowerCase());
        const typeMatch = item.type_snapshot.column_type
          .toLowerCase()
          .includes(typeFilter.toLowerCase());
        return schemaMatch && tableMatch && columnMatch && typeMatch;
      });
      if (JSON.stringify(filteredColumns) !== JSON.stringify(filteredColumnRows)) {
        setFilteredColumnRows(filteredColumns);
      }
    } else {
      if (JSON.stringify(currentColumnRows) !== JSON.stringify(filteredColumnRows)) {
        setFilteredColumnRows(currentColumnRows);
      }
    }
  }, [
    searchTriggered,
    columnSchemaFilter,
    columnTableFilter,
    columnFilter,
    typeFilter,
    currentColumnRows,
    filteredColumnRows,
  ]);

  const handleSearch = () => {
    setSearchTriggered(true);
  };

  const options = ["Error", "Warning", "Fatal", "Multiple error"];
  const [value, setValue] = useState(options[0]);
  const [inputValue, setInputValue] = useState("Error");
  const [selectedSchemaName1, setSelectedSchemaName1] = useState("");
  const [selectedTableName1, setSelectedTableName1] = useState("");
  
  const handleRunCategoryChecks = async (category) => {
    const fullTableName = `${selectedSchemaName1}.${selectedTableName1}`;
    const payload = {
      check_search_filters: {
        connection: connectionName,
        fullTableName: fullTableName,
        enabled: true,
        checkTarget: "table",
        checkType: "profiling",
        checkCategory: category,
      },
      collect_error_samples: true,
    };

    try {
      const apiUrl = `${REACT_BASE_LOCAL_URL}/api/jobs/runchecks?wait=false`;
      const response = await axios.post(apiUrl, payload);
      console.log("Run checks response:", response.data);
    } catch (error) {
      console.error("Error running checks:", error);
    }
  };
  
  const handleIconClick = (event, category) => {
    event.stopPropagation(); 
    handleRunCategoryChecks(category); 
  };

  const handleEditableValueChange = (index, event) => {
    setEditableValues((prev) => ({
      ...prev,
      [index]: event.target.value,
    }));
  };

  const handleToggle = (index) => (event) => {
    setCheckedStates((prev) => ({
      ...prev,
      [index]: event.target.checked,
    }));
  };

  const handleAutocompleteChange = (index) => (event, newValue) => {
    setSelectedValues((prev) => ({
      ...prev,
      [index]: newValue,
    }));
  };

  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleRunChecks = async () => {
    const fullTableName = `${selectedSchemaName1}.${selectedTableName1}`;
    const payload = {
      check_search_filters: {
        connection: connectionName,
        fullTableName: fullTableName,
        enabled: true,
        checkTarget: "table",
        checkType: "profiling",
      },
    };

    try {
      const apiUrl = `${REACT_BASE_LOCAL_URL}/api/jobs/runchecks?wait=false`;
      const response = await axios.post(apiUrl, payload);
      console.log("Run checks response:", response.data);
    } catch (error) {
      console.error("Error running checks:", error);
    }
  };

  const formatDate = (dateStr) => {
    const date = new Date(dateStr);
    return date
      .toLocaleString("en-US", {
        year: "numeric",
        month: "short",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      })
      .replace(",", "");
  };

  const handleCollectStats = (columnName) => {
    const fullTableName = `${selectedSchemaName1}.${selectedTableName1}`;
    const payload = {
      connection: connectionName,
      fullTableName: fullTableName,
      enabled: true,
      columnNames: columnName ? [columnName] : [],
    };
    axios
      .post(
        `${REACT_BASE_LOCAL_URL}/api/jobs/collectstatistics/table?configureTable=false&wait=false`,
        payload
      )
      .then((response) => {
        axios
          .get(
            `${REACT_BASE_LOCAL_URL}/api/connections/${connectionName}/schemas/${selectedSchemaName1}/tables/${selectedTableName1}/columns/statistics`
          )
          .then((response) => {
            const formattedData = response.data.column_statistics.map(
              (column) => {
                const stats = column.statistics || [];
                const nullsPercentStat = stats.find(
                  (stat) => stat.collector === "nulls_percent"
                );
                const nullsPercent = nullsPercentStat
                  ? parseFloat(nullsPercentStat.result).toFixed(2) + "%"
                  : "N/A";

                return {
                  columnName: column.column_name || "N/A",
                  detectedDataType:
                    stats.find((stat) => stat.collector === "column_samples")
                      ?.resultDataType || "N/A",
                  importedDataType: column.type_snapshot?.column_type || "N/A",
                  length: column.type_snapshot?.length || "N/A",
                  nullsPercent: nullsPercent,
                  distinctCount:
                    stats.find((stat) => stat.collector === "distinct_count")
                      ?.result || "N/A",
                };
              }
            );
            setData(formattedData);
          })
          .catch((error) => {
            console.error("Error fetching the updated data:", error);
          });
      })
      .catch((error) => {
        console.error("Error collecting statistics:", error);
      });
  };

  const [checksColumnData, setChecksColumnData] = useState([]);
  const [editableValues, setEditableValues] = useState({});
  const [effectiveSchedule, setEffectiveSchedule] = useState(null);
  const [runChecksJobTemplate, setRunChecksJobTemplate] = useState(null);
  const [effectiveScheduleEnabledStatus, setEffectiveScheduleEnabledStatus] =useState("");

  const [checksData, setChecksData] = useState([]);
  const [checkedStates, setCheckedStates] = useState(
    Array(checksData.length).fill(false)
  );
  const [selectedValues, setSelectedValues] = useState(
    Array(checksData.length).fill("None")
  );
  const [inputValues, setInputValues] = useState(
    Array(checksData.length).fill("")
  );
  const [loading, setLoading] = useState(false);

  const fetchColumnChecksData = async (columnName,schemaName1,tableName1) => {
    try {
      const response = await axios.get(
        `${REACT_BASE_LOCAL_URL}/api/connections/${connectionName}/schemas/${schemaName1}/tables/${tableName1}/columns/${columnName}/profiling/model`
      );
      const checks = response.data.categories;
      setChecksColumnData(checks);

      const {
        effective_schedule,
        run_checks_job_template,
        effective_schedule_enabled_status,
      } = response.data;

      setEffectiveSchedule(effective_schedule);
      setRunChecksJobTemplate(run_checks_job_template);
      setEffectiveScheduleEnabledStatus(effective_schedule_enabled_status);

      const initialCheckedStates = [];
      const initialSelectedValues = [];
      const initialInputValues = [];
      const initialEditableValues = {};

      checks.forEach((category, catIndex) => {
        category.checks.forEach((check, checkIndex) => {
          const index = `${catIndex}-${checkIndex}`;
          initialCheckedStates[index] = false;
          initialSelectedValues[index] = "Error"; // Default selection
          initialInputValues[index] = "1"; // Default long_value
          initialEditableValues[index] =
            check.rule["error"].rule_parameters[0].definition.default_value; // Default value from the response
        });
      });

      setCheckedStates(initialCheckedStates);
      setSelectedValues(initialSelectedValues);
      setInputValues(initialInputValues);
      setEditableValues(initialEditableValues);
    } catch (error) {
      console.error("Error fetching checks data:", error);
    }
  };

  const handleColumnSave = async (columnName, schemaName1, tableName1) => {
    // Ensure columnName is a string
    if (typeof columnName !== 'string' || columnName.trim() === '') {
      console.error("Invalid column name provided:", columnName);
      return;
    }

    const payload = {
      categories: checksColumnData.map((category, catIndex) => {
        return {
          category: category.category,
          checks: category.checks.map((check, checkIndex) => {
            const index = `${catIndex}-${checkIndex}`;
            const severity = selectedValues[index]?.toLowerCase();
            const updatedLongValue =
              editableValues[index] ||
              check.rule[severity]?.rule_parameters[0]?.definition.default_value;

            return {
              friendly_name: check.friendly_name,
              check_name: check.check_name,
              quality_dimension: check.quality_dimension,
              rule: {
                [severity]: {
                  ...check.rule[severity],
                  long_value: Number(updatedLongValue),
                },
              },
              checked: checkedStates[index] || false,
            };
          }),
        };
      }),
    };

    try {
      const response = await axios.put(
        `${REACT_BASE_LOCAL_URL}/api/connections/${connectionName}/schemas/${selectedSchemaName1}/tables/${selectedTableName1}/columns/${columnName}/profiling/model`,
        payload
      );
      console.log("Save response:", response.data);
    } catch (error) {
      console.error("Error saving checks data:", error);
    }
  };

  const [selectedProfileColumns, setSelectedProfileColumns] = useState([]);
  const [isProfilingVisible, setProfilingVisible] = useState(false);

  const onBackProfile = () => {
    setProfilingVisible(false);
  };

  const [statsData, setStatsData] = useState(null);

  const fetchColumnStatistics1 = (columnName, schemaName1, tableName1) => {
    axios
      .get(`${REACT_BASE_LOCAL_URL}/api/connections/${connectionName}/schemas/${schemaName1}/tables/${tableName1}/columns/${columnName}/statistics`)
      .then((response) => {
        setStatsData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching statistics:", error);
      });
  };

  const handleColumnClick = (columnName, schemaName1, tableName1) => {
    setSelectedProfileColumns(columnName);
    setSelectedSchemaName1(schemaName1);  // Store the selected schema name
    setSelectedTableName1(tableName1);    // Store the selected table name
    setProfilingVisible(true);
    
    console.log("Selected Column:", columnName,schemaName1,tableName1);
    
    fetchColumnStatistics1(columnName, schemaName1, tableName1);
    fetchColumnChecksData(columnName, schemaName1, tableName1); 
  };
  
  

  const getStat = (category, collector, field = "result") => {
    const stat = statsData?.statistics.find(
      (item) => item.category === category && item.collector === collector
    );
    return stat ? stat[field] : "N/A";
  };

  const formatDate1 = (dateString) => {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return "N/A";
    }
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

  return (
    <div className={styles.SwitchTabMonitoringChecks}>
      {isProfilingVisible ? (
        <div className="new-class">
          <div className="top-profiling-conatiner">
            <h1>
              Profiling checks for {connectionName}.{selectedSchemaName1}.{selectedTableName1}.
              {selectedProfileColumns}
            </h1>
            <div className="profiling-action">
              <button onClick={() => handleColumnSave(selectedProfileColumns)}>
                Save
              </button>
              <button onClick={() => handleCollectStats()}>
                Collect statistics
              </button>
              <button onClick={onBackProfile}>Back</button>
            </div>
          </div>
          <div className="tab-data-overview">
            <div
              className={`tab-data ${activeTab2 === "column-stats" ? "active" : ""
                }`}
              onClick={() => {
                setActiveTab2("column-stats");
              }}
            >
              <p>Basic data statistics</p>
            </div>
            <div
              className={`tab-data ${activeTab2 === "profiling-column-rule1" ? "active" : ""
                }`}
              onClick={() => {
                setActiveTab2("profiling-column-rule1");
              }}
            >
              <p>Profiling checks editors</p>
            </div>
          </div>
          {activeTab2 === "column-stats" && (
            <div className="column-stats">
              <div className="stats-header">
                <p>
                  Detected data type:{" "}
                  <strong>
                    {getStat("sampling", "column_samples", "resultDataType") ||
                      "N/A"}
                  </strong>
                </p>
                <p>
                  Data type:{" "}
                  <strong>
                    {statsData?.type_snapshot?.column_type || "N/A"}
                  </strong>
                </p>
                <p>
                  Collected at:{" "}
                  <strong>
                    {formatDate1(
                      getStat("sampling", "column_samples", "collectedAt")
                    )}
                  </strong>
                </p>
              </div>
              <div className="stats-grid">
                {/* Nulls Section */}
                <div className="stats-card">
                  <h3>Nulls</h3>
                  <div className="results-inner">
                    <p>Nulls count: </p>
                    <strong>{getStat("nulls", "nulls_count") || "N/A"}</strong>
                  </div>
                  <div className="results-inner">
                    <p>Nulls percent: </p>
                    <strong>
                      {typeof getStat("nulls", "nulls_percent") === "number"
                        ? getStat("nulls", "nulls_percent").toFixed(2) + "%"
                        : "N/A"}
                    </strong>
                  </div>
                  <div className="results-inner">
                    <p>Not nulls count: </p>
                    <strong>
                      {getStat("nulls", "not_nulls_count") || "N/A"}
                    </strong>
                  </div>
                  <div className="results-inner">
                    <p>Not nulls percent: </p>
                    <strong>
                      {typeof getStat("nulls", "not_nulls_percent") === "number"
                        ? getStat("nulls", "not_nulls_percent").toFixed(2) + "%"
                        : "N/A"}
                    </strong>
                  </div>
                </div>
                {/* Uniqueness Section */}
                <div className="stats-card">
                  <h3>Uniqueness</h3>
                  <div className="results-inner">
                    <p>Distinct count: </p>
                    <strong>
                      {getStat("uniqueness", "distinct_count") || "N/A"}
                    </strong>
                  </div>
                  <div className="results-inner">
                    <p>Distinct percent: </p>
                    <strong>
                      {typeof getStat("uniqueness", "distinct_percent") ===
                        "number"
                        ? getStat("uniqueness", "distinct_percent").toFixed(2) +
                        "%"
                        : "N/A"}
                    </strong>
                  </div>
                  <div className="results-inner">
                    <p>Duplicate count: </p>
                    <strong>
                      {getStat("uniqueness", "duplicate_count") || "N/A"}
                    </strong>
                  </div>
                  <div className="results-inner">
                    <p>Duplicate percent: </p>
                    <strong>
                      {typeof getStat("uniqueness", "duplicate_percent") ===
                        "number"
                        ? getStat("uniqueness", "duplicate_percent").toFixed(
                          2
                        ) + "%"
                        : "N/A"}
                    </strong>
                  </div>
                </div>
                {/* Range Section */}
                <div className="stats-card">
                  <h3>Range</h3>
                  <div className="results-inner">
                    <p>Min value: </p>
                    <strong>{getStat("range", "min_value") || "N/A"}</strong>
                  </div>
                  <div className="results-inner">
                    <p>Max value:</p>
                    <strong>{getStat("range", "max_value") || "N/A"}</strong>
                  </div>
                  <div className="results-inner">
                    <p>Median value: </p>
                    <strong>{getStat("range", "median_value") || "N/A"}</strong>
                  </div>
                  <div className="results-inner">
                    <p>Sum value: </p>
                    <strong>{getStat("range", "sum_value") || "N/A"}</strong>
                  </div>
                  <div className="results-inner">
                    <p>Mean value: </p>
                    <strong>{getStat("range", "mean_value") || "N/A"}</strong>
                  </div>
                </div>
                {/* Text Length Section */}
                <div className="stats-card">
                  <h3>Text length</h3>
                  <div className="results-inner">
                    <p>Text min length: </p>
                    <strong>
                      {getStat("text", "text_min_length") || "N/A"}
                    </strong>
                  </div>
                  <div className="results-inner">
                    <p>Text max length: </p>
                    <strong>
                      {typeof getStat("text", "text_max_length") === "number"
                        ? getStat("text", "text_max_length").toFixed(2)
                        : "N/A"}
                    </strong>
                  </div>
                  <div className="results-inner">
                    <p>Text mean length: </p>
                    <strong>
                      {typeof getStat("text", "text_mean_length") === "number"
                        ? getStat("text", "text_mean_length").toFixed(2)
                        : "N/A"}
                    </strong>
                  </div>
                  <div className="results-inner">
                    <p>Text min word count: </p>
                    <strong>
                      {getStat("text", "text_min_word_count") || "N/A"}
                    </strong>
                  </div>
                  <div className="results-inner">
                    <p>Text max word count: </p>
                    <strong>
                      {getStat("text", "text_max_word_count") || "N/A"}
                    </strong>
                  </div>
                </div>
                {/* Sampling Section */}
                <div className="stats-card">
                  <h3>Top most common values</h3>
                  <div className="results-inner">
                    <p>
                      {getStat("sampling", "column_samples", "result") || "N/A"}
                    </p>
                    <strong>
                      {getStat("sampling", "column_samples", "sampleCount") ||
                        "N/A"}
                    </strong>
                  </div>
                </div>
              </div>
            </div>
          )}

          {activeTab2 === "profiling-column-rule1" && (
            <div className="profiling-checks-editor1">
              <div>
                <div className="top-checks-editor">
                  <div className="checks-status">
                    <p>Scheduling status: </p>
                    <p>
                      {runChecksJobTemplate?.enabled ? "Enabled" : "Disabled"}
                    </p>
                  </div>
                  <div className="checks-status">
                    <p>Scheduling configured at: </p>
                    <p>{effectiveSchedule?.schedule_level}</p>
                  </div>
                  <div className="checks-status">
                    <p>Effective cron expression: </p>
                    <p>{effectiveSchedule?.cron_expression}</p>
                  </div>
                  <div className="checks-status">
                    <p>Next execution at: </p>
                    <p>
                      {effectiveSchedule
                        ? formatDate(effectiveSchedule.time_of_execution)
                        : ""}
                    </p>
                  </div>
                  <div className="checks-status">
                    <p>Schedule configuration: </p>
                    <p>{effectiveSchedule?.schedule_group}</p>
                  </div>
                </div>
                <div className="check-editor-wrapper">
                  <div className="header-all-checks">
                    <p>Data quality check</p>
                    <p>Issue severity level</p>
                    <p>Rule thresholds</p>
                    <div className="all-check-action2">
                      <img src={delete2}></img>
                      <img
                        src={video}
                        alt="video"
                        onClick={handleRunChecks}
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                  </div>
                  <div>
                    {checksColumnData.map((category, catIndex) => (
                      <CheckAccordion
                        key={catIndex}
                        category={category}
                        catIndex={catIndex}
                        expanded={expanded}
                        handleChange={handleChange}
                        checkedStates={checkedStates}
                        handleToggle={handleToggle}
                        selectedValues={selectedValues}
                        handleAutocompleteChange={handleAutocompleteChange}
                        editableValues={editableValues}
                        handleEditableValueChange={handleEditableValueChange}
                        options={options}
                        delete2={delete2}
                        video={video}
                        enable={enable}
                        setting1={setting1}
                        clock={clock}
                        runcheck={runcheck}
                        result={result}
                        question={question}
                        handleIconClick={handleIconClick}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      ) :viewingTableInfo ? (
          <ProfilingTable 
            table={selectedTable.name} 
            schema={selectedTable.schema} 
            connection={selectedTable.connection}
            onBack={() => setViewingTableInfo(false)}
          />
        ) : (
        <div>
      <div className="top-data-overview">
        <div className="connection-name">
          <img src={database} alt="database" />
          <h2>{connectionName}</h2>
        </div>
      </div>

      <div className="tab-data-overview">
        <div
          className={`tab-data ${activeTab1 === "schemas" ? "active" : ""}`}
          onClick={() => {
            setActiveTab1("schemas");
          }}
        >
          <p>Schemas</p>
        </div>
        <div
          className={`tab-data ${activeTab1 === "data-quality" ? "active" : ""}`}
          onClick={() => {
            setActiveTab1("data-quality");
          }}
        >
          <p>Data quality summary</p>
        </div>
      </div>

      {/* {console.log(schemaData)} */}

      {activeTab1 === "schemas" && (
        <table className={styles.schema}>
          <tbody>
            {schemaData.length > 0 ? (
              schemaData.map((schema, index) => (
                <tr key={index} className={styles.schemaData}>
                  <td>
                    <h4>{schema.schema_name}</h4>
                  </td>
                  <td>
                    <p>Edit multiple data quality checks</p>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="2">
                  <p>No schema data available</p>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      )}

      {activeTab1 === "data-quality" && (
        <div>
          <div className="nested-tab-overview">
            <div
              className={`tab-data ${
                activeScheduleTab1 === "data-tables" ? "active" : ""
              }`}
              onClick={() => handleScheduleTabClick1("data-tables")}
            >
              <p>Tables</p>
            </div>
            <div
              className={`tab-data ${
                activeScheduleTab1 === "data-columns" ? "active" : ""
              }`}
              onClick={() => handleScheduleTabClick1("data-columns")}
            >
              <p>Columns</p>
            </div>
          </div>
          {activeScheduleTab1 === "data-tables" && (
            <div className="data-tables-container">
              <div className="filter-section">
                <input
                  type="text"
                  placeholder="Schema name"
                  className="filter-input"
                  value={tableSchemaFilter}
                  onChange={(e) => setTableSchemaFilter(e.target.value)}
                />
                <input
                  type="text"
                  placeholder="Table name"
                  className="filter-input"
                  value={tableTableFilter}
                  onChange={(e) => setTableTableFilter(e.target.value)}
                />
                <button className="search-button" onClick={handleSearch}>
                  Search
                </button>
                <button
                  className="reset-button"
                  onClick={() => {
                    setTableSchemaFilter("");
                    setTableTableFilter("");
                    setSearchTriggered(false); // Reset the search trigger
                  }}
                >
                  Reset
                </button>
              </div>
              <table className="data-table">
                <thead>
                  <tr>
                    <th>Schema</th>
                    <th>Table</th>
                    <th>Data quality KPI</th>
                    <th>Completeness</th>
                    <th>Validity</th>
                    <th>Consistency</th>
                    <th>Accuracy</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {loadingTables ? (
                    <tr>
                      <td colSpan="8" style={{ textAlign: "center" }}>
                        Loading...
                      </td>
                    </tr>
                  ) : errorTables ? (
                    <tr>
                      <td colSpan="8" style={{ textAlign: "center" }}>
                        Error loading data
                      </td>
                    </tr>
                  ) : filteredTableRows.length === 0 ? (
                    <tr>
                      <td colSpan="8" style={{ textAlign: "center" }}>
                        No data available
                      </td>
                    </tr>
                  ) : (
                    filteredTableRows.map((item) => {
                      const {
                        schema_name,
                        table_name,
                        data_quality_kpi,
                        dimensions,
                      } = item.data_quality_status;

                      return (
                        <tr key={item.table_hash}>
                          <td
                          >
                            {item.target.schema_name}
                            </td>
                          <td
                            style={{ cursor: "pointer",textDecoration: "underline",paddingBottom:"2px" }}
                            onClick={() => {
                              setSelectedTable({
                                name: item.target.table_name,
                                schema: item.target.schema_name,
                                connection: connectionName
                              });
                              setViewingTableInfo(true); // Show the ProfilingTable component
                            }}
                          >
                            {item.target.table_name}
                          </td>
                          <td>
                            {data_quality_kpi ? (
                              <span
                                style={{
                                  backgroundColor:
                                    data_quality_kpi < 100
                                      ? "rgb(239, 236, 130)"
                                      : "rgb(92 188 171)",
                                  padding: "3px 5px",
                                  borderRadius: "4px",
                                  fontSize: "12px",
                                  fontWeight: "300",
                                }}
                              >
                                {`${parseFloat(data_quality_kpi).toFixed(2)}%`}
                              </span>
                            ) : (
                              <span
                                style={{
                                  visibility: "hidden",
                                  display: "inline-block",
                                  width: "100%",
                                }}
                              >
                                N/A
                              </span>
                            )}
                          </td>
                          <td>
                            {dimensions?.Completeness?.data_quality_kpi ? (
                              <span
                                style={{
                                  backgroundColor:
                                    dimensions.Completeness.current_severity ===
                                    "warning"
                                      ? "rgb(239, 236, 130)"
                                      : "rgb(92 188 171)",
                                  padding: "3px 5px",
                                  borderRadius: "4px",
                                  fontSize: "12px",
                                  fontWeight: "300",
                                }}
                              >
                                {`${parseFloat(
                                  dimensions.Completeness.data_quality_kpi
                                ).toFixed(2)}%`}
                              </span>
                            ) : (
                              <span
                                style={{
                                  visibility: "hidden",
                                  display: "inline-block",
                                  width: "100%",
                                }}
                              >
                                N/A
                              </span>
                            )}
                          </td>
                          <td>
                            {dimensions?.Validity?.data_quality_kpi ? (
                              <span
                                style={{
                                  backgroundColor:
                                    dimensions.Validity.current_severity ===
                                    "warning"
                                      ? "rgb(239, 236, 130)"
                                      : "rgb(92 188 171)",
                                  padding: "3px 5px",
                                  borderRadius: "4px",
                                  fontSize: "12px",
                                  fontWeight: "300",
                                }}
                              >
                                 {`${parseFloat(
                                  dimensions.Validity.data_quality_kpi
                                ).toFixed(2)}%`}
                              </span>
                            ) : (
                              <span
                                style={{
                                  visibility: "hidden",
                                  display: "inline-block",
                                  width: "100%",
                                }}
                              >
                                N/A
                              </span>
                            )}
                          </td>
                          <td>
                            {dimensions?.Consistency?.data_quality_kpi ? (
                              <span
                                style={{
                                  backgroundColor:
                                    dimensions.Consistency.current_severity ===
                                    "warning"
                                      ? "rgb(239, 236, 130)"
                                      : "rgb(92 188 171)",
                                  padding: "3px 5px",
                                  borderRadius: "4px",
                                  fontSize: "12px",
                                  fontWeight: "300",
                                }}
                              >
                                {`${parseFloat(
                                  dimensions.Consistency.data_quality_kpi
                                ).toFixed(2)}%`}
                              </span>
                            ) : (
                              <span
                                style={{
                                  visibility: "hidden",
                                  display: "inline-block",
                                  width: "100%",
                                }}
                              >
                                N/A
                              </span>
                            )}
                          </td>
                          <td>
                            {dimensions?.Accuracy?.data_quality_kpi ? (
                              <span
                                style={{
                                  backgroundColor:
                                    dimensions.Accuracy.current_severity ===
                                    "warning"
                                      ? "rgb(239, 236, 130)"
                                      : "rgb(92 188 171)",
                                  padding: "3px 5px",
                                  borderRadius: "4px",
                                  fontSize: "12px",
                                  fontWeight: "300",
                                }}
                              >
                                {`${parseFloat(
                                  dimensions.Accuracy.data_quality_kpi
                                ).toFixed(2)}%`}
                              </span>
                            ) : (
                              <span
                                style={{
                                  visibility: "hidden",
                                  display: "inline-block",
                                  width: "100%",
                                }}
                              >
                                N/A
                              </span>
                            )}
                          </td>
                          <td
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <Tooltip title={datasourceInfo} arrow>
                              <img
                                src={datasource}
                                onClick={handleImageClick1}
                                style={{
                                  width: "18px",
                                  height: "20px",
                                  cursor: "pointer",
                                  marginRight: "5px",
                                }}
                                alt="Data Source"
                              />
                            </Tooltip>
                            <Tooltip title={profilingInfo} arrow>
                              <img
                                src={profiling}
                                onClick={handleImageClick2}
                                style={{
                                  width: "18px",
                                  height: "20px",
                                  cursor: "pointer",
                                  marginRight: "5px",
                                }}
                                alt="Profiling"
                              />
                            </Tooltip>
                            <Tooltip title={monitoringInfo} arrow>
                              <img
                                src={monitoringchecks}
                                onClick={handleImageClick3}
                                style={{
                                  width: "18px",
                                  height: "20px",
                                  cursor: "pointer",
                                  marginRight: "5px",
                                }}
                                alt="Monitoring Checks"
                              />
                            </Tooltip>
                            <Tooltip title={partitionInfo} arrow>
                              <img
                                src={partitionchecks}
                                onClick={handleImageClick4}
                                style={{
                                  width: "18px",
                                  height: "20px",
                                  cursor: "pointer",
                                }}
                                alt="Partition Checks"
                              />
                            </Tooltip>
                          </td>
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </table>
              <div className="pagination">
                <Stack spacing={2}>
                  <Pagination
                    count={totalTablePages}
                    page={currentTablePage}
                    onChange={handleTablePageChange}
                    variant="outlined"
                    shape="rounded"
                    sx={{
                      "& .MuiPaginationItem-root": {
                        color: "#fb7857", // Text color for the items
                      },
                      "& .MuiPaginationItem-outlined": {
                        borderColor: "#fb7857", // Border color for the outlined items
                      },
                      "& .MuiPaginationItem-outlined.Mui-selected": {
                        backgroundColor: "#fb7857", // Background color for the selected item
                        color: "#fff", // Text color for the selected item
                      },
                      "& .MuiPaginationItem-outlined:hover": {
                        backgroundColor: "rgba(251, 120, 87, 0.2)", // Hover effect
                      },
                    }}
                  />
                </Stack>
              </div>
            </div>
          )}
          {activeScheduleTab1 === "data-columns" && (
            <div className="data-columns-container">
              <div className="filter-section">
                <input
                  type="text"
                  placeholder="Schema name"
                  className="filter-input"
                  value={columnSchemaFilter}
                  onChange={(e) => setColumnSchemaFilter(e.target.value)}
                />
                <input
                  type="text"
                  placeholder="Table name"
                  className="filter-input"
                  value={columnTableFilter}
                  onChange={(e) => setColumnTableFilter(e.target.value)}
                />
                <input
                  type="text"
                  placeholder="Column name"
                  className="filter-input"
                  value={columnFilter}
                  onChange={(e) => setColumnFilter(e.target.value)}
                />
                <input
                  type="text"
                  placeholder="Column type"
                  className="filter-input"
                  value={typeFilter}
                  onChange={(e) => setTypeFilter(e.target.value)}
                />
                <button className="search-button" onClick={handleSearch}>
                  Search
                </button>
                <button
                  className="reset-button"
                  onClick={() => {
                    setColumnSchemaFilter("");
                    setColumnTableFilter("");
                    setColumnFilter("");
                    setTypeFilter("");
                    setSearchTriggered(false);
                  }}
                >
                  Reset
                </button>
              </div>
              <table className="data-columns">
                <thead>
                  <tr>
                    <th>Schema</th>
                    <th>Table</th>
                    <th>Column</th>
                    <th>Column Type</th>
                    <th>Data Quality KPI</th>
                    <th>Completeness</th>
                    <th>Validity</th>
                    <th>Consistency</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {loadingColumns ? (
                    <tr>
                      <td colSpan="9" style={{ textAlign: "center" }}>
                        Loading...
                      </td>
                    </tr>
                  ) : errorColumns ? (
                    <tr>
                      <td colSpan="9" style={{ textAlign: "center" }}>
                        Error loading data
                      </td>
                    </tr>
                  ) : filteredColumnRows.length === 0 ? (
                    <tr>
                      <td colSpan="9" style={{ textAlign: "center" }}>
                        No data available
                      </td>
                    </tr>
                  ) : (
                    filteredColumnRows.map((item) => {
                      const {
                        schema_name,
                        table_name,
                        column_name,
                        type_snapshot,
                        data_quality_status,
                      } = item;
                      const dimensions = data_quality_status.dimensions || {};

                      return (
                        <tr key={item.column_hash}>
                          <td>{item.table.schema_name}</td>
                          <td>{item.table.table_name}</td>
                          <td
                            onClick={() => handleColumnClick(item.column_name, item.table.schema_name, item.table.table_name)}
                            style={{
                              cursor: "pointer",
                              textDecoration: "underline",
                            }}
                          >
                            {item.column_name}
                          </td>
                          <td>{type_snapshot.column_type}</td>
                          <td>
                            {data_quality_status?.data_quality_kpi ? (
                              <span
                                style={{
                                  backgroundColor:
                                    data_quality_status.data_quality_kpi < 100
                                      ? "rgb(239, 236, 130)"
                                      : "rgb(92 188 171)",
                                  padding: "3px 5px",
                                  borderRadius: "4px",
                                  fontSize: "12px",
                                  fontWeight: "300",
                                }}
                              >
                                {`${parseFloat(
                                  data_quality_status.data_quality_kpi
                                ).toFixed(2)}%`}
                              </span>
                            ) : (
                              <span
                                style={{
                                  visibility: "hidden",
                                  display: "inline-block",
                                  width: "100%",
                                }}
                              >
                                N/A
                              </span>
                            )}
                          </td>
                          <td>
                            {dimensions?.Completeness?.data_quality_kpi ? (
                              <span
                                style={{
                                  backgroundColor:
                                    dimensions.Completeness.current_severity ===
                                    "warning"
                                      ? "rgb(239, 236, 130)"
                                      : "rgb(92 188 171)",
                                  padding: "3px 5px",
                                  borderRadius: "4px",
                                  fontSize: "12px",
                                  fontWeight: "300",
                                }}
                              >
                                 {`${parseFloat(
                                  dimensions.Completeness.data_quality_kpi
                                ).toFixed(2)}%`}
                              </span>
                            ) : (
                              <span
                                style={{
                                  visibility: "hidden",
                                  display: "inline-block",
                                  width: "100%",
                                }}
                              >
                                N/A
                              </span>
                            )}
                          </td>
                          <td>
                            {dimensions?.Validity?.data_quality_kpi ? (
                              <span
                                style={{
                                  backgroundColor:
                                    dimensions.Validity.current_severity ===
                                    "warning"
                                      ? "rgb(239, 236, 130)"
                                      : "rgb(92 188 171)",
                                  padding: "3px 5px",
                                  borderRadius: "4px",
                                  fontSize: "12px",
                                  fontWeight: "300",
                                }}
                              >
                                {`${parseFloat(
                                  dimensions.Validity.data_quality_kpi
                                ).toFixed(2)}%`}
                              </span>
                            ) : (
                              <span
                                style={{
                                  visibility: "hidden",
                                  display: "inline-block",
                                  width: "100%",
                                }}
                              >
                                N/A
                              </span>
                            )}
                          </td>
                          <td>
                            {dimensions?.Consistency?.data_quality_kpi ? (
                              <span
                                style={{
                                  backgroundColor:
                                    dimensions.Consistency.current_severity ===
                                    "warning"
                                      ? "rgb(239, 236, 130)"
                                      : "rgb(92 188 171)",
                                  padding: "3px 5px",
                                  borderRadius: "4px",
                                  fontSize: "12px",
                                  fontWeight: "300",
                                }}
                              >
                                {`${parseFloat(
                                  dimensions.Consistency.data_quality_kpi
                                ).toFixed(2)}%`}
                              </span>
                            ) : (
                              <span
                                style={{
                                  visibility: "hidden",
                                  display: "inline-block",
                                  width: "100%",
                                }}
                              >
                                N/A
                              </span>
                            )}
                          </td>
                          <td
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <Tooltip title={datasourceInfo} arrow>
                              <img
                                src={datasource}
                                onClick={handleImageClick1}
                                style={{
                                  width: "18px",
                                  height: "20px",
                                  cursor: "pointer",
                                  marginRight: "5px",
                                }}
                                alt="Data Source"
                              />
                            </Tooltip>
                            <Tooltip title={profilingInfo} arrow>
                              <img
                                src={profiling}
                                onClick={handleImageClick2}
                                style={{
                                  width: "18px",
                                  height: "20px",
                                  cursor: "pointer",
                                  marginRight: "5px",
                                }}
                                alt="Profiling"
                              />
                            </Tooltip>
                            <Tooltip title={monitoringInfo} arrow>
                              <img
                                src={monitoringchecks}
                                onClick={handleImageClick3}
                                style={{
                                  width: "18px",
                                  height: "20px",
                                  cursor: "pointer",
                                  marginRight: "5px",
                                }}
                                alt="Monitoring Checks"
                              />
                            </Tooltip>
                            <Tooltip title={partitionInfo} arrow>
                              <img
                                src={partitionchecks}
                                onClick={handleImageClick4}
                                style={{
                                  width: "18px",
                                  height: "20px",
                                  cursor: "pointer",
                                }}
                                alt="Partition Checks"
                              />
                            </Tooltip>
                          </td>
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </table>
              <div className="pagination">
                <Stack spacing={2}>
                  <Pagination
                    count={totalColumnPages}
                    page={currentColumnPage}
                    onChange={handleColumnPageChange}
                    variant="outlined"
                    shape="rounded"
                    sx={{
                      "& .MuiPaginationItem-root": {
                        color: "#fb7857", // Text color for the items
                      },
                      "& .MuiPaginationItem-outlined": {
                        borderColor: "#fb7857", // Border color for the outlined items
                      },
                      "& .MuiPaginationItem-outlined.Mui-selected": {
                        backgroundColor: "#fb7857", // Background color for the selected item
                        color: "#fff", // Text color for the selected item
                      },
                      "& .MuiPaginationItem-outlined:hover": {
                        backgroundColor: "rgba(251, 120, 87, 0.2)", // Hover effect
                      },
                    }}
                  />
                </Stack>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
      )}
    </div>
  );
}

export default SwitchTabMonitoringChecks;
