// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.summary-heading p {
  font-weight: 600;
  margin: 20px 0;
}
.summary {
  padding: 15px;
  border-radius: 20px;
  background-color: #f9f9f9;
}
.summary-table-header {
  display: grid;
  grid-template-columns: 1fr 0.6fr 1fr;
  padding-bottom: 10px;
  align-items: center;

  font-size: 14px;
  font-weight: 500;
}
.summary-table-rows {
  display: grid;
  grid-template-columns: 1fr 0.6fr 1fr;
  padding: 10px 0;
  align-items: center;
  border-top: 1px solid #e7e7ee;
  font-size: 14px;
}

.insights-heading p {
  font-weight: 600;
  margin: 20px 0;
}

.insights-graph {
  padding: 15px;
  background-color: #f9f9f9;
  border-radius: 20px;
}
.insights-graph img {
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/components/Home/Dashboard/Summary/Summary.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,cAAc;AAChB;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,yBAAyB;AAC3B;AACA;EACE,aAAa;EACb,oCAAoC;EACpC,oBAAoB;EACpB,mBAAmB;;EAEnB,eAAe;EACf,gBAAgB;AAClB;AACA;EACE,aAAa;EACb,oCAAoC;EACpC,eAAe;EACf,mBAAmB;EACnB,6BAA6B;EAC7B,eAAe;AACjB;;AAEA;EACE,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,yBAAyB;EACzB,mBAAmB;AACrB;AACA;EACE,WAAW;AACb","sourcesContent":[".summary-heading p {\n  font-weight: 600;\n  margin: 20px 0;\n}\n.summary {\n  padding: 15px;\n  border-radius: 20px;\n  background-color: #f9f9f9;\n}\n.summary-table-header {\n  display: grid;\n  grid-template-columns: 1fr 0.6fr 1fr;\n  padding-bottom: 10px;\n  align-items: center;\n\n  font-size: 14px;\n  font-weight: 500;\n}\n.summary-table-rows {\n  display: grid;\n  grid-template-columns: 1fr 0.6fr 1fr;\n  padding: 10px 0;\n  align-items: center;\n  border-top: 1px solid #e7e7ee;\n  font-size: 14px;\n}\n\n.insights-heading p {\n  font-weight: 600;\n  margin: 20px 0;\n}\n\n.insights-graph {\n  padding: 15px;\n  background-color: #f9f9f9;\n  border-radius: 20px;\n}\n.insights-graph img {\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
