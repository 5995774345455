// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Meter_MeterContainer__vCO0S {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 300px;
  height: 160px;
}

.Meter_MeterImage__GLKq8 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  z-index: 0;
}

.Meter_Meter__Ka63H {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
}

.Meter_SvgContainer__XJiCP {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.Meter_rating__7pcNd {
  text-align: center;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.Meter_rating__7pcNd h4 {
  font-size: var(--size-xl);
  font-weight: 700;
  margin: 0;
}

.Meter_rating__7pcNd p {
  font-size: var(--size-lg);
  font-weight: 500;
  margin: 0;
}
`, "",{"version":3,"sources":["webpack://./src/components/DataSetFlow/SwitchTabs/SwitchTabFive/Meter/Meter.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,kBAAkB;EAClB,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,UAAU;AACZ;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,kBAAkB;EAClB,WAAW;EACX,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,kBAAkB;EAClB,SAAS;EACT,SAAS;EACT,2BAA2B;AAC7B;;AAEA;EACE,yBAAyB;EACzB,gBAAgB;EAChB,SAAS;AACX;;AAEA;EACE,yBAAyB;EACzB,gBAAgB;EAChB,SAAS;AACX","sourcesContent":[".MeterContainer {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  position: relative;\n  width: 300px;\n  height: 160px;\n}\n\n.MeterImage {\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: auto;\n  z-index: 0;\n}\n\n.Meter {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  position: relative;\n  width: 100%;\n  height: 100%;\n}\n\n.SvgContainer {\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n}\n\n.rating {\n  text-align: center;\n  position: absolute;\n  bottom: 0;\n  left: 50%;\n  transform: translateX(-50%);\n}\n\n.rating h4 {\n  font-size: var(--size-xl);\n  font-weight: 700;\n  margin: 0;\n}\n\n.rating p {\n  font-size: var(--size-lg);\n  font-weight: 500;\n  margin: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"MeterContainer": `Meter_MeterContainer__vCO0S`,
	"MeterImage": `Meter_MeterImage__GLKq8`,
	"Meter": `Meter_Meter__Ka63H`,
	"SvgContainer": `Meter_SvgContainer__XJiCP`,
	"rating": `Meter_rating__7pcNd`
};
export default ___CSS_LOADER_EXPORT___;
