// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home-section {
  background-color: #f5f5f5;
  padding: 40px 20px;
  display: flex;
  gap: 20px;
}
.home-section-wrapper {
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr 1fr;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Home.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,kBAAkB;EAClB,aAAa;EACb,SAAS;AACX;AACA;EACE,aAAa;EACb,SAAS;EACT,8BAA8B;AAChC","sourcesContent":[".home-section {\n  background-color: #f5f5f5;\n  padding: 40px 20px;\n  display: flex;\n  gap: 20px;\n}\n.home-section-wrapper {\n  display: grid;\n  gap: 20px;\n  grid-template-columns: 1fr 1fr;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
