//data quality backend local url
// export const REACT_BASE_LOCAL_URL = "http://10.0.0.162:8888"
// export const REACT_BASE_LOCAL_URL = "https://dataquality-backend.dataops.neuralcompany.team"

// export const REACT_BASE_LOCAL_URL = "http://172.16.200.229:30274"
// export const REACT_BASE_LOCAL_URL = "http://10.0.0.162:8888"
export const REACT_BASE_LOCAL_URL = "https://dataquality-backend.dataops.neuralcompany.team"
// 

//data quality backend production url
export const REACT_BASE_PROD_URL = "https://dataquality-backend.dataops.neuralcompany.team"
